<template>
  <div class="h-rich-editor-wrapper">
    <h-rich-text-editor-menu-bar
      v-if="shouldShowMenuBar"
      :disabled="!editable"
      :editor="editor"
      :formatParagraph="formatParagraph"
      :formatBold="formatBold"
      :formatItalic="formatItalic"
      :formatStrike="formatStrike"
      :formatMarker="formatMarker"
      :formatH1="formatH1"
      :formatH2="formatH2"
      :formatH3="formatH3"
      :formatListB="formatListB"
      :formatListN="formatListN"
      :formatCode="formatCode"
      :formatCodeBlock="formatCodeBlock"
      :formatSeparator="formatSeparator"
      :historyUndo="historyUndo"
      :historyRedo="historyRedo"/>
    <editor-content
      class="h-rich-editor"
      :editor="editor"
      :class="{
        'disabled': !editable,
        'editor-padding': editorPadding,
        'opacify-disabled': opacifyDisabled,
        'editor-borders': editorBorders,
        'has-error': this.hasError(),
      }"/>
    <div
      v-if="hasError()"
      class="h-rich-editor-errors mt-1">
      {{ getErrorMessage() }}
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Highlight from '@tiptap/extension-highlight'
import Image from '@tiptap/extension-image'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import HRichTextEditorMenuBar from './menubar/HRichTextEditorMenuBar'
export default {
  name: 'HRichTextEditor',
  components: {
    EditorContent,
    HRichTextEditorMenuBar,
  },
  props: {
    value: { type: String, default: '' },
    required: { type: Boolean, default: false },
    editable: { type: Boolean, default: false },
    editorBorders: { type: Boolean, default: false },
    editorPadding: { type: Boolean, default: false },
    opacifyDisabled: { type: Boolean, default: false },
    formatParagraph: { type: Boolean, default: false },
    formatBold: { type: Boolean, default: false },
    formatItalic: { type: Boolean, default: false },
    formatStrike: { type: Boolean, default: false },
    formatMarker: { type: Boolean, default: false },
    formatH1: { type: Boolean, default: false },
    formatH2: { type: Boolean, default: false },
    formatH3: { type: Boolean, default: false },
    formatListB: { type: Boolean, default: false },
    formatListN: { type: Boolean, default: false },
    formatCode: { type: Boolean, default: false },
    formatCodeBlock: { type: Boolean, default: false },
    // formatBlockQuote: { type: Boolean, default: false },
    formatSeparator: { type: Boolean, default: false },
    historyUndo: { type: Boolean, default: false },
    historyRedo: { type: Boolean, default: false },
  },
  computed: {
    shouldShowMenuBar () {
      return ([
        this.formatParagraph,
        this.formatBold,
        this.formatItalic,
        this.formatStrike,
        this.formatMarker,
        this.formatH1,
        this.formatH2,
        this.formatH3,
        this.formatListB,
        this.formatListN,
        this.formatCode,
        this.formatCodeBlock,
        this.formatSeparator,
        this.historyUndo,
        this.historyRedo,
      ]).some(value => value)
    },
  },
  data () {
    return {
      editor: null,
    }
  },
  watch: {
    value (value) {
      const isSame = this.editor.getHTML() === value
      if (isSame) {
        return
      }
      this.editor.commands.setContent(value, false)
    },
    editable () {
      this.editor.setEditable(this.editable)
    },
  },
  mounted () {
    this.editor = new Editor({
      editable: this.editable,
      content: this.value,
      extensions: [
        StarterKit.configure({
          heading: {
            levels: [1, 2, 3]
          },
        }),
        Highlight,
        Image.configure({
          inline: true
        }),
        Table.configure({
          HTMLAttributes: {
            class: 'h-rich-text-editor-table',
          },
        }),
        TableRow,
        TableHeader,
        TableCell,
      ],
      onUpdate: () => {
        if (this.isEditorEmpty()) {
          this.$emit('input', null)
        } else {
          this.$emit('input', this.editor.getHTML())
        }
      },
    })
  },
  beforeDestroy () {
    this.editor.destroy()
  },
  methods: {
    isEditorEmpty () {
      // Dirty way to know if the editor is empty or not.
      // The built in editor.isEmpty does not work well IMHO.
      return !this.editor?.getText()
    },
    hasError () {
      // Must have same order as method getErrorMessage()
      if (this.required && this.isEditorEmpty()) return true
    },
    getErrorMessage () {
      // Must have same order as method hasError()
      if (this.required && this.isEditorEmpty()) return this.$t('common.errors.required')
    },
  },
}
</script>

<style scoped>
.h-rich-editor.editor-padding {
  padding: 12px;
}
.h-rich-editor.editor-borders {
  border: 1px solid rgba(0, 0, 0, 0.42);
}
.h-rich-editor.has-error {
  border-color: #ff5252;
  caret-color: #ff5252;
}
.h-rich-editor.disabled.opacify-disabled {
  opacity: 0.38;
}
.h-rich-editor::v-deep .ProseMirror:focus-visible {
  outline: none;
}
.h-rich-editor::v-deep img {
  max-width: 40%;
}
.h-rich-editor-errors {
  color: #ff5252;
  line-height: 12px;
  font-size: 12px;
}
.h-rich-editor::v-deep table.h-rich-text-editor-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 8px;
}
.h-rich-editor::v-deep table.h-rich-text-editor-table th,
.h-rich-editor::v-deep table.h-rich-text-editor-table td {
  border: 1px solid #363636;
  padding: 4px;
}
.h-rich-editor::v-deep table.h-rich-text-editor-table tr:hover {
  background-color: #78b8ef;
}
</style>
