<template>
  <DialogCard :actions="actions">
    <v-toolbar
      flat
      dark
      color="warning">
      <v-toolbar-title>
        <v-icon left>{{ titleIcon }}</v-icon>
        {{ title }}
      </v-toolbar-title>
    </v-toolbar>
    <div class="pt-4 px-4 subtitle-1">
      {{ text }}
    </div>
    <v-expansion-panels
      v-if="multipleRecords"
      flat
      tile
      hover>
      <v-expansion-panel>
        <v-expansion-panel-header class="px-4">
          Show Records ({{ summaryRecords.length }})
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            :headers="summaryHeaders"
            :items="summaryRecords"
            :hide-default-footer="summaryRecords.length < 10">
            <!-- Generic subtemplate for 'is_active' rendering -->
            <template v-slot:[`item.is_active`]="{ item }">
              <v-icon v-if="item.is_active" color="green">mdi-check-bold</v-icon>
              <v-icon v-else color="red">mdi-close-thick</v-icon>
            </template>
            <!------------------------------------------------>
            <!-- Generic subtemplate for 'is_closed' rendering -->
            <template v-slot:[`item.is_closed`]="{ item }">
              <template v-if="item.is_closed">Closed</template>
              <template v-else>Open</template>
            </template>
            <!------------------------------------------------>
            <!-- Generic subtemplate for 'user_type' rendering -->
            <template v-slot:[`item.user_type`]="{ item }">
              <v-chip
                small
                label
                :color="item.user_type === 'system' ? 'green' : 'primary'"
                class="font-weight-bold white--text">
                {{ item.user_type_display }}
              </v-chip>
            </template>
            <!------------------------------------------------>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-alert v-if="checkRequired" class="ma-4" prominent outlined text dense :type="alertType">
      {{ alertMessage }}
      <v-checkbox
        v-if="checkRequired"
        v-model="checked"
        :label="checkMessage"
        dense/>
    </v-alert>
  </DialogCard>
</template>

<script>
export default {
  name: 'DeleteModal',
  props: {
    titleIcon: {
      type: String,
      default: 'mdi-delete',
    },
    titleText: {
      type: String,
      default: '',
    },
    warningText: {
      type: String,
      default: '',
    },
    alertType: {
      type: String,
      default: 'warning',
    },
    alertMessage: {
      type: String,
      default: '',
    },
    checkRequired: {
      type: Boolean,
      default: false,
    },
    checkMessage: {
      type: String,
      default: 'I agree',
    },
    summaryHeaders: {
      type: Array,
      default: () => { return [] },
    },
    summaryRecords: {
      type: Array,
      default: () => { return [] },
    },
    recordName: {
      type: String,
      default: 'Record',
    },
    recordNamePlural: {
      type: String,
      default: 'Records',
    },
  },
  computed: {
    multipleRecords () {
      return this.summaryRecords.length > 1
    },
    title () {
      if (!this.titleText) {
        return `Delete ${this.multipleRecords ? this.recordNamePlural : this.recordName} ?`
      }
      return this.titleText
    },
    text () {
      if (!this.warningText) {
        return `Are you sure you want to delete ${this.multipleRecords ? `these ${this.recordNamePlural}` : `this ${this.recordName}`} ?`
      }
      return this.warningText
    },
    actions () {
      return {
        true: {
          text: this.$t('common.buttons.yes'),
          color: 'red',
          disabled: this.checkRequired && !this.checked,
        },
        false: {
          text: this.$t('common.buttons.cancel'),
        },
      }
    },
  },
  data () {
    return {
      checked: false,
    }
  },
}
</script>

<style scoped>
  .v-card::v-deep .v-card__text {
    padding: 0;
  }
  .v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
    padding-block: 0;
    padding-inline: 16px;
  }
</style>
