var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-toolbar',{attrs:{"tile":"","dense":"","elevation":"1"}},[(_vm.hasPermission('add_user'))?_c('v-btn',{attrs:{"text":"","to":{ name: 'UserFormCreate', params: { editing: true } }}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t('common.buttons.create'))+" ")],1):_vm._e(),(
      _vm.selectedUsers.length
      && (
        _vm.hasPermission('change_user')
        || _vm.hasPermission('delete_user')
      ))?_c('v-menu',{attrs:{"close-on-click":"","close-on-content-click":"","offsetY":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v(" "+_vm._s(_vm.$tc('common.labels.actions', 2))+" "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,false,969608688)},[_c('v-list',{staticClass:"h-menu-list"},[(_vm.hasPermission('change_user'))?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('activateUsers', _vm.selectedUsers)}}},[_c('v-icon',{staticClass:"mr-4",attrs:{"small":""}},[_vm._v("mdi-archive-arrow-up")]),_vm._v(" "+_vm._s(_vm.$t('common.buttons.activate'))+" ")],1):_vm._e(),(_vm.hasPermission('change_user'))?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('deactivateUsers', _vm.selectedUsers)}}},[_c('v-icon',{staticClass:"mr-4",attrs:{"small":""}},[_vm._v("mdi-archive-arrow-down")]),_vm._v(" "+_vm._s(_vm.$t('common.buttons.deactivate'))+" ")],1):_vm._e(),(_vm.hasPermission('delete_user'))?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('deleteUsers', _vm.selectedUsers)}}},[_c('v-icon',{staticClass:"mr-4",attrs:{"small":""}},[_vm._v("mdi-delete")]),_vm._v(" "+_vm._s(_vm.$t('common.buttons.delete'))+" ")],1):_vm._e()],1)],1):_vm._e(),_c('v-spacer'),_c('h-filtering-bar',{attrs:{"availableFilters":_vm.availableFilters,"defaultFilters":[
      { fieldName: 'is_user_system', label: this.$tc('common.labels.usersSystem', 1), optionText: this.$tc('common.labels.usersSystem', 1), type: 'boolean', value: true },
    ]}}),_c('v-spacer'),_c('v-btn',{class:{'v-btn--active': _vm.viewType === 'kanban'},attrs:{"tile":"","icon":"","link":""},on:{"click":function($event){return _vm.$emit('setKanbanView')}}},[_c('v-icon',[_vm._v("mdi-view-grid")])],1),_c('v-btn',{class:{'v-btn--active': _vm.viewType === 'list'},attrs:{"tile":"","icon":"","link":""},on:{"click":function($event){return _vm.$emit('setListView')}}},[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }