<template>
  <h-app-main-menu :menuItems="menuItems"/>
</template>

<script>
import HAppMainMenu from '@/common/components/HAppMainMenu'
export default {
  name: 'ContactMainMenu',
  components: { HAppMainMenu },
  computed: {
    menuItems () {
      return [
        {
          menuLabel: this.$tc('common.labels.companies', 2),
          menuPermissions: ['view_contactcompany'],
          routeName: 'ContactCompanyListing',
          matchingPaths: ['/contact/companies'],
        },
        {
          menuLabel: this.$tc('common.labels.contacts', 2),
          menuPermissions: ['view_contactcontact'],
          routeName: 'ContactContactListing',
          matchingPaths: ['/contact/contacts'],
        },
        {
          menuLabel: 'Tags',
          menuPermissions: ['view_contactcontacttag'],
          routeName: 'ContactContactTagListing',
          matchingPaths: ['/contact/tags'],
        },
      ]
    },
  },
}
</script>
