<template>
  <DialogCard :actions="actions">
    <v-toolbar
      flat
      dark
      color="blue darken-1">
      <v-toolbar-title>
        <v-icon left>mdi-account-key</v-icon>
        {{ $t('applications.base.changeUserPasswordDialog.changePasswordOf', { fullName: user?.full_name }) }}:
      </v-toolbar-title>
    </v-toolbar>
    <v-form v-model="isFormValid" class="pa-4 mb-4">
      <v-text-field
        v-model="form.password"
        required
        maxlength="128"
        counter
        autofocus
        :label="$t('common.labels.newPassword')"
        :rules="[formRules.required]"
        :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"/>
      <v-text-field
        v-model="form.confirm"
        required
        maxlength="128"
        counter
        :label="$t('common.labels.newPasswordConfirm')"
        :rules="[formRules.required, formRules.confirmIsEqual]"
        :type="showConfirm ? 'text' : 'password'"
        :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showConfirm = !showConfirm"/>
    </v-form>
    <v-divider/>
  </DialogCard>
</template>

<script>
export default {
  name: 'UserChangePassword',
  props: { user: Object },
  data () {
    return {
      form: {
        password: '',
        confirm: ''
      },
      showPassword: false,
      showConfirm: false,
      isFormValid: false,
      formRules: {
        required: value => !!value || this.$t('common.errors.required'),
        confirmIsEqual: value => value === this.form.password || this.$t('common.errors.passwordsDoNotMatch'),
      },
    }
  },
  computed: {
    actions () {
      return {
        false: {
          text: this.$t('common.buttons.cancel'),
        },
        true: {
          text: this.$t('common.buttons.save'),
          color: 'green',
          disabled: !this.isFormValid,
          handler: () => {
            return new Promise(resolve => {
              resolve(this.form)
            })
          },
        },
      }
    },
  },
}
</script>

<style scoped>
  .v-card::v-deep
    .v-card__text {
      padding: 0;
    }
</style>
