<template>
  <v-toolbar tile dense elevation="1">
    <v-spacer/>
    <h-filtering-bar :availableFilters="availableFilters"/>
    <v-spacer/>
    <v-btn
      tile
      icon
      link
      :class="{'v-btn--active': viewType === 'kanban'}"
      @click="$emit('setKanbanView')">
      <v-icon>mdi-view-grid</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import HFilteringBar from '@/common/components/HFilteringBar'
export default {
  name: 'ApplicationListingToolbar',
  components: { HFilteringBar },
  props: {
    viewType: { type: String },
  },
  computed: {
    availableFilters () {
      return [
        { fieldName: 'name', label: this.$t('common.labels.name'), type: 'string' },
        { fieldName: 'is_activated', label: this.$t('common.labels.activated'), optionText: this.$t('common.labels.activated'), type: 'boolean', value: true },
        { fieldName: 'is_activated', label: this.$t('common.labels.activated'), optionText: this.$t('common.labels.deactivated'), type: 'boolean', value: false },
      ]
    },
  },
}
</script>

<style scoped>
  .v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:last-child {
    /* Override the default -12px right margin in order to be symmetrical */
    margin-right: 0px;
  }
  .v-toolbar {
    height: unset!important;
  }
  .v-toolbar::v-deep .v-toolbar__content {
    height: fit-content!important;
  }
</style>
