<template>
  <div>
    <timesheet-item-listing-toolbar
      :viewType="viewType"
      :selectedTimesheets="selectedTimesheets"
      @setListView="setListView"
      @exportTimesheets="exportTimesheets"
      @deleteTimesheets="deleteTimesheets"/>
      <transition name="router-anim" mode="out-in">
        <timesheet-item-list
          v-if="viewType === 'list'"
          v-model="selectedTimesheets"
          :timesheets="timesheets"
          :pagination="pagination"
          :loading="loading"
          :loadingMore="loadingMore"
          @loadNextTimesheetPage="loadNextTimesheetPage"
          @deleteTimesheet="deleteTimesheet"/>
      </transition>
  </div>
</template>

<script>
import DeleteModal from '@/common/components/DeleteModal'
import TimesheetItemExportDialog from '@/views/timesheets/item/shared/TimesheetItemExportDialog'
import TimesheetItemList from '@/views/timesheets/item//TimesheetItemList'
import TimesheetItemListingToolbar from '@/views/timesheets/item/shared/TimesheetItemListingToolbar'
import TimesheetItemService from '@/services/timesheets/timesheet.item.service'
import FileService from '@/services/file.service.js'
export default {
  name: 'TimesheetItemListing',
  components: {
    TimesheetItemList,
    TimesheetItemListingToolbar,
  },
  data () {
    return {
      loading: false,
      loadingMore: false,
      viewType: 'list',
      timesheets: [],
      pagination: null,
      selectedTimesheets: [],
    }
  },
  mounted () {
    this.getTimesheets(this.$store.state.url.urlSearchParams)
  },
  watch: {
    '$store.state.url.urlSearchParams' (newParams, oldParams) {
      this.getTimesheets(this.$store.state.url.urlSearchParams)
    },
  },
  methods: {
    setListView () {
      this.viewType = 'list'
    },
    async getTimesheets (urlSearchParams) {
      this.loading = true
      const responseData = await TimesheetItemService.getTimesheets(urlSearchParams)
      this.timesheets = responseData.timesheets
      this.pagination = responseData.pagination
      this.loading = false
    },
    async loadNextTimesheetPage () {
      if (this.pagination.has_next_page) {
        this.loadingMore = true
        const nextPageNumber = this.pagination.page_number + 1
        const urlSearchParamsString = this.$store.state.url.urlSearchParams.toString()
        // We do not use the one from the store otherwise the page is stored there..
        const urlSearchParams = new URLSearchParams(urlSearchParamsString)
        urlSearchParams.set('page', nextPageNumber)
        const responseData = await TimesheetItemService.getTimesheets(urlSearchParams)
        this.pagination = responseData.pagination
        const newTimesheetPage = responseData.timesheets
        const currentTimesheets = this.timesheets
        const updatedTimesheetList = currentTimesheets.concat(newTimesheetPage)
        this.timesheets = updatedTimesheetList
        this.loadingMore = false
      }
    },
    exportTimesheets () {
      this.$dialog.show(TimesheetItemExportDialog, {
        selectionCount: this.selectedTimesheets.length,
        persistent: true,
        waitForResult: true,
      }).then(async result => {
        if (result) {
          let response = null
          if (result.exportSelection === 'all') {
            response = await TimesheetItemService.exportTimesheets(
              result.exportType,
              {
                format: result.exportType,
                from_date: result.from,
                to_date: result.to,
              }
            )
          } else if (result.exportSelection === 'selection') {
            const selectedTimesheetIds = this.selectedTimesheets.map(timesheet => timesheet.id)
            response = await TimesheetItemService.exportSelectedTimesheets(
              result.exportType,
              {
                format: result.exportType,
                ids: selectedTimesheetIds,
              }
            )
          }
          if (response) FileService.downloadFile(response)
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    deleteTimesheet (timesheet) {
      this.$dialog.show(DeleteModal, {
        titleText: this.$t('applications.timesheets.items.common.deleteTimesheetTitle'),
        warningText: this.$t('applications.timesheets.items.common.deleteTimesheetWarningText'),
        recordName: 'Timesheet',
        recordNamePlural: 'Timesheets',
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await TimesheetItemService.deleteTimesheets({ ids: [timesheet.id] })
          if (response) {
            this.timesheets.splice(this.timesheets.indexOf(timesheet), 1)
            this.selectedTimesheets = []
            this.$dialog.notify.success(
              this.$t('applications.timesheets.items.messages.timesheetDeleted', { description: timesheet.description }), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    deleteTimesheets (timesheetsToDelete) {
      this.$dialog.show(DeleteModal, {
        titleText: this.$tc('applications.timesheets.items.common.deleteTimesheetsTitle', timesheetsToDelete.length),
        warningText: this.$tc('applications.timesheets.items.common.deleteTimesheetsWarningText', timesheetsToDelete.length),
        recordName: 'Timesheet',
        recordNamePlural: 'Timesheets',
        summaryHeaders: [
          { value: 'description', text: this.$t('common.labels.description') },
        ],
        summaryRecords: timesheetsToDelete,
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const timesheetIdsToDelete = timesheetsToDelete.map(timesheet => timesheet.id)
          const response = await TimesheetItemService.deleteTimesheets({ ids: timesheetIdsToDelete })
          if (response) {
            this.timesheets = this.timesheets.filter(undeletedTimesheet => !timesheetIdsToDelete.includes(undeletedTimesheet.id))
            this.selectedTimesheets = []
            this.$dialog.notify.success(
              this.$tc('applications.timesheets.items.messages.timesheetsDeleted', timesheetsToDelete.length, { count: timesheetsToDelete.length }), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
  },
}
</script>
