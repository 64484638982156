<template>
  <v-row class="my-0">
    <v-col>
      <v-card tile elevation="3">
        <v-row no-gutters class="pa-4">
          <v-col class="d-flex justify-end">
            <span class="caption my-auto">{{ $t('showing') }} <span style="color:#1E88E5">{{ subtasks.length }}</span> {{ $t('of') }} {{ pagination?.total_records }}</span>
            <v-divider
              v-if="pagination?.has_next_page"
              class="mx-4"
              vertical/>
            <v-btn
              v-if="pagination?.has_next_page"
              color="blue darken-1"
              outlined
              :loading="loadingMore"
              @click="$emit('loadNextSubtaskPage')">
              <v-icon left>mdi-download</v-icon>
              {{ $t('common.buttons.loadMore') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-divider/>
        <v-data-table
          v-model="selectedSubtasks"
          multi-sort
          check
          checkbox-color="blue darken-2"
          :show-select="hasPermission('delete_helpdeskticketsubtask') || hasPermission('change_helpdeskticketsubtask')"
          :loading="loading"
          :headers="tableHeaders"
          :items="subtasks"
          :hide-default-footer="subtasks.length < 10">
          <template v-slot:loading>
            <div class="my-12">{{ $t('loading') }}...</div>
          </template>
          <template v-slot:no-data>
            <h-no-records-found/>
          </template>
          <template v-slot:[`item.description`]="{ item }">
            {{ item.description | truncate(40, '...') }}
          </template>
          <template v-slot:[`item.status_display`]="{ item }">
            <v-menu tile offsetX>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  tile
                  depressed
                  :color="ticketSubtaskStatusAttributes(item.status).color"
                  :class="{
                    'white--text': shouldTextBeWhite(ticketSubtaskStatusAttributes(item.status).color),
                    'disable-events': !hasPermission('change_helpdeskticketsubtask')
                  }">
                  <v-icon left>{{ ticketSubtaskStatusAttributes(item.status).icon }}</v-icon>
                  {{ ticketSubtaskStatusAttributes(item.status).text }}
                </v-btn>
              </template>
              <v-list class="h-menu-list">
                <v-list-item
                  @click="$emit('updateSubtaskAsToDo', item)">
                  <v-icon class="mr-4" color="red">mdi-circle-outline</v-icon>
                  To Do
                </v-list-item>
                <v-list-item
                  @click="$emit('updateSubtaskAsInProgress', item)">
                  <v-icon class="mr-4" color="orange">mdi-progress-wrench</v-icon>
                  In Progress
                </v-list-item>
                <v-list-item
                  @click="$emit('updateSubtaskAsDone', item)">
                  <v-icon class="mr-4" color="green">mdi-check-circle</v-icon>
                  Done
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:[`item.ticket_id`]="{ item }">
            <v-tooltip bottom open-delay="250">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  text
                  color="blue darken-1"
                  :ripple="false"
                  :to="{ name: 'HelpdeskTicketFormRead', params: { helpdeskTicketId: item.ticket_id }}">
                  Ticket #{{ item.ticket_id }}
                </v-btn>
              </template>
              {{ item.ticket_name }}
            </v-tooltip>
          </template>
          <template v-slot:[`item.author.full_name`]="{ item }">
            <template v-if="item.author">
              <v-avatar size="24px" class="mr-1">
                <v-img :src="item.author.picture ? getPictureURL(item.author.picture) : require('@/assets/images/no-user-picture.jpeg')"/>
              </v-avatar>
              {{ item.author.full_name }}
            </template>
            <template v-else-if="item.author_full_name">
              <v-avatar size="24px" class="mr-1">
                <v-img :src="require('@/assets/images/no-user-picture.jpeg')"/>
              </v-avatar>
              {{ item.author_full_name }}
            </template>
          </template>
          <template v-slot:[`item.assigned_user.full_name`]="{ item }">
            <template v-if="item.assigned_user">
              <v-avatar size="24px" class="mr-1">
                <v-img :src="item.assigned_user.picture ? getPictureURL(item.assigned_user.picture) : require('@/assets/images/no-user-picture.jpeg')"/>
              </v-avatar>
              {{ item.assigned_user.full_name }}
            </template>
            <template v-else-if="item.assigned_user_full_name">
              <v-avatar size="24px" class="mr-1">
                <v-img :src="require('@/assets/images/no-user-picture.jpeg')"/>
              </v-avatar>
              {{ item.assigned_user_full_name }}
            </template>
          </template>
          <template v-slot:[`item.due_date`]="{ item }">
            <template v-if="item.due_date">{{ momentDate(item.due_date) }}</template>
            <template v-else>-</template>
          </template>
          <template v-slot:[`item.done_date`]="{ item }">
            <template v-if="item.done_date">{{ momentDate(item.done_date) }}</template>
            <template v-else>-</template>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip v-if="hasPermission('delete_helpdeskticketsubtask')" bottom open-delay="250">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" @click="$emit('deleteSubtask', item)" icon>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              {{ $t('common.buttons.delete') }}
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment-timezone'
import HNoRecordsFound from '@/common/components/HNoRecordsFound'
export default {
  name: 'HelpdeskTicketSubtaskList',
  components: { HNoRecordsFound },
  props: {
    value: {
      type: Array,
      default: () => { return [] },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingMore: {
      type: Boolean,
      default: false,
    },
    subtasks: {
      type: Array,
      default: () => { return [] },
    },
    pagination: {
      type: Object,
      default: null,
    },
  },
  computed: {
    selectedSubtasks: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
    tableHeaders () {
      return [
        { value: 'description', text: this.$t('common.labels.description') },
        { value: 'ticket_id', text: 'Ticket' },
        { value: 'assigned_user.full_name', text: this.$t('common.labels.assignee') },
        { value: 'status_display', text: 'Status' },
        { value: 'due_date', text: 'Due Date' },
        { value: 'done_date', text: 'Done Date' },
        { value: 'author.full_name', text: this.$t('common.labels.createdBy') },
        { value: 'actions', text: this.$tc('common.labels.actions', 2), sortable: false },
      ]
    }
  },
  beforeDestroy () {
    this.selectedSubtasks = []
  },
  watch: {
    '$store.state.url.urlSearchParams' (newParams, oldParams) {
      this.selectedSubtasks = []
    },
  },
  methods: {
    ticketSubtaskStatusAttributes (status) {
      if (status === 'to_do') return { icon: 'mdi-circle-outline', color: 'red', text: "To Do" }
      if (status === 'in_progress') return { icon: 'mdi-progress-wrench', color: 'orange', text: "In Progress" }
      if (status === 'done') return { icon: 'mdi-check-circle', color: 'green', text: "Done" }
    },
    momentDate (date) {
      return moment(date).calendar({
        sameElse: 'YYYY-MM-DD HH:mm:ss'
      })
    },
  },
}
</script>

<style scoped>
.disable-events {
    pointer-events: none;
  }
.v-list.h-menu-list {
  padding: 0;
}
</style>
