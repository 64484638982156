import { render, staticRenderFns } from "./EmailSettingsClassicInboundFormDialog.vue?vue&type=template&id=2dbacf37&scoped=true"
import script from "./EmailSettingsClassicInboundFormDialog.vue?vue&type=script&lang=js"
export * from "./EmailSettingsClassicInboundFormDialog.vue?vue&type=script&lang=js"
import style0 from "./EmailSettingsClassicInboundFormDialog.vue?vue&type=style&index=0&id=2dbacf37&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dbacf37",
  null
  
)

export default component.exports