<template>
  <div>
    <v-toolbar
      v-if="hasPermission('change_emailsettings')"
      flat
      height=36
      color="grey lighten-4"
      dense
      class="ma-4">
      <v-toolbar-items>
        <v-btn
          v-if="!formIsEditing"
          text
          @click="edit()">
          <v-icon small left>mdi-pencil</v-icon>
          {{ $t('common.buttons.edit') }}
        </v-btn>
        <v-btn
          v-if="formIsEditing"
          text
          @click="cancel()">
          {{ $t('common.buttons.cancel') }}
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-form v-model="isFormValid" :disabled="!formIsEditing" class="pt-4 px-4">
      <v-row>
        <v-col cols=12 lg=6>
          <v-select
            v-model="form.config_type"
            item-value="value"
            outlined
            :menu-props="{ top: false, offsetY: true }"
            :label="$t('common.labels.configTemplate')"
            :loading="loadingEmaiLSettings"
            :items="emailConfigTypes"
            :rules="[formRules.required]"/>
        </v-col>
      </v-row>
    </v-form>
    <div class="px-4 pb-4">
      <v-divider class="my-2"/>
      <email-settings-classic-form
        v-if="form.config_type === 'classic'"
        :editing="formIsEditing"
        @toggleEdit="updateEditingStatus"/>
      <email-settings-office-365-form
        v-if="form.config_type === 'office_365'"
        :editing="formIsEditing"
        @toggleEdit="updateEditingStatus"/>
    </div>
  </div>
</template>

<script>
import BaseSettingsService from '@/services/base/base.settings.service'
import EmailSettingsClassicForm from '@/views/base/settings/settings/emails/EmailSettingsClassicForm'
import EmailSettingsOffice365Form from '@/views/base/settings/settings/emails/EmailSettingsOffice365Form'
import DialogUnsavedChanges from '@/common/mixins/DialogUnsavedChanges'
export default {
  name: 'EmailSettingsForm',
  mixins: [ DialogUnsavedChanges ],
  components: {
    EmailSettingsClassicForm,
    EmailSettingsOffice365Form,
  },
  data () {
    return {
      loadingEmaiLSettings: false,
      emailSettings: null,
      emailConfigTypes: [
        { text: this.$t('common.labels.emailConfigTemplateOffice'), value: 'office_365' },
        // { text: 'Gsuite', value: 'gsuite' },
        { text: this.$t('common.labels.emailConfigTemplateClassic'), value: 'classic' },
      ],
      formIsEditing: false,
      isFormValid: false,
      form: {
        config_type: '',
      },
      formRules: {
        required: value => !!value || this.$t('common.errors.required'),
      },
    }
  },
  async mounted () {
    await this.getEmailSettings()
    if (this.emailSettings) {
      this.initFormFields()
    }
  },
  methods: {
    edit () {
      this.formIsEditing = true
    },
    cancel () {
      this.formIsEditing = false
      if (this.emailSettings) {
        this.form.config_type = this.emailSettings.config_type
      } else {
        this.form.config_type = ''
      }
    },
    updateEditingStatus (editingStatus) {
      this.formIsEditing = editingStatus
    },
    async getEmailSettings () {
      this.loadingEmaiLSettings = true
      this.emailSettings = await BaseSettingsService.getEmailSettingsConfig()
      this.loadingEmaiLSettings = false
    },
    initFormFields () {
      for (const field in this.form) {
        this.form[field] = this.emailSettings[field]
      }
    },
  },
}
</script>
