<template>
  <div>
    <v-toolbar
      v-if="hasPermission('add_webhook')"
      flat
      height=36
      color="grey lighten-4"
      dense
      class="ma-4">
      <v-toolbar-items>
        <v-btn v-if="hasPermission('add_webhook')" text @click="createWebhook">
          <v-icon small left>mdi-plus</v-icon>
          {{ $t('common.buttons.create') }}
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-divider/>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="webhooks"
      :hide-default-footer="webhooks.length < 10">
      <template v-slot:loading>
        <div class="my-12">{{ $t('loading') }}...</div>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <template v-if="item.action === 'create_or_update_helpdesk_ticket'">
          {{ $t('applications.base.settings.integrations.webhooks.actions.createOrUpdateHelpdeskTicket') }}
        </template>
      </template>
      <template v-slot:[`item.url`]="{ item }">
        <v-tooltip bottom open-delay="250">
          <template v-slot:activator="{ on }">
            <span v-on="on">
              {{ item.url | truncate(25, '...') }}
            </span>
          </template>
          {{ item.url }}
        </v-tooltip>
        <v-tooltip bottom open-delay="250">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="copyWebhookURL(item)" icon>
              <v-icon small>mdi-content-copy</v-icon>
            </v-btn>
          </template>
          {{ $t('common.buttons.copyToClipboard') }}
        </v-tooltip>
      </template>
      <template v-slot:[`item.extra_params`]="{ item }">
        {{ item.extra_params }}
      </template>
      <template v-slot:[`item.is_active`]="{ item }">
        <v-icon v-if="item.is_active" color="green">mdi-check-bold</v-icon>
        <v-icon v-else color="red">mdi-close-thick</v-icon>
      </template>
      <template v-slot:[`item.create_date`]="{ item }">
        {{ item.create_date | moment("calendar") }}
      </template>
      <template v-slot:[`item.last_activation_date`]="{ item }">
        {{ item.last_activation_date | moment("calendar") }}
      </template>
      <template v-slot:[`item.last_deactivation_date`]="{ item }">
        <template v-if="item.last_deactivation_date">
          {{ item.last_deactivation_date | moment("calendar") }}
        </template>
      </template>
      <template v-slot:[`item.last_used_date`]="{ item }">
        <template v-if="item.last_used_date">
          {{ item.last_used_date | moment("calendar") }}
        </template>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip
          v-if="hasPermission('change_webhook')"
          bottom
          open-delay="250">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
              @click="item.is_active ? deactivateWebhook(item) : activateWebhook(item)">
              <v-icon v-if="item.is_active">mdi-archive-arrow-down</v-icon>
              <v-icon v-else>mdi-archive-arrow-up</v-icon>
            </v-btn>
          </template>
          {{ item.is_active ? $t('common.buttons.deactivate') : $t('common.buttons.activate') }}
        </v-tooltip>
        <v-tooltip
          v-if="hasPermission('delete_webhook')"
          bottom
          open-delay="250">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="deleteWebhook(item)" icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          {{ $t('common.buttons.delete') }}
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import BaseSettingsService from '@/services/base/base.settings.service'
import DeleteModal from '@/common/components/DeleteModal'
import WarningModal from '@/common/components/WarningModal'
import WebhookFormDialog from './WebhookFormDialog'
export default {
  name: 'WebhookList',
  data () {
    return {
      loading: false,
      webhooks: [],
    }
  },
  computed: {
    headers () {
      return [
        { value: 'name', text: this.$t('common.labels.name') },
        { value: 'create_date', text: this.$t('common.labels.creationDate') },
        { value: 'action', text: this.$tc('common.labels.actions', 1) },
        { value: 'url', text: this.$t('common.labels.url') },
        { value: 'extra_params', text: this.$tc('common.labels.parameters', 2) },
        { value: 'is_active', text: this.$t('common.labels.activated') },
        { value: 'last_activation_date', text: this.$t('common.labels.lastActivation') },
        { value: 'last_deactivation_date', text: this.$t('common.labels.lastDeactivation') },
        { value: 'last_used_date', text: this.$t('common.labels.lastUsed') },
        { value: 'actions', text: this.$tc('common.labels.actions', 2), sortable: false },
      ]
    },
  },
  async mounted () {
    await this.getWebhooks()
  },
  methods: {
    copyWebhookURL (webhook) {
      const origin = window.origin
      navigator.clipboard.writeText(`${origin}${webhook.url}`)
      this.$dialog.notify.success(
        this.$t('applications.base.settings.integrations.webhooks.messages.webhookTokenCopiedToClipboard'), {
          position: 'top-right',
          border: 'left',
          dense: true,
          timeout: 5000,
        }
      )
    },
    async getWebhooks () {
      this.loading = true
      this.webhooks = await BaseSettingsService.getWebhooks()
      this.loading = false
    },
    createWebhook () {
      this.$dialog.show(WebhookFormDialog, {
        persistent: true,
        waitForResult: true,
      }).then(async result => {
        if (result) {
          const createdWebhook = await BaseSettingsService.createWebhook({
            name: result.name,
            api_user_id: result.api_user.id,
            is_active: result.is_active,
            action: result.action,
            helpdesk_team_id: result.helpdesk_team ? result.helpdesk_team.id : null,
          })
          if (createdWebhook) {
            this.webhooks.push(createdWebhook)
            this.$dialog.notify.success(
              this.$t('applications.base.settings.integrations.webhooks.messages.webhookCreated', { name: createdWebhook.name }), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    activateWebhook (webhook) {
      this.$dialog.show(WarningModal, {
        icon: 'archive-arrow-up',
        title: this.$t('applications.base.settings.integrations.webhooks.common.activateWebhookTitle', { name: webhook.name }),
        message: this.$t('applications.base.settings.integrations.webhooks.common.activateWebhookText', { name: webhook.name }),
        okText: this.$t('common.buttons.yes'),
        okColor: 'green',
        noButton: true,
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const activatedWebhook = await BaseSettingsService.activateWebhook(webhook.id)
          if (activatedWebhook) {
            this.webhooks.splice(this.webhooks.indexOf(webhook), 1, activatedWebhook)
            this.$dialog.notify.success(
              this.$t('applications.base.settings.integrations.webhooks.messages.webhookActivated', { name: webhook.name }), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    deactivateWebhook (webhook) {
      this.$dialog.show(WarningModal, {
        icon: 'archive-arrow-down',
        title: this.$t('applications.base.settings.integrations.webhooks.common.deactivateWebhookTitle', { name: webhook.name }),
        message: this.$t('applications.base.settings.integrations.webhooks.common.deactivateWebhookText', { name: webhook.name }),
        okText: this.$t('common.buttons.yes'),
        okColor: 'green',
        noButton: true,
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const deactivatedWebhook = await BaseSettingsService.deactivateWebhook(webhook.id)
          if (deactivatedWebhook) {
            this.webhooks.splice(this.webhooks.indexOf(webhook), 1, deactivatedWebhook)
            this.$dialog.notify.success(
              this.$t('applications.base.settings.integrations.webhooks.messages.webhookDeactivated', { name: webhook.name }), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    deleteWebhook (webhook) {
      this.$dialog.show(DeleteModal, {
        titleText: this.$t('applications.base.settings.integrations.webhooks.common.deleteWebhookTitle', { name: webhook.name }),
        warningText: this.$t('applications.base.settings.integrations.webhooks.common.deleteWebhookWarningText', { name: webhook.name }),
        recordName: this.$tc('common.labels.webhooks', 1),
        recordNamePlural: this.$tc('common.labels.webhooks', 2),
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await BaseSettingsService.deleteWebhook(webhook.id)
          if (response) {
            this.webhooks.splice(this.webhooks.indexOf(webhook), 1)
            this.$dialog.notify.success(
              this.$t('applications.base.settings.integrations.webhooks.messages.webhookDeleted', { name: webhook.name }), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    }
  },
}
</script>
