<template>
  <div>
    <group-listing-toolbar
      :viewType="viewType"
      :selectedGroups="selectedGroups"
      @setListView="setListView"
      @setKanbanView="setKanbanView"
      @deleteGroups="deleteGroups"/>
    <transition name="router-anim" mode="out-in">
      <group-kanban
        v-if="viewType === 'kanban'"
        :groups="groups"
        :loading="loading"
        @deleteGroup="deleteGroup"/>
      <group-list
        v-else
        v-model="selectedGroups"
        :groups="groups"
        :loading="loading"
        @deleteGroup="deleteGroup"/>
    </transition>
  </div>
</template>

<script>
import GroupListingToolbar from '@/views/base/group/list/GroupListingToolbar'
import GroupKanban from '@/views/base/group/list/GroupKanban'
import GroupList from '@/views/base/group/list/GroupList'
import DeleteModal from '@/common/components/DeleteModal'
import BaseGroupService from '@/services/base/base.group.service'
export default {
  name: 'GroupListing',
  components: {
    GroupListingToolbar,
    GroupKanban,
    GroupList,
  },
  data () {
    return {
      loading: false,
      viewType: 'kanban',
      groups: [],
      selectedGroups: [],
    }
  },
  mounted () {
    this.getGroups(this.$store.state.url.urlSearchParams)
  },
  watch: {
    '$store.state.url.urlSearchParams' (newParams, oldParams) {
      this.getGroups(this.$store.state.url.urlSearchParams)
    },
  },
  methods: {
    setListView () {
      this.viewType = 'list'
    },
    setKanbanView () {
      this.viewType = 'kanban'
    },
    async getGroups (urlSearchParams) {
      this.loading = true
      this.groups = await BaseGroupService.getGroups(urlSearchParams)
      for (let group of this.groups) {
        this.addIsSelectableOnGroup(group)
      }
      this.loading = false
    },
    addIsSelectableOnGroup (group) {
      this.$set(group, 'isSelectable', this.isSelectable(group))
    },
    isSelectable (group) {
      return ![1].includes(group.id)
    },
    deleteGroup (group) {
      this.$dialog.show(DeleteModal, {
        titleText: this.$t('applications.base.groups.common.deleteGroupTitle', { name: group.name }),
        warningText: this.$t('applications.base.groups.common.deleteGroupWarningText', { name: group.name }),
        recordName: this.$tc('common.labels.groups', 1),
        recordNamePlural: this.$tc('common.labels.groups', 2),
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await BaseGroupService.deleteGroups({ ids: [group.id] })
          if (response) {
            this.groups.splice(this.groups.indexOf(group), 1)
            this.selectedGroups = []
            this.$dialog.notify.success(
              this.$t('applications.base.groups.messages.groupDeleted', { name: group.name }), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    deleteGroups (groupsToDelete) {
      this.$dialog.show(DeleteModal, {
        titleText: this.$tc('applications.base.groups.common.deleteGroupsTitle', groupsToDelete.length),
        warningText: this.$tc('applications.base.groups.common.deleteGroupsWarningText', groupsToDelete.length),
        recordName: this.$tc('common.labels.groups', 1),
        recordNamePlural: this.$tc('common.labels.groups', 2),
        summaryHeaders: [
          { value: 'name', text: this.$t('common.labels.name') },
          { value: 'users_count', text: `# ${this.$tc('common.labels.users', 2)}` },
        ],
        summaryRecords: groupsToDelete,
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const groupIdsToDelete = groupsToDelete.map(group => group.id)
          const response = await BaseGroupService.deleteGroups({ ids: groupIdsToDelete })
          if (response) {
            this.groups = this.groups.filter(undeletedGroup => !groupIdsToDelete.includes(undeletedGroup.id))
            this.selectedGroups = []
            this.$dialog.notify.success(
              this.$tc('applications.base.groups.messages.groupsDeleted', groupsToDelete.length , { count: groupsToDelete.length }), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>
  .router-anim-enter,
  .router-anim-leave-to {
    opacity: 0;
    transform: translateY(2em);
  }
  .router-anim-enter-active,
  .router-anim-leave-active {
    transition: all .20s ease;
  }
</style>
