<template>
  <v-card tile elevation="3">
    <v-toolbar
      v-if="
        supplier
        && !editing
        && (
          hasPermission('add_inventoryitemsupplier')
          || hasPermission('change_inventoryitemsupplier')
          || hasPermission('delete_inventoryitemsupplier')
        )
      "
      flat
      dense>
      <v-toolbar-items>
        <v-btn
          v-if="hasPermission('change_inventoryitemsupplier')"
          text
          @click="edit()">
          <v-icon small class="mr-1">mdi-pencil</v-icon>
          Edit
        </v-btn>
        <v-btn
          v-if="hasPermission('delete_inventoryitemsupplier')"
          text
          @click="deleteSupplier(supplier)">
          <v-icon small class="mr-1">mdi-delete</v-icon>
          Delete
        </v-btn>
        <v-btn
          v-if="hasPermission('add_inventoryitemsupplier')"
          text
          :to="{ name: 'InventoryItemSupplierFormCreate' }">
          <v-icon small class="mr-1">mdi-plus</v-icon>
          Create Another
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-divider v-if="supplier"/>
    <template v-if="!loadingSupplier">
      <v-form
        v-model="isFormValid"
        class="pa-4"
        :disabled="!editing">
        <v-text-field
          v-model="form.name"
          label="Name"
          required
          clearable
          autofocus
          type="text"
          maxlength="255"
          :counter="editing"
          :rules="[formRules.required]"/>
        <v-text-field
          v-model="form.email"
          label="Email"
          clearable
          type="email"
          maxlength="254"
          :counter="editing"
          :rules="[formRules.email]"/>
        <v-text-field
          v-model="form.phone"
          label="Phone"
          clearable
          type="tel"
          maxlength="100"
          :counter="editing"/>
      </v-form>
      <template v-if="editing">
        <v-divider/>
        <v-card-actions>
          <v-btn text @click="cancel()">
            Cancel
          </v-btn>
          <v-btn
            v-if="hasPermission('change_inventoryitemsupplier') || hasPermission('add_inventoryitemsupplier')"
            text
            color="green"
            :disabled="!isFormValid"
            @click="!supplier ? createSupplier() : updateSupplier(supplier)">
            Save
          </v-btn>
        </v-card-actions>
      </template>
    </template>
    <template v-else>
      <v-col cols=12 align="center" justify="center">
        <h-request-loading/>
      </v-col>
    </template>
  </v-card>
</template>

<script>
import HRequestLoading from '@/common/components/HRequestLoading'
import DeleteModal from '@/common/components/DeleteModal'
import InventoryItemSupplierService from '@/services/inventory/inventory.item.supplier.service.js'
export default {
  name: 'InventoryItemSupplierForm',
  components: {
    HRequestLoading,
  },
  props: {
    editing: { type: Boolean },
    supplierId: {
      type: [String, Number],
      default: () => { return null },
    },
  },
  data () {
    return {
      loadingSupplier: false,
      supplier: null,
      form: {
        name: '',
        phone: '',
        email: '',
      },
      isFormValid: false,
      formRules: {
        required: value => !!value || this.$t('common.errors.required'),
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return !value || pattern.test(value) || this.$t('common.errors.invalidEmail')
        },
      },
    }
  },
  async mounted () {
    if (this.supplierId) await this.getSupplier(this.supplierId)
    if (this.supplier) this.initFormFields()
  },
  methods: {
    async getSupplier (supplierId) {
      this.loadingSupplier = true
      this.supplier = await InventoryItemSupplierService.getSupplier(supplierId)
      this.loadingSupplier = false
    },
    initFormFields () {
      for (let field in this.form) {
        this.form[field] = this.supplier[field]
      }
    },
    edit () {
      this.$emit('toggleEdit', true)
    },
    cancelEdit () {
      this.$emit('toggleEdit', false)
    },
    cancel () {
      this.cancelEdit()
      if (this.supplier) {
        this.initFormFields()
      } else {
        history.back()
      }
    },
    async createSupplier () {
      const { name, phone, email } = this.$data.form
      const createdSupplier = await InventoryItemSupplierService.createSupplier({
        name: name,
        phone: phone,
        email: email,
      })
      if (createdSupplier) {
        this.cancelEdit()
        this.$router.push({ name: 'InventoryItemSupplierFormRead', params: { supplierId: createdSupplier.id } })
        this.$dialog.notify.success(
          `Supplier '${createdSupplier.name}' created`, {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    async updateSupplier (supplier) {
      const { name, phone, email } = this.$data.form
      const updatedSupplier = await InventoryItemSupplierService.updateSupplier(supplier.id, {
        name: name,
        phone: phone,
        email: email,
      })
      if (updatedSupplier) {
        this.supplier = updatedSupplier
        this.$dialog.notify.success(
          'Supplier updated', {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
        this.cancelEdit()
      }
    },
    async deleteSupplier (supplier) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Supplier',
        recordNamePlural: 'Suppliers',
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await InventoryItemSupplierService.deleteSuppliers({ ids: [supplier.id] })
          if (response) {
            this.$router.push({ name: 'InventoryItemSupplierListing' })
            this.$dialog.notify.success(
              `Supplier '${supplier.name}' deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
  },
}
</script>
