<template>
  <v-toolbar tile dense elevation="1">
    <v-btn
      v-if="hasPermission('add_contactcontacttag')"
      text
      :to="{ name: 'ContactContactTagFormCreate', params: { editing: true } }">
      <v-icon small left>mdi-plus</v-icon>
      {{ $t('common.buttons.create') }}
    </v-btn>
    <v-menu
      v-if="selectedTags.length && hasPermission('delete_contactcontacttag')"
      close-on-click
      close-on-content-click
      offsetY>
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on">
          {{ $tc('common.labels.actions', 2) }}
          <v-icon small right>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list class="h-menu-list">
        <v-list-item
          v-if="hasPermission('delete_contactcontacttag')"
          @click="$emit('deleteTags', selectedTags)">
          <v-icon small class="mr-4">mdi-delete</v-icon>
          {{ $t('common.buttons.delete') }}
        </v-list-item>
      </v-list>
    </v-menu>
    <v-spacer/>
    <h-filtering-bar :availableFilters="availableFilters"/>
    <v-spacer/>
    <v-btn
      tile
      icon
      link
      :class="{'v-btn--active': viewType === 'list'}"
      @click="$emit('setListView')">
      <v-icon>mdi-format-list-bulleted</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import HFilteringBar from '@/common/components/HFilteringBar'
export default {
  name: 'ContactContactTagListingToolbar',
  components: { HFilteringBar },
  props: {
    viewType: { type: String },
    selectedTags: {
      type: Array,
      default: () => { return [] },
    },
  },
  computed: {
    availableFilters () {
      return [
        { fieldName: 'name', label: this.$t('common.labels.name'), type: 'string' },
      ]
    },
  },
}
</script>

<style scoped>
  .v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:last-child {
    /* Override the default -12px right margin in order to be symmetrical */
    margin-right: 0px;
  }
  .v-toolbar {
    height: unset!important;
  }
  .v-toolbar::v-deep .v-toolbar__content {
    height: fit-content!important;
  }
  .v-list.h-menu-list {
    padding: 0;
  }
</style>
