<template>
  <DialogCard :actions="actions">
    <v-toolbar flat dark color="blue darken-1">
      <v-toolbar-title>
        <v-icon left>mdi-account-plus</v-icon>
        {{ $t('common.buttons.createUser') }} {{ $t('for') }}: {{ contact.full_name }}
      </v-toolbar-title>
    </v-toolbar>
    <v-form
      v-model="isFormValid"
      class="pa-4">
      <v-text-field
        v-model="form.username"
        required
        clearable
        autofocus
        type="text"
        maxlength="150"
        counter
        :label="$t('common.labels.username')"
        :rules="[formRules.required]"/>
      <v-text-field
        v-model="form.password"
        :label="$t('common.labels.password')"
        required
        clearable
        :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"
        maxlength="128"
        counter
        :rules="[formRules.required]"/>
      <v-select
        v-model="form.user_type"
        item-value="value"
        :items="userTypes"
        :label="$t('common.labels.userType')"
        :persistent-hint="!!form.user_type"
        :hint="userTypeFormHint"
        :menu-props="{ top: false, offsetY: true }"
        :rules="[formRules.required]">
        <template v-slot:item="{ item }">
          <v-chip
            small
            label
            :color="item.value === 'system' ? 'green' : 'primary'"
            class="font-weight-bold white--text">
            {{ item.text }}
          </v-chip>
        </template>
        <template v-slot:selection="data">
          <v-chip
            small
            label
            :color="data.item.value === 'system' ? 'green' : 'primary'"
            class="font-weight-bold white--text">
            {{ data.item.text }}
          </v-chip>
        </template>
      </v-select>
    </v-form>
    <v-divider/>
  </DialogCard>
</template>

<script>
export default {
  name: 'UserFromContactFormDialog',
  props: {
    contact: { type: Object },
  },
  data () {
    return {
      userTypes: [
        { text: 'System', value: 'system' },
        { text: 'Portal', value: 'portal' },
        { text: 'Portal Referent', value: 'portal_referent'},
      ],
      form: {
        user_type: null,
        username: '',
        password: '',
      },
      showPassword: false,
      isFormValid: false,
      formRules: {
        required: value => !!value || this.$t('common.errors.required'),
      },
    }
  },
  computed: {
    userTypeFormHint () {
      if (this.form.user_type === 'system') {
        return this.$t('applications.contact.userFromContactFormDialog.userTypeFormHint.system')
      } else if (this.form.user_type === 'portal') {
        return this.$t('applications.contact.userFromContactFormDialog.userTypeFormHint.portal')
      } else {
        return this.$t('applications.contact.userFromContactFormDialog.userTypeFormHint.portalRef')
      }
    },
    actions () {
      return {
        false: {
          text: this.$t('common.buttons.cancel'),
        },
        true: {
          text: this.$t('common.buttons.save'),
          color: 'green',
          disabled: !this.isFormValid,
          handler: () => {
            return new Promise(resolve => {
              resolve(this.form)
            })
          },
        },
      }
    },
  },
}
</script>

<style scoped>
  .v-card::v-deep
    .v-card__text {
      padding: 0;
    }
</style>
