<template>
  <DialogCard :actions="actions">
    <v-toolbar flat dark color="blue darken-1">
      <v-toolbar-title>
        <v-icon left>mdi-download</v-icon>
        {{ $t('common.buttons.export') }}
      </v-toolbar-title>
    </v-toolbar>
    <v-form v-model="isFormValid" class="pa-4" ref="form">
      <v-radio-group v-model="form.exportSelection" row mandatory :label="$t('common.labels.selection')">
        <v-radio :label="$t('all')" value="all"/>
        <v-radio :label="$t('selectionOnly')" value="selection" :disabled="selectionCount === 0"/>
      </v-radio-group>
      <v-select
        v-model="form.exportType"
        label="Type"
        :items="exportTypes"
        :menu-props="{ top: false, offsetY: true }"/>
      <h-date-picker
        v-if="form.exportSelection === 'all'"
        v-model="form.from"
        :label="$t('common.labels.from')"
        :rules="[formRules.required, ...formFromRules]"/>
      <h-date-picker
        v-if="form.exportSelection === 'all'"
        v-model="form.to"
        :label="$t('common.labels.to')"
        :rules="[formRules.required, ...formToRules]"/>
    </v-form>
    <v-divider/>
  </DialogCard>
</template>

<script>
import HDatePicker from '@/common/components/forms/HDatePicker'
export default {
  name: 'TimesheetItemExportDialog',
  components: { HDatePicker },
  props: {
    selectionCount: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      exportTypes: ['csv', 'xlsx'],
      form: {
        exportSelection: 'all',
        exportType: 'csv',
        from: '',
        to: '',
      },
      isFormValid: false,
      formRules: {
        required: value => !!value || this.$t('common.errors.required'),
      },
    }
  },
  computed: {
    formFromRules () {
      const rules = []
      if (this.form.from && this.form.to) {
        const rule = () => this.form.from < this.form.to || this.$t('common.errors.dateFromBeforeTo')
        rules.push(rule)
      }
      return rules
    },
    formToRules () {
      const rules = []
      if (this.form.from && this.form.to) {
        const rule = () => this.form.from < this.form.to || this.$t('common.errors.dateToAfterFrom')
        rules.push(rule)
      }
      return rules
    },
    actions () {
      return {
        false: {
          text: this.$t('common.buttons.cancel'),
        },
        true: {
          text: this.$t('common.buttons.export'),
          color: 'primary',
          disabled: !this.isFormValid,
          handler: () => {
            return new Promise(resolve => {
              resolve(this.form)
            })
          },
        },
      }
    },
  },
  watch: {
    'form.from': async function () {
      await this.$nextTick()
      this.$refs.form.validate()
    },
    'form.to': async function () {
      await this.$nextTick()
      this.$refs.form.validate()
    },
  },
}
</script>

<style scoped>
  .v-card::v-deep
    .v-card__text {
      padding: 0;
    }
</style>
