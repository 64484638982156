<template>
  <DialogCard :actions="actions">
    <v-toolbar
      flat
      dark
      color="blue darken-1">
      <v-toolbar-title>
        <v-icon left>mdi-plus</v-icon>
        {{ slaLevel ? 'Update' : 'Create' }} SLA Level
      </v-toolbar-title>
    </v-toolbar>
    <v-form
      v-model="isFormValid"
      class="pa-4">
      <v-text-field
        v-model="form.name"
        label="Name"
        required
        clearable
        autofocus
        type="text"
        maxlength="255"
        counter
        :rules="[formRules.required]"/>
      <v-text-field
        v-model.number="form.hours_to_first_message"
        type="number"
        label="Hours To First Message"
        suffix="hours"
        outlined
        :hint="hoursToFirstMessageHint"
        persistent-hint
        class="my-4"
        :rules="[formRules.positiveNumber]"/>
      <v-text-field
        v-model.number="form.hours_to_first_answer"
        type="number"
        label="Hours To First Answer"
        suffix="hours"
        outlined
        :hint="hoursToFirstAnswerHint"
        persistent-hint
        class="my-4"
        :rules="[formRules.positiveNumber]"/>
      <v-text-field
        v-model.number="form.hours_to_close"
        type="number"
        label="Hours To Close"
        suffix="hours"
        outlined
        :hint="hoursToCloseHint"
        persistent-hint
        class="my-4"
        :rules="[formRules.positiveNumber]"/>
    </v-form>
  </DialogCard>
</template>

<script>
export default {
  name: 'HelpdeskSLALevelFormDialog',
  props: {
    slaLevel: { type: Object, default: () => { return null } }
  },
  data () {
    return {
      form: {
        name: this.slaLevel ? this.slaLevel.name : '',
        hours_to_first_message: this.slaLevel ? this.slaLevel.hours_to_first_message : 0,
        hours_to_first_answer: this.slaLevel ? this.slaLevel.hours_to_first_answer : 0,
        hours_to_close: this.slaLevel ? this.slaLevel.hours_to_close : 0,
      },
      isFormValid: false,
      formRules: {
        required: value => !!value || this.$t('common.errors.required'),
        positiveNumber: value => value >= 0 || 'Must be >= 0.',
      }
    }
  },
  computed: {
    hoursToFirstMessageHint: function () {
      if (this.form.hours_to_first_message === 0) {
        return "Hours to first message won't be calculated if 0."
      }
      return ''
    },
    hoursToFirstAnswerHint: function () {
      if (this.form.hours_to_first_answer === 0) {
        return "Hours to first answer won't be calculated if 0."
      }
      return ''
    },
    hoursToCloseHint: function () {
      if (this.form.hours_to_close === 0) {
        return "Hours to close won't be calculated if 0."
      }
      return ''
    },
    actions () {
      return {
        false: {
          text: 'Cancel'
        },
        true: {
          text: 'Save',
          color: 'green',
          disabled: !this.isFormValid,
          handler: () => {
            return new Promise(resolve => {
              resolve(this.form)
            })
          }
        }
      }
    }
  },
}
</script>

<style scoped>
  .v-card::v-deep
    .v-card__text {
      padding: 0;
    }
</style>
