import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index'
import { hasPermission } from '@/common/helpers/hasPermission'
import { getIsLoggedIn, handleUnloggedStatus } from './tools'
import ErrorModal from '@/common/components/ErrorModal'
import WarningModal from '@/common/components/WarningModal'
import LoginForm from '@/views/base/LoginForm'
import ResetPasswordRequestForm from '@/views/base/ResetPasswordRequestForm'
import ResetPasswordForm from '@/views/base/ResetPasswordForm'
import HomePage from '@/views/base/HomePage'
import NotFound from '@/views/base/NotFound'
import baseRoutes from './modules/base/index'
import contactRoutes from './modules/contact/index'
import helpdeskRoutes from './modules/helpdesk/index'
import inventoryRoutes from './modules/inventory/index'
import timesheetsRoutes from './modules/timesheets/index'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'LoginForm',
      component: LoginForm,
    },
    {
      path: '/request-password-reset',
      name: 'ResetPasswordRequestForm',
      component: ResetPasswordRequestForm,
    },
    {
      path: '/reset-password',
      name: 'ResetPasswordForm',
      component: ResetPasswordForm,
    },
    {
      path: '/',
      name: 'HomePage',
      component: HomePage,
      meta: { requiresAuth: true },
    },
    ...baseRoutes,
    ...contactRoutes,
    ...helpdeskRoutes,
    ...inventoryRoutes,
    ...timesheetsRoutes,
    {
      path: '*',
      name: 'NotFound',
      component: NotFound,
    },
  ],
})

/*
  --------------------------------------
  *          ROUTER FUNCTIONS          *
  --------------------------------------
*/

const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(error => {
    if (error.name !== 'NavigationDuplicated') throw error
  })
}

/*
  -----------------------------------
  *          ROUTER GUARDS          *
  -----------------------------------
*/

router.beforeEach(async (to, from, next) => {
  const isLoggedIn = getIsLoggedIn()
  if (isLoggedIn) {
    store.commit('SET_IS_LOGGED_IN', isLoggedIn)
  }
  if (to.name === 'LoginForm' && isLoggedIn) {
    await router.push({ name: 'HomePage' })
    return
  }
  if (to.meta.requiresAuth) {
    if (isLoggedIn) {
      if (!store.state.login.me) {
        await store.dispatch('FETCH_ME')
      }
      if (to.meta.permission) {
        if (hasPermission(to.meta.permission)) {
          next()
        } else {
          router.app.$dialog.show(ErrorModal, {
            persistent: true,
            icon: 'close-octagon',
            title: 'Permission Denied',
            message: `Permission '${to.meta.permission}' is required to access '${to.name}' view.`,
            advice: 'Contact your local Administrator for more information.',
            okText: 'GO BACK',
            okColor: 'primary',
            okHandler: function () {
              return new Promise(async resolve => {
                if (from?.name === 'LoginForm' || from?.name === null) {
                  const me = store.state.login.me
                  if (me.user_type === 'system') {
                    await router.push({ name: 'HomePage' })
                  } else if (['portal', 'portal_referrent'].includes(me.user_type)) {
                    await router.push({ name: 'HelpdeskPortalTicketList' })
                  }
                } else {
                  if (from) {
                    await router.push(from)
                  } else {
                    history.back()
                  }
                }
                resolve()
              })
            },
          })
        }
      } else {
        next()
      }
    } else {
      const docDataApp = document.querySelectorAll("div[data-app='true']")
      if (docDataApp.length) {
        let res = await router.app.$dialog.show(WarningModal, {
          persistent: true,
          waitForResult: true,
          icon: 'login',
          title: 'Warning: Login Required',
          message: 'Your session may have expired.',
          advice: 'Please log again and retry.',
          okColor: 'primary',
          okHandler: function () {
            return new Promise(resolve => {
              resolve()
            })
          },
        })
        if (res) {
          handleUnloggedStatus()
        }
      } else {
        // TODO : handleUnloggedStatus() here too ?
      }
      const redirectTo = {
        perm: to.meta?.permission,
      }
      localStorage.setItem('redirectTo', JSON.stringify(redirectTo))
      await router.push({ name: 'LoginForm', query: { redirect: to.fullPath } })
    }
  } else {
    next()
  }
})

/*
  --------------------------------------
  *          EXPORT STATEMENT          *
  --------------------------------------
*/

export default router
