<template>
  <v-main class="apps-content">
    <v-img
      :src="require('@/assets/images/hubbiz_home_wallpaper.jpeg')"
      alt="homepage background image"
      class="homepage-background-image"/>
    <v-container fluid>
      <v-row
        v-if="authorizedApplications.length"
        class="apps-row justify-center">
        <v-col
          v-for="application in authorizedApplications"
          :key="application.id"
          class="app-col">
          <v-tooltip bottom open-delay="50">
            <template v-slot:activator="{ on }">
              <v-card
                width="120"
                height="120"
                elevation="15"
                v-on="on"
                @click="routeToApplication(application.name)"
                class="app-card">
                <v-icon class="mx-auto" color="#F2F2F2" elevation="15" size="84">
                  mdi-{{ application.icon }}
                </v-icon>
              </v-card>
            </template>
            <span>{{ $t(`applicationNames.${application.name}`) }}</span>
          </v-tooltip>
        </v-col>
        <v-col v-if="hasAccess('base') || hasAccess('auth')" class="app-col">
          <v-tooltip bottom open-delay="50">
            <template v-slot:activator="{ on }">
              <v-card
                width="120"
                height="120"
                elevation="15"
                v-on="on"
                @click="routeToApplication('settings')"
                class="app-card">
                <v-icon class="mx-auto" color="#F2F2F2" elevation="15" size="84">
                  mdi-cogs
                </v-icon>
              </v-card>
            </template>
            <span>{{ $t('applicationNames.base') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row v-else class="apps-row justify-center">
        <v-alert
          prominent
          text
          outlined
          icon="mdi-account-lock"
          type="info">
          {{ $t('globalPages.home.noAccessText') }}
          <br>
          {{ $t('globalPages.home.askAdminText') }}
        </v-alert>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { getFirstAuthorizedViewNameFromRoutePath } from '@/router/tools'
export default {
  name: 'HomePage',
  computed: {
    authorizedApplications () {
      if (this.$store.state.login.me) {
        return this.$store.state.base.activatedApplications.filter(app => this.hasAccess(app.name))
      }
      return []
    },
  },
  methods: {
    routeToApplication (appName) {
      this.$router.push({ name: getFirstAuthorizedViewNameFromRoutePath(`/${appName}`) })
    },
  },
}
</script>

<style scoped>
  .v-main.apps-content > .v-main__wrap {
    position: relative;
    /* background: linear-gradient(to bottom, #F2F2F2 0%, #C2C2C2 100%); */
  }
  .homepage-background-image {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
    height: 100%;
    width: 100%;
  }
  div.container {
    position: relative;
  }
  .row.apps-row {
    margin-top: 10em;
    margin-left: 5em;
    margin-right: 5em;
  }
  .col.app-col {
    max-width: fit-content;
  }
  .v-card.app-card {
    display: flex;
    transition: all 0.20s;
    background: linear-gradient(to bottom, #29B6F6 0%, #01579B 100%);
  }
  .v-card.app-card:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
</style>
