<template>
  <v-card tile elevation="3">
    <v-toolbar
      flat
      dense
      v-if="
        group
        && !editing
        && (
          hasPermission('add_group')
          || hasPermission('change_group')
          || hasPermission('delete_group')
        )">
      <v-toolbar-items>
        <v-btn
          text
          v-if="hasPermission('change_group') && group.id !== 1"
          @click="edit()">
          <v-icon small class="mr-1">mdi-pencil</v-icon>
          {{ $t('common.buttons.edit') }}
        </v-btn>
        <v-btn
          text
          v-if="hasPermission('delete_group') && group.id !== 1"
          @click="deleteGroup(group)">
          <v-icon small class="mr-1">mdi-delete</v-icon>
          {{ $t('common.buttons.delete') }}
        </v-btn>
        <v-btn
          text
          v-if="hasPermission('add_group')"
          :to="{ name: 'GroupFormCreate' }">
          <v-icon small class="mr-1">mdi-plus</v-icon>
          {{ $t('common.buttons.create') }}
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-divider v-if="group"/>
    <v-tabs v-model="selectedFormTab" vertical background-color="grey lighten-4">
      <v-tab>
        <v-tooltip right open-delay="250">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-form-select</v-icon>
          </template>
          {{ $tc('common.labels.groups', 1) }}
        </v-tooltip>
      </v-tab>
      <v-tab :disabled="!group || editing">
        <v-tooltip right open-delay="250">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-account-plus</v-icon>
          </template>
          {{ $tc('common.labels.users', 2) }}
        </v-tooltip>
      </v-tab>
      <v-tab :disabled="!group || editing">
        <v-tooltip right open-delay="250">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-lock-open-check</v-icon>
          </template>
          {{ $tc('common.labels.permissions', 2) }}
        </v-tooltip>
      </v-tab>
      <v-tab-item>
        <template v-if="!loadingGroup">
          <v-form
            class="pa-4"
            v-model="isFormValid"
            :disabled="!editing">
            <v-text-field
              v-model="form.name"
              :label="$t('common.labels.name')"
              required
              clearable
              autofocus
              type="text"
              maxlength="150"
              :counter="editing"
              :rules="[formRules.required]"/>
          </v-form>
          <template v-if="editing">
            <v-divider/>
            <v-card-actions>
              <v-btn text @click="cancel()">
                {{ $t('common.buttons.cancel') }}
              </v-btn>
              <v-btn
                text
                color="green"
                :disabled="!isFormValid"
                v-if="hasPermission('change_group') || hasPermission('add_group')"
                @click="!group ? createGroup() : updateGroup(group)">
                {{ $t('common.buttons.save') }}
              </v-btn>
            </v-card-actions>
          </template>
        </template>
        <template v-else>
          <v-col cols=12 align="center" justify="center">
            <h-request-loading/>
          </v-col>
        </template>
      </v-tab-item>
      <v-tab-item v-if="group" class="pa-4">
        <v-row class="my-0">
          <v-col class="pb-0">
            <v-autocomplete
              v-if="hasPermission('change_group')"
              v-model="selectedUsers"
              multiple
              hide-selected
              clearable
              return-object
              item-text="full_name"
              :items="usersGroupHasNNot"
              :label="$t('applications.base.groups.form.usersTab.selectUsersToAdd')"
              :loading="loadingUsersInput"
              @click="getUsersInput">
              <template v-slot:item="data">
                <v-list-item-avatar>
                  <v-img :src="data.item.picture ? getPictureURL(data.item.picture) : require('@/assets/images/no-user-picture.jpeg')"/>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.full_name"/>
                  <v-list-item-subtitle v-html="data.item.username"/>
                </v-list-item-content>
              </template>
              <template v-slot:selection="data">
                <v-chip
                  small
                  pill
                  close
                  class="font-weight-bold"
                  @click:close="removeUserFromSelection(data.item)">
                  <v-avatar left>
                    <v-img :src="data.item.picture ? getPictureURL(data.item.picture) : require('@/assets/images/no-user-picture.jpeg')"/>
                  </v-avatar>
                  {{ data.item.full_name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col class="pb-0 d-flex align-center">
            <v-btn
              text
              color="green"
              v-if="selectedUsers.length && hasPermission('change_group')"
              @click="addUsersInGroup(group, selectedUsers)">
              <v-icon small class="mr-1">mdi-plus</v-icon>
              {{ addUsersButtonText }}
            </v-btn>
          </v-col>
        </v-row>
        <v-card outlined>
          <v-card-title>
            {{ $tc('common.labels.users', 2)}}
            <v-spacer/>
            <v-text-field
              v-model="groupUserSearch"
              append-icon="mdi-magnify"
              single-line
              hide-details
              :label="$t('applications.base.groups.form.usersTab.searchUsers')"/>
          </v-card-title>
          <v-data-table
            :loading="loadingGroupUsers"
            :headers="groupUsersHeaders"
            :items="groupUsers"
            :search="groupUserSearch">
            <template v-slot:loading>
              <div class="my-12">{{ $t('loading') }}...</div>
            </template>
            <template v-slot:[`item.is_active`]="{ item }">
              <v-icon v-if="item.is_active" color="green">mdi-check-bold</v-icon>
              <v-icon v-else color="red">mdi-close-thick</v-icon>
            </template>
            <template v-slot:[`item.last_login`]="{ item }">
              <template v-if="item.last_login">
                {{ item.last_login | moment("calendar") }}
              </template>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip
                v-if="
                  hasPermission('change_group')
                  && ![1, 2].includes(item.id)
                "
                bottom
                open-delay="250">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" @click="removeUserFromGroup(group, item)" icon>
                    <v-icon color="red">mdi-close-circle</v-icon>
                  </v-btn>
                </template>
                {{ $t('common.buttons.remove') }}
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="group" class="pa-4">
        <v-toolbar
          v-if="hasPermission('change_group') && group.id !== 1"
          flat
          height=36
          color="grey lighten-4"
          class="mt-4">
          <v-toolbar-items>
            <v-btn
              text
              small
              @click="selectAllPermissions()">
              <v-icon small class="mr-1">mdi-checkbox-multiple-outline</v-icon>
              {{ $t('common.buttons.selectAll') }}
            </v-btn>
            <v-btn
              text
              small
              @click="unselectAllPermissions()">
              <v-icon small class="mr-1">mdi-checkbox-multiple-blank-outline</v-icon>
              {{ $t('common.buttons.unselectAll') }}
            </v-btn>
            <v-divider vertical v-if="modifiedPermissionIds.length"/>
            <v-btn
              small
              text
              color="red"
              v-if="modifiedPermissionIds.length"
              @click="resetPermissions()">
              <v-icon small class="mr-1">mdi-restore</v-icon>
              {{ $t('common.buttons.reset') }}
            </v-btn>
            <v-btn
              small
              text
              color="green"
              v-if="modifiedPermissionIds.length && hasPermission('change_group')"
              @click="updatePermissions(group, modifiedPermissionIds)">
              <v-icon small class="mr-1">mdi-content-save</v-icon>
              {{ $t('common.buttons.save') }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card outlined class="mt-4">
          <v-card-title>{{ $tc('common.labels.permissions', 2) }}</v-card-title>
          <v-treeview
            v-model="permissionsSelection"
            :items="applications"
            :disabled="!hasPermission('change_group') || group.id === 1"
            selected-color="blue darken-2"
            open-on-click
            item-children="children"
            item-key="id"
            item-text="name"
            hoverable
            selectable>
            <template v-slot:label="{ item }">
              <span :class="{'modified-perm': shouldBeMarkedModified(item)}">{{ item.name }}</span>
            </template>
          </v-treeview>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import HRequestLoading from '@/common/components/HRequestLoading'
import DeleteModal from '@/common/components/DeleteModal'
import BaseGroupService from '@/services/base/base.group.service'
import BaseUserService from '@/services/base/base.user.service'
export default {
  name: 'GroupForm',
  components: {
    HRequestLoading
  },
  props: {
    editing: { type: Boolean },
    groupId: {
      type: [String, Number],
      default: () => { return null }
    }
  },
  watch: {
    permissionsSelection (newValue, oldValue) {
      const unselectedPermissionsIds = this.initialTruePermissionsIds.flatMap((id) => {
        if (!newValue.includes(id)) {
          return [id]
        }
        return []
      })
      const selectedPermissionsIds = this.initialFalsePermissionsIds.flatMap((id) => {
        if (newValue.includes(id)) {
          return [id]
        }
        return []
      })
      this.modifiedPermissionIds = selectedPermissionsIds.concat(unselectedPermissionsIds)
    }
  },
  data () {
    return {
      selectedFormTab: 0,
      groupUserSearch: '',
      loadingGroupUsers: false,
      loadingUsersInput: false,
      loadingGroup: false,
      loadingGroupPermissions: false,
      group: null,
      groupUsers: [],
      users: [],
      form: {
        name: ''
      },
      isFormValid: false,
      formRules: {
        required: value => !!value || this.$t('common.errors.required'),
      },
      selectedUsers: [],
      applications: [],
      permissionsSelection: [],
      initialTruePermissionsIds: [],
      initialFalsePermissionsIds: [],
      modifiedPermissionIds: []
    }
  },
  async mounted () {
    if (this.groupId) {
      await this.getGroup(this.groupId)
      if (this.group) {
        this.initFormFields()
        this.getGroupUsers(this.groupId)
        this.getGroupPermissions(this.groupId)
      }
    }
  },
  computed: {
    groupUsersHeaders () {
      return [
        { value: 'full_name', text: this.$t('common.labels.fullName') },
        { value: 'username', text: this.$t('common.labels.username') },
        { value: 'email', text: this.$tc('common.labels.emails', 1) },
        { value: 'is_active', text: this.$t('common.labels.activated') },
        { value: 'last_login', text: this.$t('common.labels.lastLogin') },
        { value: 'actions', text: this.$tc('common.labels.actions', 2), sortable: false }
      ]
    },
    addUsersButtonText () {
      return this.$tc('applications.base.groups.form.usersTab.addUserButton', this.selectedUsers.length)
    },
    usersGroupHasNNot () {
      const groupUsersIds = this.groupUsers.map(user => user.id)
      return this.users.filter(user => !groupUsersIds.includes(user.id))
    }
  },
  methods: {
    async getGroup (groupId) {
      this.loadingGroup = true
      this.group = await BaseGroupService.getGroup(groupId)
      this.loadingGroup = false
    },
    async getUsersInput () {
      this.loadingUsersInput = true
      this.users = await BaseUserService.getUsersInput()
      this.loadingUsersInput = false
    },
    async getGroupUsers (groupId) {
      this.loadingGroupUsers = true
      this.groupUsers = await BaseGroupService.getGroupUsers(groupId)
      this.loadingGroupUsers = false
    },
    async getGroupPermissions (groupId) {
      this.loadingGroupPermissions = true
      this.applications = await BaseGroupService.getGroupPermissions(groupId)
      this.loadingGroupPermissions = false
      this.preparePermissionsData(this.applications)
    },
    initFormFields () {
      for (let field in this.form) {
        this.form[field] = this.group[field]
      }
    },
    edit () {
      this.selectedFormTab = 0
      this.$emit('toggleEdit', true)
    },
    cancelEdit () {
      this.$emit('toggleEdit', false)
    },
    cancel () {
      this.cancelEdit()
      if (this.group) {
        for (let field in this.form) {
          this.form[field] = this.group[field]
        }
      } else {
        history.back()
      }
    },
    shouldBeMarkedModified (item) {
      if (!item.children) {
        // We have a permission here.
        if (this.modifiedPermissionIds.includes(item.id)) {
          return true
        }
        return false
      } else if (item.children?.length > 0) {
        // We have a parent here (model or application layer).
        for (const child of item.children) {
          const modified = this.shouldBeMarkedModified(child)
          if (modified) {
            return modified
          }
        }
      }
    },
    preparePermissionsData (permissionsByModelsByApplication) {
      this.permissionsSelection = []
      this.initialTruePermissionsIds = []
      this.initialFalsePermissionsIds = []
      for (let application of permissionsByModelsByApplication) {
        application.id = `a-${application.id}`
        application['children'] = application['models']
        delete application['models']
        for (let model of application.children) {
          model.id = `m-${model.id}`
          model['children'] = model['permissions']
          delete model['permissions']
          for (const permission of model.children) {
            if (permission.has_permission) {
              this.permissionsSelection.push(permission.id)
              this.initialTruePermissionsIds.push(permission.id)
            } else {
              this.initialFalsePermissionsIds.push(permission.id)
            }
          }
        }
      }
    },
    async updatePermissions (group, permissionIds) {
      const updatedPermissions = await BaseGroupService.updateGroupPermissions(group.id, { permission_ids: permissionIds })
      if (updatedPermissions) {
        this.applications = updatedPermissions
        this.preparePermissionsData(this.applications)
        this.$dialog.notify.success(
          this.$t('applications.base.groups.messages.permissionsUpdated'), {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    resetPermissions () {
      for (const app of this.applications) {
        for (const model of app.children) {
          for (let perm of model.children) {
            if (this.permissionsSelection.includes(perm.id) && perm.has_permission === false) {
              this.permissionsSelection.splice(this.permissionsSelection.indexOf(perm.id), 1)
            } else if (!this.permissionsSelection.includes(perm.id) && perm.has_permission === true) {
              this.permissionsSelection.push(perm.id)
            }
          }
        }
      }
    },
    selectAllPermissions () {
      for (const app of this.applications) {
        for (const model of app.children) {
          for (let perm of model.children) {
            if (!this.permissionsSelection.includes(perm.id)) {
              this.permissionsSelection.push(perm.id)
            }
          }
        }
      }
    },
    unselectAllPermissions () {
      this.permissionsSelection = []
    },
    async createGroup () {
      const { name } = this.$data.form
      const createdGroup = await BaseGroupService.createGroup({ name: name })
      if (createdGroup) {
        this.cancelEdit()
        this.$dialog.notify.success(
          this.$t('applications.base.groups.messages.groupCreated', { name: createdGroup.name }), {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
        this.$router.push({
          name: 'GroupFormRead',
          params: {
            groupId: createdGroup.id
          }
        })
      }
    },
    async updateGroup (group) {
      const { name } = this.$data.form
      const updatedGroup = await BaseGroupService.updateGroup(group.id, { name: name })
      if (updatedGroup) {
        this.cancelEdit()
        this.group = updatedGroup
        this.$dialog.notify.success(
          this.$t('applications.base.groups.messages.groupUpdated', { name: group.name }), {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    deleteGroup (group) {
      this.$dialog.show(DeleteModal, {
        titleText: this.$t('applications.base.groups.common.deleteGroupTitle', { name: group.name }),
        warningText: this.$t('applications.base.groups.common.deleteGroupWarningText', { name: group.name }),
        recordName: this.$tc('common.labels.groups', 1),
        recordNamePlural: this.$tc('common.labels.groups', 2),
        persistent: true,
        waitForResult: true
      }).then(async yes => {
        if (yes) {
          const response = await BaseGroupService.deleteGroups({ ids: [group.id] })
          if (response) {
            this.$dialog.notify.success(
              this.$t('applications.base.groups.messages.groupDeleted', { name: group.name }), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
            this.$router.push({ name: 'GroupListing' })
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    async addUsersInGroup (group, selectedUsers) {
      const userIds = selectedUsers.map(user => user.id)
      const groupUsers = await BaseGroupService.addUsersToGroup(group.id, {
        user_ids: userIds
      })
      if (groupUsers) {
        this.groupUsers = groupUsers
        this.selectedUsers = []
        this.$dialog.notify.success(
          this.$tc('applications.base.groups.messages.usersAddedToGroup', selectedUsers.length, { count: selectedUsers.length, name: group.name }), {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    async removeUserFromGroup (group, user) {
      const groupUsers = await BaseGroupService.removeUserFromGroup(group.id, { user_id: user.id })
      if (groupUsers) {
        this.groupUsers = groupUsers
        this.$dialog.notify.success(
          this.$t('applications.base.groups.messages.userRemovedFromGroup', { fullName: user.full_name, username: user.username, name: group.name }), {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    removeUserFromSelection (user) {
      this.selectedUsers = this.selectedUsers.filter(item => item.id !== user.id)
    }
  }
}
</script>

<style scoped>
  span.modified-perm {
    color: #FFCA28;
  }
</style>
