<template>
  <DialogCard :actions="actions">
    <v-toolbar flat dark color="blue darken-1">
      <v-toolbar-title>
        <v-icon left>mdi-plus</v-icon>
        {{ $t('common.buttons.createAPIKey') }}
      </v-toolbar-title>
    </v-toolbar>
    <v-form v-model="isFormValid" class="pa-4">
      <v-text-field
        v-model="form.name"
        placeholder="'Zabbix',..."
        required
        autofocus
        type="text"
        maxlength="30"
        counter
        persistent-hint
        :hint="$t('applications.base.settings.integrations.apikeys.form.hints.useMeaningfulName')"
        :label="$t('common.labels.name')"
        :rules="[formRules.required]"/>
      <v-switch
        v-model="form.is_active"
        inset
        dense
        color="green">
        <template v-slot:label>
          <span class="caption">{{ form.is_active ? $t('common.labels.activated') : $t('common.labels.deactivated') }}</span>
        </template>
      </v-switch>
    </v-form>
    <v-divider/>
  </DialogCard>
</template>

<script>
export default {
  name:  'APIKeyFormDialog',
  data () {
    return {
      isFormValid: false,
      form: {
        name: '',
        is_active: true,
      },
      formRules: {
        required: value => !!value || this.$t('common.errors.required'),
      },
    }
  },
  computed: {
    actions () {
      return {
        false: {
          text: this.$t('common.buttons.cancel'),
        },
        true: {
          text: this.$t('common.buttons.save'),
          color: 'green',
          disabled: !this.isFormValid,
          handler: () => {
            return new Promise(resolve => {
              resolve(this.form)
            })
          },
        },
      }
    },
  },
}
</script>

<style scoped>
  .v-card::v-deep .v-card__text {
    padding: 0;
  }
</style>
