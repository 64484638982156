<template>
  <v-main class="notfound-content">
    <v-card
      flat
      tile
      color="transparent"
      class="mx-auto my-auto">
      <v-card-title class="mb-3">
        <h1 class="mx-auto">Oops!</h1>
      </v-card-title>
      <v-card-subtitle class="text-center">
        <h3>404 - {{ $t('globalPages.notFound.pageNotFound') }}</h3>
      </v-card-subtitle>
      <v-card-text class="text-center overline">
        {{ $t('globalPages.notFound.nothingHere') }}...
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          small
          outlined
          color="#29B6F6"
          @click="back()">
          <v-icon class="mr-2">mdi-arrow-left</v-icon>
          {{ $t('common.buttons.back') }}
        </v-btn>
        <v-btn
          small
          outlined
          color="#29B6F6"
          @click="homepage()">
          <v-icon class="mr-2">mdi-home</v-icon>
          {{ $t('common.buttons.home') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-main>
</template>

<script>
export default {
  name: 'NotFound',
  computed: {
    userMe () {
      return this.$store.state.login.me
    },
  },
  methods: {
    back () {
      history.back()
    },
    homepage () {
      if (this.userMe?.user_type === 'system') {
        this.$router.push({ name: 'HomePage' })
      } else {
        this.$router.push({ name: 'HelpdeskPortalTicketList' })
      }
    },
  },
}
</script>

<style>
  .v-main.notfound-content >
    .v-main__wrap {
      display: flex;
    }
</style>
