<template>
  <div>
    <contact-company-listing-toolbar
      :viewType="viewType"
      :selectedCompanies="selectedCompanies"
      @setListView="setListView"
      @setKanbanView="setKanbanView"
      @deleteCompanies="deleteCompanies"/>
    <transition name="router-anim" mode="out-in">
      <contact-company-kanban
        v-if="viewType === 'kanban'"
        :companies="companies"
        :pagination="pagination"
        :loading="loading"
        :loadingMore="loadingMore"
        @loadNextCompanyPage="loadNextCompanyPage"
        @deleteCompany="deleteCompany"/>
      <contact-company-list
        v-else
        v-model="selectedCompanies"
        :companies="companies"
        :pagination="pagination"
        :loading="loading"
        :loadingMore="loadingMore"
        @loadNextCompanyPage="loadNextCompanyPage"
        @deleteCompany="deleteCompany"/>
    </transition>
  </div>
</template>

<script>
import ContactCompanyListingToolbar from '@/views/contact/company/shared/ContactCompanyListingToolbar.vue'
import ContactCompanyKanban from '@/views/contact/company/ContactCompanyKanban'
import ContactCompanyList from '@/views/contact/company/ContactCompanyList'
import DeleteModal from '@/common/components/DeleteModal'
import ContactCompanyService from '@/services/contact/contact.company.service'
export default {
  name: 'ContactCompanyListing',
  components: {
    ContactCompanyListingToolbar,
    ContactCompanyKanban,
    ContactCompanyList,
  },
  data () {
    return {
      loading: false,
      loadingMore: false,
      viewType: 'kanban',
      companies: [],
      pagination: null,
      selectedCompanies: [],
    }
  },
  mounted () {
    this.getCompanies(this.$store.state.url.urlSearchParams)
  },
  watch: {
    '$store.state.url.urlSearchParams' (newParams, oldParams) {
      this.getCompanies(this.$store.state.url.urlSearchParams)
    },
  },
  methods: {
    setListView () {
      this.viewType = 'list'
    },
    setKanbanView () {
      this.viewType = 'kanban'
    },
    async getCompanies (urlSearchParams) {
      this.loading = true
      const responseData = await ContactCompanyService.getCompanies(urlSearchParams)
      this.pagination = responseData.pagination
      this.companies = responseData.companies
      this.loading = false
    },
    async loadNextCompanyPage () {
      if (this.pagination.has_next_page) {
        this.loadingMore = true
        const nextPageNumber = this.pagination.page_number + 1
        const urlSearchParamsString = this.$store.state.url.urlSearchParams.toString()
        // We do not use the one from the store otherwise the page is stored there..
        const urlSearchParams = new URLSearchParams(urlSearchParamsString)
        urlSearchParams.set('page', nextPageNumber)
        const responseData = await ContactCompanyService.getCompanies(urlSearchParams)
        this.pagination = responseData.pagination
        const newCompanyPage = responseData.companies
        const currentCompanies = this.companies
        const UpdatedCompanyList = currentCompanies.concat(newCompanyPage)
        this.companies = UpdatedCompanyList
        this.loadingMore = false
      }
    },
    deleteCompany (company) {
      this.$dialog.show(DeleteModal, {
        titleText: this.$t('applications.contact.companies.common.deleteCompanyTitle', { name: company.name }),
        warningText: this.$t('applications.contact.companies.common.deleteCompanyWarningText', { name: company.name }),
        recordName: this.$tc('common.labels.companies', 1),
        recordNamePlural: this.$tc('common.labels.companies', 2),
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await ContactCompanyService.deleteCompanies({ ids: [company.id] })
          if (response) {
            this.companies.splice(this.companies.indexOf(company), 1)
            this.pagination.total_records -= 1
            this.selectedCompanies = []
            this.$dialog.notify.success(
              this.$t('applications.contact.companies.messages.companyDeleted', { name: company.name }), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    deleteCompanies (companiesToDelete) {
      this.$dialog.show(DeleteModal, {
        titleText: this.$tc('applications.contact.companies.common.deleteCompaniesTitle', companiesToDelete.length),
        warningText: this.$tc('applications.contact.companies.common.deleteCompaniesWarningText', companiesToDelete.length),
        recordName: this.$tc('common.labels.companies', 1),
        recordNamePlural: this.$tc('common.labels.companies', 2),
        summaryHeaders: [
          { value: 'name', text: this.$t('common.labels.name') },
          { value: 'domain', text: this.$t('common.labels.domain') },
          { value: 'addresses_count', text: `# ${this.$t('common.labels.addresses')}` },
          { value: 'contacts_count', text: `# ${this.$tc('common.labels.contacts', 2)}` },
        ],
        summaryRecords: companiesToDelete,
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const companyIdsToDelete = companiesToDelete.map(company => company.id)
          const response = await ContactCompanyService.deleteCompanies({ ids: companyIdsToDelete })
          if (response) {
            this.companies = this.companies.filter(undeletedCompany => !companyIdsToDelete.includes(undeletedCompany.id))
            this.pagination.total_records -= companiesToDelete.length
            this.selectedCompanies = []
            this.$dialog.notify.success(
              this.$tc('applications.contact.companies.messages.companiesDeleted', companiesToDelete.length, { count: companiesToDelete.length }), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>
  .router-anim-enter,
  .router-anim-leave-to {
    opacity: 0;
    transform: translateY(2em);
  }
  .router-anim-enter-active,
  .router-anim-leave-active {
    transition: all .20s ease;
  }
</style>
