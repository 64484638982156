<template>
  <v-card tile elevation="3">
    <v-toolbar
      v-if="
        user
        && (
          hasPermission('add_user')
          || hasPermission('change_user')
          || (hasPermission('delete_user') && [1, 2, userMe.id].includes(user.id))
        )
      "
      flat
      dense
      class="form-toolbar">
      <v-hover v-slot="{ hover }">
        <v-btn
          text
          :color="userActivationButtonOptions(hover).color"
          :class="{
            'disable-events': !hasPermission('change_user') || [1, 2, userMe.id].includes(user.id)
          }"
          @click="userActivation()">
          {{ userActivationButtonOptions(hover).text }}
        </v-btn>
      </v-hover>
      <v-divider v-if="user" vertical/>
      <v-btn
        text
        v-if="!editing && hasPermission('change_user') && ![1, 2].includes(user.id)"
        @click="edit()">
        <v-icon small left>mdi-pencil</v-icon>
        {{ $t('common.buttons.edit') }}
      </v-btn>
      <v-menu
        v-if="
          !editing
          && (
            hasPermission('change_user_password')
            || (
              hasPermission('delete_user')
              && ![1, 2, userMe.id].includes(user.id)
            )
          )
        "
        close-on-click
        close-on-content-click
        offsetY>
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on">
            {{ $tc('common.labels.actions', 2) }}
            <v-icon small right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-if="hasPermission('change_user_password')"
            @click="updateUserPassword(user)">
            <v-icon small class="mr-4">mdi-key</v-icon>
            {{ $t('common.buttons.changePassword') }}
          </v-list-item>
          <v-list-item
            v-if="hasPermission('delete_user') && ![1, 2, userMe.id].includes(user.id)"
            @click="deleteUser(user)">
            <v-icon small class="mr-4">mdi-delete</v-icon>
            {{ $t('common.buttons.delete') }}
          </v-list-item>
        </v-list>
      </v-menu>
      <template v-if="!editing && hasPermission('add_user')">
        <v-divider vertical/>
        <v-btn
          text
          :to="{ name: 'UserFormCreate' }">
          <v-icon small left>mdi-plus</v-icon>
          {{ $t('common.buttons.create') }}
        </v-btn>
      </template>
      <template
        v-if="
          hasPermission('view_contactcontact')
          && user?.contact_id
          && ![1, 2].includes(user.id)
        ">
        <v-spacer/>
        <v-divider vertical/>
        <v-btn
          text
          :to="{ name: 'ContactContactFormRead', params: { contactId: user.contact_id } }">
          <v-icon small left>mdi-card-account-details</v-icon>
          {{ this.$tc('common.labels.contacts', 1) }}
        </v-btn>
      </template>
    </v-toolbar>
    <v-divider v-if="user"/>
    <v-tabs v-model="selectedFormTab" vertical background-color="grey lighten-4">
      <v-tab>
        <v-tooltip right open-delay="250">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-form-select</v-icon>
          </template>
          {{ $tc('common.labels.users', 1) }}
        </v-tooltip>
      </v-tab>
      <v-tab :disabled="!user || editing">
        <v-tooltip right open-delay="250">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-account-multiple-plus</v-icon>
          </template>
          {{ $tc('common.labels.groups', 2) }}
        </v-tooltip>
      </v-tab>
      <v-tab :disabled="!user || editing">
        <v-tooltip right open-delay="250">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-lock-open-check</v-icon>
          </template>
          {{ $tc('common.labels.permissions', 2) }}
        </v-tooltip>
      </v-tab>
      <v-tab :disabled="!user || editing">
        <v-tooltip right open-delay="250">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-account-clock</v-icon>
          </template>
          {{ $t('common.labels.logs') }}
        </v-tooltip>
      </v-tab>
      <v-tab-item>
        <template v-if="!loadingUser">
          <template v-if="editing">
            <v-alert
              v-if="user"
              type="info"
              dense
              text
              outlined
              icon="mdi-account-sync"
              class="ma-4 mb-0">
              {{ $t('applications.base.users.form.formTab.hints.userContactSync') }}
            </v-alert>
            <v-alert
              v-else
              type="info"
              dense
              text
              outlined
              class="ma-4 mb-0">
              {{ $t('applications.base.users.form.formTab.hints.userCreation') }}
            </v-alert>
          </template>
          <v-row>
            <v-col v-if="user" class="pr-0" cols="1" xl="1" lg="2" md="3" sm="3" xs="12">
              <div class="pa-4">
                <v-hover v-slot="{ hover }">
                  <v-img
                    lazy-src="@/assets/images/no-user-picture.jpeg"
                    :src="newPictureURL || getPictureURL(user.picture) || require('@/assets/images/no-user-picture.jpeg')"
                    alt="No user picture">
                    <v-expand-transition v-if="hasPermission('change_user')">
                      <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out blue darken-1"
                        style="height: 100%; opacity: 0.7;">
                        <div class="mx-auto my-auto">
                          <v-btn fab small @click="chooseUserPicture()">
                            <v-icon>mdi-camera-plus</v-icon>
                          </v-btn>
                          <v-btn fab small @click="uploadUserChosenPicture()" class="ml-2" :disabled="!newPictureURL">
                            <v-icon>mdi-upload</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </v-expand-transition>
                  </v-img>
                </v-hover>
                <v-file-input
                  v-model="newPicture"
                  ref="userPictureInput"
                  class="d-none"
                  accept="image/*"
                  @change="choseUserPicture()"/>
              </div>
            </v-col>
            <v-col
              :cols="user ? 11 : 12"
              :xl="user ? 11 : 12"
              :lg="user ? 10 : 12"
              :md="user ? 9 : 12"
              :sm="user ? 9 : 12"
              :xs="user ? 12 : 12">
              <v-form
                class="pa-4"
                v-model="isFormValid"
                :disabled="!editing">
                <v-switch
                  v-if="!user"
                  v-model="form.is_active"
                  inset
                  dense
                  color="green"
                  :hide-details="!form.username">
                  <template v-slot:label>
                    <span class="caption">{{ isActiveSwitchLabel }}</span>
                  </template>
                </v-switch>
                <v-row class="my-0">
                  <v-col cols=12 sm=6>
                    <v-text-field
                      v-model="form.username"
                      required
                      clearable
                      autofocus
                      type="text"
                      maxlength="150"
                      :label="$t('common.labels.username')"
                      :counter="editing"
                      :rules="[formRules.required]"/>
                  </v-col>
                  <v-col cols=12 sm=6>
                    <v-text-field
                      v-if="!user"
                      v-model="form.password"
                      required
                      clearable
                      maxlength="128"
                      :label="$t('common.labels.password')"
                      :type="showPassword ? 'text' : 'password'"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :counter="editing"
                      :rules="[formRules.required]"
                      @click:append="showPassword = !showPassword"/>
                  </v-col>
                </v-row>
                <v-row class="my-0">
                  <v-col cols=12 sm=6>
                    <v-select
                      v-model="form.language"
                      item-value="value"
                      :items="userSupportedLanguages"
                      :label="$t('common.labels.language')"
                      :menu-props="{ top: false, offsetY: true }">
                      <template v-slot:item="{ item }">
                        {{ item.flag }}<span class="ml-3">{{ item.text }}</span>
                      </template>
                      <template v-slot:selection="data">
                        {{ data.item.flag }}<span class="ml-3">{{ data.item.text }}</span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols=12 sm=6>
                    <v-select
                      v-model="form.user_type"
                      persistent-hint
                      item-value="value"
                      :label="$t('common.labels.userType')"
                      :hint="userTypeFormHint"
                      :items="userTypes"
                      :menu-props="{ top: false, offsetY: true }"
                      :rules="[formRules.required]">
                      <template v-slot:item="{ item }">
                        <v-chip
                          small
                          label
                          :color="item.value === 'system' ? 'green' : 'primary'"
                          class="font-weight-bold white--text">
                          {{ item.text }}
                        </v-chip>
                      </template>
                      <template v-slot:selection="data">
                        <v-chip
                          small
                          label
                          :color="data.item.value === 'system' ? 'green' : 'primary'"
                          class="font-weight-bold white--text">
                          {{ data.item.text }}
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-text-field
                  v-model="form.email"
                  clearable
                  type="email"
                  maxlength="254"
                  :label="$tc('common.labels.emails', 1)"
                  :counter="editing"
                  :rules="[
                    formRules.email,
                    formRules.required
                  ]"/>
                <v-row class="my-0">
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="form.first_name"
                      required
                      clearable
                      type="text"
                      maxlength="150"
                      :label="$t('common.labels.firstName')"
                      :counter="editing"
                      :rules="[formRules.required]"/>
                  </v-col>
                  <v-col cols="12" sm=6>
                    <v-text-field
                      v-model="form.last_name"
                      required
                      clearable
                      type="text"
                      maxlength="150"
                      :label="$t('common.labels.lastName')"
                      :counter="editing"
                      :rules="[formRules.required]"/>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
          <template v-if="editing">
            <v-divider/>
            <v-card-actions>
              <v-btn text @click="cancel()">
                {{ $t('common.buttons.cancel') }}
              </v-btn>
              <v-btn
                text
                color="green"
                :disabled="!isFormValid"
                v-if="hasPermission('change_user') || hasPermission('add_user')"
                @click="!user ? createUser() : updateUser(user)">
                {{ $t('common.buttons.save') }}
              </v-btn>
            </v-card-actions>
          </template>
        </template>
        <template v-else>
          <v-col cols=12 align="center" justify="center">
            <h-request-loading/>
          </v-col>
        </template>
      </v-tab-item>
      <v-tab-item v-if="user" class="pa-4">
        <v-row v-if="![1, 2].includes(user.id)" class="my-0">
          <v-col class="pb-0">
            <v-autocomplete
              v-if="hasPermission('change_user')"
              v-model="selectedGroups"
              multiple
              hide-selected
              clearable
              return-object
              item-text="name"
              :items="groupsUserHasNot"
              :label="$t('applications.base.users.form.groupsTab.selectGroupsToAdd')"
              :loading="loadingGroupsInput"
              @click="getGroupsInput">
              <template v-slot:selection="data">
                <v-chip
                  small
                  close
                  class="font-weight-bold"
                  @click:close="removeGroupFromSelection(data.item)">
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col class="pb-0 d-flex align-center">
            <v-btn
              text
              color="green"
              v-if="selectedGroups.length && hasPermission('change_user')"
              @click="addGroupsToUser(user, selectedGroups)">
              <v-icon small class="mr-1">mdi-plus</v-icon>
              {{ addGroupsButtonText }}
            </v-btn>
          </v-col>
        </v-row>
        <v-card outlined>
          <v-card-title>
            {{ $tc('common.labels.groups', 2) }}
            <v-spacer/>
            <v-text-field
              v-model="userGroupSearch"
              append-icon="mdi-magnify"
              single-line
              hide-details
              :label="$t('applications.base.users.form.groupsTab.searchGroups')"/>
          </v-card-title>
          <v-data-table
            :loading="loadingUserGroups"
            :headers="userGroupsHeaders"
            :items="userGroups"
            :search="userGroupSearch">
            <template v-slot:loading>
              <div class="my-12">{{ $t('loading') }}...</div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip
                v-if="hasPermission('change_user') && ![1, 2].includes(user.id)"
                bottom
                open-delay="250">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" @click="removeGroupFromUser(user, item)" icon>
                    <v-icon color="red">mdi-close-circle</v-icon>
                  </v-btn>
                </template>
                {{ $t('common.buttons.remove') }}
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="user" class="pa-4">
        <v-alert
          type="info"
          dense
          text
          outlined>
          {{ $t('applications.base.users.form.permissionsTab.infoText', { fullName: user?.full_name }) }}
        </v-alert>
        <v-card outlined>
          <v-card-title>{{ $tc('common.labels.permissions', 2) }}</v-card-title>
          <v-treeview
            v-model="permissionsSelection"
            disabled
            open-on-click
            item-children="children"
            item-key="id"
            item-text="name"
            hoverable
            selectable
            :items="userPermissions"/>
        </v-card>
      </v-tab-item>
      <v-tab-item class="pa-4">
        <v-text-field
          v-if="user"
          readonly
          :disabled="!editing"
          :value="momentDateJoined"
          :label="$t('common.labels.creationDate')"/>
        <v-text-field
          v-if="user"
          readonly
          :disabled="!editing"
          :value="momentLastLogin"
          :label="$t('common.labels.lastLogin')"/>
        <v-text-field
          v-if="user"
          readonly
          :disabled="!editing"
          :value="momentLastActivationDate"
          :label="$t('common.labels.lastActivation')"/>
        <v-text-field
          v-if="user"
          :disabled="!editing"
          readonly
          :value="momentLastDeactivationDate"
          :label="$t('common.labels.lastDeactivation')"/>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import moment from 'moment'
import HRequestLoading from '@/common/components/HRequestLoading'
import UserChangePassword from './UserChangePassword'
import DeleteModal from '@/common/components/DeleteModal'
import WarningModal from '@/common/components/WarningModal'
import BaseGroupService from '@/services/base/base.group.service'
import BaseUserService from '@/services/base/base.user.service'
export default {
  name: 'UserForm',
  components: {
    HRequestLoading,
  },
  props: {
    editing: { type: Boolean },
    userId: {
      type: [String, Number],
      default: () => { return null },
    }
  },
  data () {
    return {
      selectedFormTab: 0,
      userGroupSearch: '',
      loadingUserGroups: false,
      loadingGroupsInput: false,
      loadingUserPermissions: false,
      loadingUser: false,
      user: null,
      userGroups: [],
      userPermissions: [],
      permissionsSelection: [],
      groups: [],
      newPicture: null,
      newPictureURL: null,
      userSupportedLanguages: [
        { flag: '🇬🇧', text: 'English', value: 'en' },
        { flag: '🇫🇷', text: 'Français', value: 'fr' },
      ],
      userTypes: [
        { text: 'System', value: 'system' },
        { text: 'Portal', value: 'portal' },
        { text: 'Portal Referent', value: 'portal_referent'},
      ],
      form: {
        username: '',
        password: '',
        language: 'en',
        user_type: null,
        email: '',
        first_name: '',
        last_name: '',
        is_active: true,
      },
      isFormValid: false,
      formRules: {
        required: value => !!value || this.$t('common.errors.required'),
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return !value || pattern.test(value) || this.$t('common.errors.invalidEmail')
        },
      },
      selectedGroups: [],
      showPassword: false,
    }
  },
  async mounted () {
    if (this.userId) {
      await this.getUser(this.userId)
      if (this.user) {
        this.initFormFields()
        this.getUserGroups(this.userId)
        this.getUserPermissions(this.userId)
      }
    }
  },
  computed: {
    userMe () {
      return this.$store.state.login.me
    },
    userTypeFormHint () {
      if (this.form.user_type === 'system') {
        return this.$t('applications.base.users.form.formTab.hints.userTypeSystem')
      } else if (this.form.user_type === 'portal') {
        return this.$t('applications.base.users.form.formTab.hints.userTypePortal')
      } else if (this.form.user_type === 'portal_referent') {
        return this.$t('applications.base.users.form.formTab.hints.userTypePortalRef')
      } else {
        return ''
      }
    },
    userGroupsHeaders () {
      return [
        { value: 'name', text: this.$t('common.labels.name') },
        { value: 'users_count', text: `# ${this.$tc('common.labels.users', 2)}` },
        { value: 'permissions_count', text: `# ${this.$tc('common.labels.permissions', 2)}` },
        { value: 'actions', text: this.$tc('common.labels.actions', 2), sortable: false },
      ]
    },
    addGroupsButtonText () {
      return this.$tc('applications.base.users.form.groupsTab.addGroupButton', this.selectedGroups.length)
    },
    isActiveSwitchLabel () {
      if (this.form.is_active) return this.$t('applications.base.users.form.formTab.hints.userCreationActive')
      return this.$t('applications.base.users.form.formTab.hints.userCreationInactive')
    },
    momentDateJoined ()  {
      return moment(this.user.date_joined).calendar()
    },
    momentLastLogin () {
      if (this.user.last_login) return moment(this.user.last_login).calendar()
      return this.$t('applications.base.users.form.historyTab.neverLoggedIn')
    },
    momentLastActivationDate () {
      if (this.user.last_activation_date) return moment(this.user.last_activation_date).calendar()
      return this.$t('applications.base.users.form.historyTab.neverActivated')
    },
    momentLastDeactivationDate () {
      if (this.user.last_deactivation_date) return moment(this.user.last_deactivation_date).calendar()
      return this.$t('applications.base.users.form.historyTab.neverDeactivated')
    },
    groupsUserHasNot () {
      const userGroupsIds = this.userGroups.map(group => group.id)
      return this.groups.filter(group => !userGroupsIds.includes(group.id))
    },
  },
  methods: {
    async getUser (userId) {
      this.loadingUser = true
      this.user = await BaseUserService.getUser(userId)
      this.loadingUser = false
    },
    async getUserGroups (userId) {
      this.loadingUserGroups = true
      this.userGroups = await BaseUserService.getUserGroups(userId)
      this.loadingUserGroups = false
    },
    async getGroupsInput () {
      this.loadingGroupsInput = true
      this.groups = await BaseGroupService.getGroupsInput()
      this.loadingGroupsInput = false
    },
    async getUserPermissions (userId) {
      this.loadingUserPermissions = true
      this.userPermissions = await BaseUserService.getUserPermissions(userId)
      this.loadingUserPermissions = false
      this.preparePermissionsData(this.userPermissions)
    },
    preparePermissionsData (userPermissionsByModelsByApplication) {
      this.permissionsSelection = []
      for (let application of userPermissionsByModelsByApplication) {
        application.id = `a-${application.id}`
        application['children'] = application['models']
        delete application['models']
        for (let model of application.children) {
          model.id = `m-${model.id}`
          model['children'] = model['permissions']
          delete model['permissions']
          for (const permission of model.children) {
            if (permission.has_permission) {
              this.permissionsSelection.push(permission.id)
            }
          }
        }
      }
    },
    initFormFields () {
      for (let field in this.form) {
        this.form[field] = this.user[field]
      }
    },
    edit () {
      this.selectedFormTab = 0
      this.$emit('toggleEdit', true)
    },
    cancelEdit () {
      this.$emit('toggleEdit', false)
    },
    cancel () {
      this.cancelEdit()
      if (this.user) {
        for (let field in this.form) {
          if (field === 'password') {
            continue
          } else {
            this.form[field] = this.user[field]
          }
        }
      } else {
        history.back()
      }
    },
    chooseUserPicture () {
      this.$refs.userPictureInput.$refs.input.click()
    },
    choseUserPicture () {
      this.newPictureURL = URL.createObjectURL(this.newPicture)
    },
    userActivationButtonOptions (hover) {
      if (this.user.is_active && hover) {
        return {
          text: this.$t('common.buttons.deactivate'),
          color: 'red',
        }
      }
      if (this.user.is_active && !hover) {
        return {
          text: this.$t('common.labels.activated'),
          color: 'green',
        }
      }
      if (!this.user.is_active && hover) {
        return {
          text: this.$t('common.buttons.activate'),
          color: 'green',
        }
      }
      if (!this.user.is_active && !hover) {
        return {
          text: this.$t('common.labels.deactivated'),
          color: 'red',
        }
      }
    },
    async uploadUserChosenPicture () {
      const formData = new FormData()
      formData.append('picture', this.newPicture)
      const uploadedPictureURL = await BaseUserService.uploadUserPicture(this.userId, formData)
      if (uploadedPictureURL) {
        this.$dialog.notify.success(
          this.$t('applications.base.users.messages.userAvatarUpdated', { fullName: this.user?.full_name }), {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
        this.user.picture = uploadedPictureURL.picture_url
        await this.$store.dispatch('FETCH_ME')
        URL.revokeObjectURL(this.newPicture)
        this.newPicture = null
        this.newPictureURL = null
      }
    },
    async createUser () {
      const {
        username,
        password,
        language,
        user_type,
        email,
        first_name,
        last_name,
        is_active,
      } = this.$data.form
      const createdUser = await BaseUserService.createUser({
        username: username,
        password: password,
        language: language,
        user_type: user_type,
        email: email,
        first_name: first_name,
        last_name: last_name,
        is_active: is_active,
      })
      if (createdUser) {
        this.cancelEdit()
        this.$dialog.notify.success(
          this.$t('applications.base.users.messages.userCreated', { username: createdUser.username }), {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
        this.$router.push({
          name: 'UserFormRead',
          params: { userId: createdUser.id, },
        })
      }
    },
    updateUserPassword (user) {
      this.$dialog.show(UserChangePassword, {
        user: user,
        persistent: true,
        waitForResult: true,
      }).then(async result => {
        if (result) {
          const updatedUser = await BaseUserService.updateUserPassword(user.id, { password: result.password })
          if (updatedUser) {
            this.$dialog.notify.success(
              this.$t('applications.base.users.messages.userPasswordUpdated', { fullName: updatedUser.full_name }), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    userActivation () {
      this.user.is_active ? this.deactivateUser(this.user) : this.activateUser(this.user)
    },
    async activateUser (user) {
      const activatedUser = await BaseUserService.activateUser(user.id)
      if (activatedUser) {
        this.user = activatedUser
        this.$dialog.notify.success(
          this.$t('applications.base.users.messages.userActivated', { username: activatedUser.username }), {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    async deactivateUser (user) {
      this.$dialog.show(WarningModal, {
        icon: 'archive-arrow-down',
        title: this.$t('applications.base.users.common.deactivateUserTitle', { fullName: user.full_name, username: user.username }),
        message: this.$t('applications.base.users.common.deactivateUserText', { fullName: user.full_name, username: user.username }),
        okText: this.$t('common.buttons.yes'),
        okColor: 'green',
        noButton: true,
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const deactivatedUser = await BaseUserService.deactivateUser(user.id)
          if (deactivatedUser) {
            this.user = deactivatedUser
            this.$dialog.notify.success(
              this.$t('applications.base.users.messages.userDeactivated', { username: deactivatedUser.username }), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    async updateUser (user) {
      const { username, language, user_type, email, first_name, last_name } = this.$data.form
      const updatedUser = await BaseUserService.updateUser(user.id, {
        username: username,
        language: language,
        user_type: user_type,
        email: email,
        first_name: first_name,
        last_name: last_name,
      })
      if (updatedUser) {
        this.cancelEdit()
        this.user = updatedUser
        if (this.user.id === this.userMe.id) {
          this.updateMeInStore(updatedUser)
        }
        this.$dialog.notify.success(
          this.$t('applications.base.users.messages.userUpdated', { username: user.username }), {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    updateMeInStore (updatedUser) {
      let updatedMe = this.userMe
      updatedMe.full_name = updatedUser.full_name
      updatedMe.email = updatedUser.email
      updatedMe.language = updatedUser.language
      this.$store.commit('SET_ME', updatedMe)
      this.$root.$i18n.locale = this.$store.state.login.me.language
    },
    deleteUser (user) {
      this.$dialog.show(DeleteModal, {
        titleText: this.$t('applications.base.users.common.deleteUserTitle', { fullName: user.full_name, username: user.username }),
        warningText: this.$t('applications.base.users.common.deleteUserWarningText', { fullName: user.full_name, username: user.username }),
        alertMessage: this.$tc('applications.base.users.common.deleteUserAlertText', 1),
        checkRequired: true,
        checkMessage: this.$tc('applications.base.users.common.deleteUserCheckText', 1),
        recordName: this.$tc('common.labels.users', 1),
        recordNamePlural: this.$tc('common.labels.users', 2),
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await BaseUserService.deleteUsers({ ids: [user.id] })
          if (response) {
            this.$dialog.notify.success(
              this.$t('applications.base.users.messages.userDeleted', { username: user.username, fullName: user.full_name }), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
            this.$router.push({ name: 'UserListing' })
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    async addGroupsToUser (user, selectedGroups) {
      const groupIds = selectedGroups.map(group => group.id)
      const userGroups = await BaseUserService.addGroupsToUser(user.id, {
        group_ids: groupIds
      })
      if (userGroups) {
        this.userGroups = userGroups
        this.selectedGroups = []
        this.getUserPermissions(this.userId)
        this.$dialog.notify.success(
          this.$tc('applications.base.users.messages.groupsAddedToUser', groupIds.length, { count: groupIds.length, username: user.username }), {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    async removeGroupFromUser (user, group) {
      const userGroups = await BaseUserService.removeGroupFromUser(user.id, { group_id: group.id })
      if (userGroups) {
        this.userGroups = userGroups
        this.getUserPermissions(this.userId)
        this.$dialog.notify.success(
          this.$t('applications.base.users.messages.groupRemovedFromUser', { groupName: group.name, username: user.username }), {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    removeGroupFromSelection (group) {
      this.selectedGroups = this.selectedGroups.filter(item => item.id !== group.id)
    },
  },
}
</script>

<style scoped>
  .disable-events {
    pointer-events: none;
  }
  div.v-toolbar__content .v-btn {
    border-radius: 0;
    height: 100%!important;
    max-height: none;
  }
</style>
