<template>
  <div>
    <v-subheader class="mb-2">
      <h2>{{ $t('common.labels.emailConfigClassicInbound') }}</h2>
    </v-subheader>
    <v-toolbar
      v-if="hasPermission('change_emailsettings')"
      flat
      height=36
      color="grey lighten-4"
      class="mt-2">
      <v-toolbar-items>
        <v-btn
          text
          color="primary"
          @click="createInbound()">
          <v-icon small left>mdi-plus</v-icon>
          {{ $t('common.buttons.add')}}
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-divider/>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="inbounds"
      :hide-default-footer="inbounds.length < 10">
      <template v-slot:loading>
        <div class="my-12">{{ $t('loading') }}...</div>
      </template>
      <template v-slot:[`item.server_type`]="{ item }">
        <v-chip small color="primary" class="font-weight-bold">{{ item.server_type.toUpperCase() }}</v-chip>
      </template>
      <template v-slot:[`item.use_ssl`]="{ item }">
        <v-icon v-if="item.use_ssl" color="green">mdi-check-bold</v-icon>
        <span v-else>-</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip v-if="hasPermission('change_emailsettings')" bottom open-delay="250">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="editInbound(item)" icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          {{ $t('common.buttons.edit') }}
        </v-tooltip>
        <v-tooltip v-if="hasPermission('change_emailsettings')" bottom open-delay="250">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="deleteInbound(item)" icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          {{ $t('common.buttons.delete') }}
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import BaseSettingsService from '@/services/base/base.settings.service'
import DeleteModal from '@/common/components/DeleteModal'
import EmailSettingsClassicInboundFormDialog from '@/views/base/settings/settings/emails/EmailSettingsClassicInboundFormDialog'
export default {
  name: 'EmailSettingsClassicInboundList',
  data () {
    return {
      loading: false,
      inbounds: [],
    }
  },
  computed: {
    headers () {
      return [
        { value: 'name', text: this.$t('common.labels.name') },
        { value: 'host', text: this.$tc('common.labels.hosts', 1) },
        { value: 'port', text: this.$tc('common.labels.ports', 1) },
        { value: 'server_type', text: this.$t('common.labels.serverType') },
        { value: 'username', text: this.$t('common.labels.username') },
        { value: 'use_ssl', text: this.$t('common.labels.useSSL') },
        { value: 'actions', text: this.$tc('common.labels.actions', 2), sortable: false },
      ]
    },
  },
  async mounted () {
    await this.getClassicInboundEmailSettings()
  },
  methods: {
    async getClassicInboundEmailSettings () {
      this.loading = true
      this.inbounds = await BaseSettingsService.getClassicInboundEmailSettingss()
      this.loading = false
    },
    createInbound () {
      this.$dialog.show(EmailSettingsClassicInboundFormDialog, {
        persistent: true,
        waitForResult: true
      }).then(async result => {
        if (result) {
          const createdInbound = await BaseSettingsService.createClassicInboundEmailSettings({
            name: result.name,
            host: result.host,
            port: result.port,
            server_type: result.server_type,
            use_ssl: result.use_ssl,
            username: result.username,
            password: result.password,
          })
          if (createdInbound) {
            this.inbounds.push(createdInbound)
            this.$dialog.notify.success(
              this.$t('applications.base.settings.emails.classic.messages.inboundCreated'), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    editInbound (inbound) {
      this.$dialog.show(EmailSettingsClassicInboundFormDialog, {
        inboundMailServer: inbound,
        persistent: true,
        waitForResult: true,
      }).then(async result => {
        if (result) {
          const updatedInbound = await BaseSettingsService.updateClassicInboundEmailSettings(inbound.id, {
            name: result.name,
            host: result.host,
            port: result.port,
            server_type: result.server_type,
            use_ssl: result.use_ssl,
            username: result.username,
            password: result.password,
          })
          if (updatedInbound) {
            this.inbounds.splice(this.inbounds.indexOf(inbound), 1, updatedInbound)
            this.$dialog.notify.success(
              this.$t('applications.base.settings.emails.classic.messages.inboundUpdated'), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    deleteInbound (inbound) {
      this.$dialog.show(DeleteModal, {
        titleText: this.$t('applications.base.settings.emails.classic.inbound.deleteInboundMailboxTitle', { name: inbound.name }),
        warningText: this.$t('applications.base.settings.emails.classic.inbound.deleteInboundMailboxWarningText'),
        recordName: this.$t('common.labels.mailboxes', 1),
        recordNamePlural: this.$t('common.labels.mailboxes', 2),
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await BaseSettingsService.deleteClassicInboundEmailSettings({ ids: [inbound.id] })
          if (response) {
            this.inbounds.splice(this.inbounds.indexOf(inbound), 1)
            this.$dialog.notify.success(
              this.$t('applications.base.settings.emails.classic.messages.inboundDeleted'), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
  },
}
</script>
