<template>
  <v-row class="my-0">
    <v-col>
      <v-card tile elevation="3">
        <v-data-table
          v-model="selectedGroups"
          multi-sort
          checkbox-color="blue darken-2"
          :loading="loading"
          :show-select="hasPermission('delete_group')"
          :headers="headers"
          :items="groups"
          :hide-default-footer="groups.length < 10">
          <!-- TODO: Find a way not to hardcode the 10 in hide-default-footer ? -->
          <template v-slot:loading>
            <div class="my-12">{{ $t('loading') }}...</div>
          </template>
          <template v-slot:no-data>
            <h-no-records-found createFormViewName="GroupFormCreate"/>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip v-if="hasPermission('view_group')" bottom open-delay="250">
              <template v-slot:activator="{ on }">
                <v-btn :to="{ name: 'GroupFormRead', params: { groupId: item.id } }" v-on="on" icon>
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
              {{ $t('common.buttons.open') }}
            </v-tooltip>
            <v-tooltip v-if="hasPermission('delete_group') && item.id !== 1" bottom open-delay="250">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" @click="$emit('deleteGroup', item)" icon>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              {{ $t('common.buttons.delete') }}
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import HNoRecordsFound from '@/common/components/HNoRecordsFound'
export default {
  name: 'GroupList',
  components: { HNoRecordsFound },
  props: {
    value: {
      type: Array,
      default: () => { return [] },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    groups: {
      type: Array,
      default: () => { return [] },
    },
  },
  computed: {
    headers () {
      return [
        { value: 'name', text: this.$t('common.labels.name') },
        { value: 'users_count', text: `# ${this.$tc('common.labels.users', 2)}` },
        { value: 'permissions_count', text: `# ${this.$tc('common.labels.permissions', 2)}` },
        { value: 'actions', text: this.$tc('common.labels.actions', 2), sortable: false },
      ]
    },
    selectedGroups: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
  },
  beforeDestroy () {
    this.selectedGroups = []
  },
  watch: {
    '$store.state.url.urlSearchParams' (newParams, oldParams) {
      this.selectedGroups = []
    },
  },
}
</script>
