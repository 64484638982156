<template>
  <div v-if="!loading">
    <v-toolbar v-if="hasPermission('change_globalschedule')" flat dense>
      <v-toolbar-items>
        <v-btn
          v-if="!formIsEditing && hasPermission('change_globalschedule')"
          text
          @click="edit()">
          <v-icon small left>mdi-pencil</v-icon>
          {{ $t('common.buttons.edit') }}
        </v-btn>
        <v-btn
          v-if="formIsEditing"
          text
          @click="cancel()">
          {{ $t('common.buttons.cancel') }}
        </v-btn>
        <v-divider vertical v-if="formIsEditing"/>
        <v-btn
          v-if="formIsEditing && hasPermission('change_globalschedule')"
          text
          color="green"
          @click="updateGlobalSchedule()">
          {{ $t('common.buttons.save') }}
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-divider/>
    <v-form
      class="pa-4"
      v-model="isFormValid"
      :disabled="!formIsEditing">
      <v-switch
        v-model="form.h24"
        inset
        color="primary"
        class="mb-4"
        persistent-hint
        :hint="$t('applications.base.settings.schedule.form.hints.useH24')"
        :label="$t('common.labels.workH24')"/>
      <v-autocomplete
        v-model="form.country"
        outlined
        clearable
        item-text="name"
        item-value="code"
        persistent-hint
        :hint="$t('applications.base.settings.schedule.form.hints.useCountry')"
        :label="$t('common.labels.country')"
        :disabled="!formIsEditing || form.h24"
        :loading="loadingHolidaysCountries"
        :items="holidaysCountries"
        @click="getHolidaysCountries()"/>
      <v-alert
        v-if="formIsEditing && !form.h24"
        dense
        text
        outlined
        type="info"
        class="mt-2">
        {{ $t('applications.base.settings.schedule.form.hints.dayNotWorked') }}
      </v-alert>
      <v-row>
        <v-col cols=12 sm=6>
          <h-time-picker
            v-model="form.start_0"
            :label="$t('common.labels.day0Start')"
            :disabled="!formIsEditing || form.h24"/>
        </v-col>
        <v-col cols=12 sm=6>
          <h-time-picker
            v-model="form.end_0"
            :label="$t('common.labels.day0End')"
            :disabled="!formIsEditing || form.h24"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12 sm=6>
          <h-time-picker
            v-model="form.start_1"
            :label="$t('common.labels.day1Start')"
            :disabled="!formIsEditing || form.h24"/>
        </v-col>
        <v-col cols=12 sm=6>
          <h-time-picker
            v-model="form.end_1"
            :label="$t('common.labels.day1End')"
            :disabled="!formIsEditing || form.h24"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12 sm=6>
          <h-time-picker
            v-model="form.start_2"
            :label="$t('common.labels.day2Start')"
            :disabled="!formIsEditing || form.h24"/>
        </v-col>
        <v-col cols=12 sm=6>
          <h-time-picker
            v-model="form.end_2"
            :label="$t('common.labels.day2End')"
            :disabled="!formIsEditing || form.h24"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12 sm=6>
          <h-time-picker
            v-model="form.start_3"
            :label="$t('common.labels.day3Start')"
            :disabled="!formIsEditing || form.h24"/>
        </v-col>
        <v-col cols=12 sm=6>
          <h-time-picker
            v-model="form.end_3"
            :label="$t('common.labels.day3End')"
            :disabled="!formIsEditing || form.h24"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12 sm=6>
          <h-time-picker
            v-model="form.start_4"
            :label="$t('common.labels.day4Start')"
            :disabled="!formIsEditing || form.h24"/>
        </v-col>
        <v-col cols=12 sm=6>
          <h-time-picker
            v-model="form.end_4"
            :label="$t('common.labels.day4End')"
            :disabled="!formIsEditing || form.h24"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12 sm=6>
          <h-time-picker
            v-model="form.start_5"
            :label="$t('common.labels.day5Start')"
            :disabled="!formIsEditing || form.h24"/>
        </v-col>
        <v-col cols=12 sm=6>
          <h-time-picker
            v-model="form.end_5"
            :label="$t('common.labels.day5End')"
            :disabled="!formIsEditing || form.h24"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12 sm=6>
          <h-time-picker
            v-model="form.start_6"
            :label="$t('common.labels.day6Start')"
            :disabled="!formIsEditing || form.h24"/>
        </v-col>
        <v-col cols=12 sm=6>
          <h-time-picker
            v-model="form.end_6"
            :label="$t('common.labels.day6End')"
            :disabled="!formIsEditing || form.h24"/>
        </v-col>
      </v-row>
    </v-form>
  </div>
  <div v-else>
    <v-col cols=12 align="center" justify="center">
      <h-request-loading/>
    </v-col>
  </div>
</template>

<script>
import HTimePicker from '@/common/components/forms/HTimePicker'
import HRequestLoading from '@/common/components/HRequestLoading'
import DialogUnsavedChanges from '@/common/mixins/DialogUnsavedChanges'
import BaseSettingsService from '@/services/base/base.settings.service'
export default {
  name: 'GlobalScheduleForm',
  mixins: [ DialogUnsavedChanges ],
  components: {
    HRequestLoading,
    HTimePicker,
  },
  data () {
    return {
      loading: false,
      loadingHolidaysCountries: false,
      holidaysCountries: [],
      globalSchedule: null,
      formIsEditing: false,
      isFormValid: false,
      form: {
        h24: false,
        country: null,
        start_0: '00:00',
        end_0: '00:00',
        start_1: '00:00',
        end_1: '00:00',
        start_2: '00:00',
        end_2: '00:00',
        start_3: '00:00',
        end_3: '00:00',
        start_4: '00:00',
        end_4: '00:00',
        start_5: '00:00',
        end_5: '00:00',
        start_6: '00:00',
        end_6: '00:00',
      }
    }
  },
  async mounted () {
    await this.getGlobalSchedule()
    if (this.globalSchedule) {
      this.initFormFields()
    }
  },
  methods: {
    async getHolidaysCountries () {
      this.loadingHolidaysCountries = true
      this.holidaysCountries = await BaseSettingsService.getHolidaysCountries()
      this.loadingHolidaysCountries = false
    },
    async getGlobalSchedule () {
      this.loading = true
      this.globalSchedule = await BaseSettingsService.getGlobalSchedule()
      this.loading = false
    },
    initFormFields () {
      for (const field in this.form) {
        if (field === 'country') {
          this.holidaysCountries = [this.globalSchedule[field]]
          this.form[field] = this.globalSchedule[field]?.code
          continue
        }
        this.form[field] = this.globalSchedule[field]
      }
    },
    edit () {
      this.formIsEditing = true
    },
    cancelEdit () {
      this.formIsEditing = false
    },
    cancel () {
      if (this.globalSchedule) {
        this.initFormFields()
      }
      this.cancelEdit()
    },
    async updateGlobalSchedule () {
      const {
        h24,
        country,
        start_0,
        start_1,
        start_2,
        start_3,
        start_4,
        start_5,
        start_6,
        end_0,
        end_1,
        end_2,
        end_3,
        end_4,
        end_5,
        end_6,
      } = this.$data.form
      const updatedSchedule = await BaseSettingsService.updateGlobalSchedule({
        h24: h24,
        country: country,
        start_0: start_0,
        start_1: start_1,
        start_2: start_2,
        start_3: start_3,
        start_4: start_4,
        start_5: start_5,
        start_6: start_6,
        end_0: end_0,
        end_1: end_1,
        end_2: end_2,
        end_3: end_3,
        end_4: end_4,
        end_5: end_5,
        end_6: end_6,
      })
      if (updatedSchedule) {
        this.cancelEdit()
        this.globalSchedule = updatedSchedule
        // Exceptionally, replace form fields with HTTP response.
        // It is necessary for first time to get default 00:00:00
        // from the backend.
        for (const field in this.form) {
          this.form[field] = updatedSchedule[field]
        }
        this.$dialog.notify.success(
          this.$t('applications.base.settings.schedule.messages.scheduleUpdated'), {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000
          }
        )
      }
    }
  }
}
</script>
