<template>
  <v-row class="my-0">
    <v-col>
      <v-card tile elevation="3">
        <v-row no-gutters class="pa-4">
          <v-col class="d-flex justify-end">
            <span class="caption my-auto">
              {{ $t('showing') }} <span style="color:#1E88E5">{{ contacts.length }}</span> {{ $t('of') }} {{ pagination?.total_records }}
            </span>
            <v-divider
              v-if="pagination?.has_next_page"
              class="mx-4"
              vertical/>
            <v-btn
              v-if="pagination?.has_next_page"
              color="blue darken-1"
              outlined
              :loading="loadingMore"
              @click="$emit('loadNextContactPage')">
              <v-icon left>mdi-download</v-icon>
              {{ $t('common.buttons.loadMore') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-divider/>
        <v-data-table
          multi-sort
          :loading="loading"
          :headers="headers"
          :items="contacts"
          :hide-default-footer="contacts.length < 10">
          <!-- TODO: Find a way not to hardcode the 10 in hide-default-footer ? -->
          <template v-slot:loading>
            <div class="my-12">{{ $t('loading') }}...</div>
          </template>
          <template v-slot:no-data>
            <h-no-records-found createFormViewName="ContactContactFormCreate"/>
          </template>
          <template v-slot:[`item.tags`]="{ item }">
            <v-chip-group column>
              <v-chip
                v-for="tag in item.tags"
                :key="tag.id"
                :color="tag.color"
                x-small
                class="font-weight-bold"
                :class="{ 'white--text': shouldTextBeWhite(tag.color) }">
                {{ tag.name }}
              </v-chip>
            </v-chip-group>
          </template>
          <template v-slot:[`item.user`]="{ item }">
            <v-chip
              v-if="item.user"
              small
              :color="item.user.user_type === 'system' ? 'green' : 'primary'"
              class="font-weight-bold white--text">
              {{ item.user.username }} ({{ item.user.user_type_display }})
            </v-chip>
            <span v-else>-</span>
          </template>
          <template v-slot:[`item.is_archived`]="{ item }">
            <v-chip
              small
              outlined
              label
              :color="item.is_archived ? 'red' : 'green'"
              class="font-weight-bold">
              {{ item.is_archived ? $t('common.labels.archived') : $t('common.labels.unarchived') }}
            </v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip v-if="hasPermission('view_contactcontact')" bottom open-delay="250">
              <template v-slot:activator="{ on }">
                <v-btn :to="{ name: 'ContactContactFormRead', params: { contactId: item.id } }" v-on="on" icon>
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
              {{ $t('common.buttons.open') }}
            </v-tooltip>
            <v-tooltip v-if="hasPermission('add_user')" bottom open-delay="250">
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  :disabled="!!item.user || item.is_archived"
                  @click="$emit('createUserForContact', item)">
                  <v-icon>mdi-account-plus</v-icon>
                </v-btn>
              </template>
              {{ $t('common.buttons.createUser') }}
            </v-tooltip>
            <v-tooltip v-if="hasPermission('change_contactcontact')" bottom open-delay="250">
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  :disabled="!!item.user"
                  @click="contactArchiving(item)">
                  <v-icon v-if="item.is_archived">mdi-archive-arrow-up</v-icon>
                  <v-icon v-else>mdi-archive-arrow-down</v-icon>
                </v-btn>
              </template>
              {{ item.is_archived ? $t('common.buttons.unarchive') : $t('common.buttons.archive') }}
            </v-tooltip>
            <v-tooltip
              v-if="hasPermission('delete_contactcontact')" bottom open-delay="250">
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  :disabled="!!item.user"
                  @click="$emit('deleteContact', item)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              {{ $t('common.buttons.delete') }}
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import HNoRecordsFound from '@/common/components/HNoRecordsFound'
export default {
  name: 'ContactContactList',
  components: { HNoRecordsFound },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loadingMore: {
      type: Boolean,
      default: false,
    },
    contacts: {
      type: Array,
      default: () => { return [] },
    },
    pagination: {
      type: Object,
      default: null,
    },
  },
  computed: {
    headers () {
      return [
        { value: 'full_name', text: this.$t('common.labels.name') },
        { value: 'company.name', text: this.$tc('common.labels.companies', 1) },
        { value: 'phone_pro', text: this.$t('common.labels.phonePro') },
        { value: 'phone_pro_mobile', text: this.$t('common.labels.phoneProMobile') },
        { value: 'email', text: this.$tc('common.labels.emails', 1) },
        { value: 'tags', text: this.$tc('common.labels.tags', 2) },
        { value: 'user', text: this.$tc('common.labels.users', 1) },
        { value: 'is_archived', text: this.$t('common.labels.status') },
        { value: 'actions', text: this.$tc('common.labels.actions', 2), sortable: false },
      ]
    },
  },
  methods: {
    contactArchiving (contact) {
      contact.is_archived
      ? this.$emit('unarchiveContact', contact)
      : this.$emit('archiveContact', contact)
    },
  },
}
</script>
