<template>
  <v-row class="my-0">
    <v-col>
      <v-card tile elevationn="3">
        <v-row no-gutters class="pa-4">
          <v-col class="d-flex justify-end">
            <span class="caption my-auto">
              {{ $t('showing') }} <span style="color:#1E88E5">{{ companies.length }}</span> {{ $t('of') }} {{ pagination?.total_records }}
            </span>
            <v-divider
              v-if="pagination?.has_next_page"
              class="mx-4"
              vertical/>
            <v-btn
              v-if="pagination?.has_next_page"
              color="blue darken-1"
              outlined
              :loading="loadingMore"
              @click="$emit('loadNextCompanyPage')">
              <v-icon left>mdi-download</v-icon>
              {{ $t('common.buttons.loadMore') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-divider/>
        <v-data-table
          v-model="selectedCompanies"
          multi-sort
          checkbox-color="blue darken-2"
          :loading="loading"
          :show-select="hasPermission('delete_contactcompany')"
          :headers="tableHeaders"
          :items="companies"
          :hide-default-footer="companies.length < 10">
          <!-- TODO: Find a way not to hardcode the 10 in hide-default-footer ? -->
          <template v-slot:loading>
            <div class="my-12">{{ $t('loading') }}...</div>
          </template>
          <template v-slot:no-data>
            <h-no-records-found createFormViewName="ContactCompanyFormCreate"/>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip v-if="hasPermission('view_contactcompany')" bottom open-delay="250">
              <template v-slot:activator="{ on }">
                <v-btn :to="{ name: 'ContactCompanyFormRead', params: { companyId: item.id } }" v-on="on" icon>
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
              {{ $t('common.buttons.open') }}
            </v-tooltip>
            <v-tooltip v-if="hasPermission('delete_contactcompany')" bottom open-delay="250">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" @click="$emit('deleteCompany', item)" icon>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              {{ $t('common.buttons.delete') }}
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import HNoRecordsFound from '@/common/components/HNoRecordsFound'
export default {
  name: 'ContactCompanyList',
  components: { HNoRecordsFound },
  props: {
    value: {
      type: Array,
      default: () => { return [] },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingMore: {
      type: Boolean,
      default: false,
    },
    companies: {
      type: Array,
      default: () => { return [] },
    },
    pagination: {
      type: Object,
      default: null,
    },
  },
  computed: {
    selectedCompanies: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
    tableHeaders () {
      return [
        { value: 'name', text: this.$t('common.labels.name') },
        { value: 'domain', text: this.$t('common.labels.domains') },
        { value: 'phone', text: this.$t('common.labels.phone') },
        { value: 'email', text: this.$tc('common.labels.emails', 1) },
        { value: 'addresses_count', text: `# ${this.$t('common.labels.addresses')}` },
        { value: 'contacts_count', text: `# ${this.$tc('common.labels.contacts', 2)}` },
        { value: 'actions', text: this.$tc('common.labels.actions', 2), sortable: false },
      ]
    },
  },
  beforeDestroy () {
    this.selectedCompanies = []
  },
  watch: {
    '$store.state.url.urlSearchParams' (newParams, oldParams) {
      this.selectedCompanies = []
    },
  },
}
</script>
