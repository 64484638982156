<template>
  <v-row class="my-0">
    <template v-if="groups.length && !loading">
      <v-col
        sm=6 md=4 lg=2
        v-for="group in groups"
        :key="group.id">
        <v-card
          tile
          elevation="3"
          class="group-card"
          :ripple="false"
          @click="onGroupClick(group)">
          <v-card-title>
            {{ group.name || truncate(20, '...') }}
            <v-spacer/>
            <v-menu
              v-if="hasPermission('delete_group') && group.id !== 1"
              close-on-click
              close-on-content-click
              offsetY
              left>
              <!-- TODO: Add close-delay when it work -->
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" tile icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list class="h-menu-list">
                <v-list-item
                  v-if="hasPermission('delete_group')"
                  @click="$emit('deleteGroup', group)">
                  <v-icon small class="mr-4">mdi-delete</v-icon>
                  {{ $t('common.buttons.delete') }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-card-subtitle class="pt-1">
            <v-chip
              x-small
              outlined
              class="font-weight-bold"
              :color="group.users_count > 0 ? 'green' : 'grey lighten-2'">
              <v-icon left x-small>
                mdi-account-group
              </v-icon>
              {{ group.users_count }} {{ $tc('common.labels.users', group.users_count) }}
            </v-chip>
            <v-chip
              x-small
              outlined
              :color="group.permissions_count > 0 ? 'green' : 'grey lighten-2'"
              class="ml-2 font-weight-bold">
              <v-icon left x-small>
                mdi-lock-open-check
              </v-icon>
              {{ group.permissions_count }} {{ $tc('common.labels.permissions', group.permissions_count) }}
            </v-chip>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </template>
    <template v-else-if="!groups.length && !loading">
      <v-col cols=12 align="center" justify="center">
        <h-no-records-found createFormViewName="GroupFormCreate"/>
      </v-col>
    </template>
    <template v-else>
      <v-col cols=12 align="center" justify="center">
        <h-request-loading/>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import HNoRecordsFound from '@/common/components/HNoRecordsFound'
import HRequestLoading from '@/common/components/HRequestLoading'
export default {
  name: 'GroupKanban',
  components: {
    HNoRecordsFound,
    HRequestLoading,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    groups: {
      type: Array,
      default: () => { return [] },
    },
  },
  methods: {
    onGroupClick (group) {
      this.$router.push({
        name: 'GroupFormRead',
        params: { groupId: group.id },
      })
    },
  },
}
</script>

<style scoped>
  .group-card {
    transition: all 0.15s;
    cursor: pointer;
    height: 100%;
  }
  .group-card:hover {
    border: thin solid rgba(0, 0, 0, 0.25);
  }
  .v-list.h-menu-list {
    padding: 0;
  }
</style>
