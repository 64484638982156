<template>
  <DialogCard :actions="actions">
    <v-toolbar
      flat
      dark
      color="warning">
      <v-toolbar-title>
        <v-icon left>mdi-{{ icon }}</v-icon>
        {{ title }}
      </v-toolbar-title>
    </v-toolbar>
    <div class="mb-4 pa-4 subtitle-1">
      <div>{{ message }}</div>
      <div
        v-if="advice"
        class="mt-3">
        {{ advice }}
      </div>
      <v-alert v-if="checkRequired" class="ma-4" prominent outlined text dense type="warning">
        {{ checkAlert }}
        <v-checkbox
          v-if="checkRequired"
          v-model="checked"
          :label="checkMessage"
          dense/>
      </v-alert>
    </div>
    <v-divider/>
  </DialogCard>
</template>

<script>
export default {
  name: 'WarningModal',
  props: {
    icon: {
      type: String,
      default: 'alert',
    },
    title: {
      type: String,
      default: 'Warning',
    },
    message: {
      type: String,
      default: 'You cannot do that.',
    },
    advice: {
      type: String,
      default: '',
    },
    checkAlert: {
      type: String,
      default: 'Do you really want to do this ?',
    },
    checkRequired: {
      type: Boolean,
      default: () => { return false },
    },
    checkMessage: {
      type: String,
      default: 'I agree',
    },
    okText: {
      type: String,
    },
    okColor: {
      type: String,
    },
    okHandler: {
      type: Function,
    },
    noButton: {
      type: Boolean,
      default: () => { return false },
    },
    noText: {
      type: String,
    },
    noColor: {
      type: String,
    },
    noHandler: {
      type: Function,
    },
  },
  computed: {
    actions () {
      let actions = {}
      actions.true = {
        text: this.okText ? this.okText : this.$t('common.buttons.yes'),
        color: this.okColor,
        disabled: this.checkRequired && !this.checked,
      }
      if (this.okHandler) actions.true.handler = this.okHandler
      if (this.noButton) {
        actions.false = {
          text: this.noText ? this.noText : this.$t('common.buttons.cancel'),
          color: this.noColor,
        }
        if (this.noHandler) actions.false.handler = this.noHandler
      }
      return actions
    },
  },
  data () {
    return {
      checked: false,
    }
  },
}
</script>

<style scoped>
  .v-card::v-deep .v-card__text {
    padding: 0;
  }
</style>
