<template>
  <v-card tile elevation="3">
    <v-toolbar
      flat
      dense
      v-if="
        tag
        && !editing
        && (
          hasPermission('add_helpdesktickettag')
          || hasPermission('change_helpdesktickettag')
          || hasPermission('delete_helpdesktickettag')
        )">
      <v-toolbar-items>
        <v-btn
          v-if="hasPermission('change_helpdesktickettag')"
          text
          @click="edit()">
          <v-icon small class="mr-1">mdi-pencil</v-icon>
          Edit
        </v-btn>
        <v-btn
          v-if="hasPermission('delete_helpdesktickettag')"
          text
          @click="deleteTag(tag)">
          <v-icon small class="mr-1">mdi-delete</v-icon>
          Delete
        </v-btn>
        <v-btn
          v-if="hasPermission('add_helpdesktickettag')"
          text
          :to="{ name: 'HelpdeskTicketTagFormCreate' }">
          <v-icon small class="mr-1">mdi-plus</v-icon>
          Create Another
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-divider v-if="tag"/>
    <template v-if="!loadingTag">
      <v-form
        v-model="isFormValid"
        class="pa-4"
        :disabled="!editing">
        <v-row class="my-0">
          <v-col
            cols="12"
            lg="9"
            md="6">
            <v-text-field
              v-model="form.name"
              label="Name"
              required
              clearable
              autofocus
              type="text"
              maxlength="255"
              :counter="editing"
              :rules="[formRules.required]"/>
          </v-col>
          <v-col
            cols="12"
            lg="3"
            md="6">
            <v-text-field
              v-model="form.color"
              label="Color">
              <template v-slot:append>
                <v-menu
                  offsetY
                  left
                  :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <div :style="colorMenuStyle" v-on="on"/>
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker
                        v-model="form.color"
                        flat
                        hide-mode-switch/>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <template v-if="editing">
        <v-divider/>
        <v-card-actions>
          <v-btn text @click="cancel()">
            Cancel
          </v-btn>
          <v-btn
            v-if="hasPermission('change_helpdesktickettag') || hasPermission('add_helpdesktickettag')"
            text
            color="green"
            :disabled="!isFormValid"
            @click="!tag ? createTag() : updateTag(tag)">
            Save
          </v-btn>
        </v-card-actions>
      </template>
    </template>
    <template v-else>
      <v-col cols=12 align="center" justify="center">
        <h-request-loading/>
      </v-col>
    </template>
  </v-card>
</template>

<script>
import HRequestLoading from '@/common/components/HRequestLoading'
import DeleteModal from '@/common/components/DeleteModal'
import HelpdeskTicketTagService from '@/services/helpdesk/helpdesk.ticket.tag.service'
export default {
  name: 'HelpdeskTicketTagForm',
  components: {
    HRequestLoading,
  },
  props: {
    editing: { type: Boolean },
    helpdeskTicketTagId: {
      type: [Number, String],
      default: () => { return null },
    },
  },
  data () {
    return {
      loadingTag: false,
      tag: null,
      form: {
        name: '',
        color: '#BEBEBEFF',
      },
      isFormValid: false,
      formRules: {
        required: value => !!value || this.$t('common.errors.required'),
      },
    }
  },
  async mounted () {
    if (this.helpdeskTicketTagId) await this.getTag(this.helpdeskTicketTagId)
    if (this.tag) this.initFormFields()
  },
  computed: {
    colorMenuStyle () {
      const color = this.form.color
      return {
        backgroundColor: color,
        border: '1px solid black',
        cursor: 'pointer',
        height: '25px',
        width: '25px',
      }
    },
  },
  methods: {
    async getTag (tagId) {
      this.loadingTag = true
      this.tag = await HelpdeskTicketTagService.getTag(tagId)
      this.loadingTag = false
    },
    initFormFields () {
      for (let field in this.form) {
        this.form[field] = this.tag[field]
      }
    },
    edit () {
      this.$emit('toggleEdit', true)
    },
    cancelEdit () {
      this.$emit('toggleEdit', false)
    },
    cancel () {
      this.cancelEdit()
      if (this.tag) {
        for (let field in this.form) {
          this.form[field] = this.tag[field]
        }
      } else {
        history.back()
      }
    },
    async createTag () {
      const { name, color } = this.$data.form
      const createdTag = await HelpdeskTicketTagService.createTag({
        name: name,
        color: color,
      })
      if (createdTag) {
        this.cancelEdit()
        this.$router.push({ name: 'HelpdeskTicketTagFormRead', params: { helpdeskTicketTagId: createdTag.id } })
        this.$dialog.notify.success(
          `Tag '${createdTag.name}' created`, {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    async updateTag (tag) {
      const { name, color } = this.$data.form
      const updatedTag = await HelpdeskTicketTagService.updateTag(tag.id, {
        name: name,
        color: color,
      })
      if (updatedTag) {
        this.tag = updatedTag
        this.cancelEdit()
        this.$dialog.notify.success(
          'Tag updated', {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    deleteTag (tag) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Tag',
        recordNamePlural: 'Tags',
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await HelpdeskTicketTagService.deleteTags({ ids: [tag.id] })
          if (response) {
            this.$router.push({ name: 'HelpdeskTicketTagListing' })
            this.$dialog.notify.success(
              `Tag '${tag.name}' deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
  },
}
</script>
