<template>
  <h-app-main-menu :menuItems="menuItems"/>
</template>

<script>
import HAppMainMenu from '@/common/components/HAppMainMenu'
import { getFirstAuthorizedChildViewNameFromRouteName } from '@/router/tools'
export default {
  name: 'BaseMainMenu',
  components: { HAppMainMenu },
  computed: {
    menuItems () {
      return [
        {
          menuLabel: this.$tc('common.labels.users', 2),
          menuPermissions: ['view_user'],
          routeName: 'UserListing',
          matchingPaths: ['/settings/users'],
        },
        {
          menuLabel: this.$tc('common.labels.groups', 2),
          menuPermissions: ['view_group'],
          routeName: 'GroupListing',
          matchingPaths: ['/settings/groups'],
        },
        {
          menuLabel: this.$t('common.labels.applications'),
          menuPermissions: ['view_application'],
          routeName: 'ApplicationListing',
          matchingPaths: ['/settings/applications'],
        },
        {
          menuLabel: this.$t('applicationNames.base'),
          menuPermissions: [
            'view_globalschedule',
            'view_emailsettings',
            'view_outboundfailedmail',
            'view_apikey',
            'view_webhook',
          ],
          routeName: getFirstAuthorizedChildViewNameFromRouteName('BaseSettingsMenu'),
          matchingPaths: [
            '/settings/global-schedule',
            '/settings/emails',
            '/settings/integrations',
          ],
        },
      ]
    },
  },
}
</script>
