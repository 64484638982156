<template>
  <v-toolbar tile dense elevation="1">
    <v-menu
      v-if="
        hasPermission('delete_timesheetitem')
        || hasPermission('export_timesheets')
      "
      close-on-click
      close-on-content-click
      offsetY>
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on">
          {{ $tc('common.labels.actions', 2) }}
          <v-icon small right>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list class="h-menu-list">
        <v-list-item
          v-if="hasPermission('export_timesheets')"
          @click="$emit('exportTimesheets')">
          <v-icon small class="mr-4">mdi-download</v-icon>
          {{ $t('common.buttons.export') }}
        </v-list-item>
        <v-list-item
          v-if="hasPermission('delete_timesheetitem')"
          :disabled="!selectedTimesheets.length"
          @click="$emit('deleteTimesheets', selectedTimesheets)">
          <v-icon small class="mr-4">mdi-delete</v-icon>
          {{ $t('common.buttons.delete') }}
        </v-list-item>
      </v-list>
    </v-menu>
    <v-spacer/>
    <h-filtering-bar :availableFilters="availableFilters"/>
    <v-spacer/>
    <v-btn
      tile
      icon
      link
      :class="{'v-btn--active': viewType === 'list'}"
      @click="$emit('setListView')">
      <v-icon>mdi-format-list-bulleted</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import HFilteringBar from '@/common/components/HFilteringBar'
export default {
  name: 'TimesheetItemListingToolbar',
  components: { HFilteringBar },
  props: {
    viewType: { type: String },
    selectedTimesheets: {
      type: Array,
      default: () => { return [] },
    },
  },
  computed: {
    availableFilters () {
      return [
        { fieldName: 'description', label: this.$t('common.labels.description'), type: 'string' },
        { fieldName: 'author', label: this.$t('common.labels.author'), type: 'string' },
        { fieldName: 'assignee', label: this.$t('common.labels.assignee'), type: 'string' },
        { fieldName: 'unassigned', label: this.$t('common.labels.unassigned'), optionText: this.$t('common.labels.unassigned'), type: 'boolean', value: true },
        { fieldName: 'customer', label: this.$t('common.labels.customer'), type: 'string' },
        { fieldName: 'start_date', label: this.$t('common.labels.from'), type: 'date' },
        { fieldName: 'end_date', label: this.$t('common.labels.to'), type: 'date' },
      ]
    },
  },
}
</script>

<style scoped>
  .v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:last-child {
    /* Override the default -12px right margin in order to be symmetrical */
    margin-right: 0px;
  }
  .v-toolbar {
    height: unset!important;
  }
  .v-toolbar::v-deep .v-toolbar__content {
    height: fit-content!important;
  }
  .v-list.h-menu-list {
    padding: 0;
  }
</style>
