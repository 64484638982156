<template>
  <DialogCard :actions="actions">
    <v-toolbar flat dark color="blue darken-1">
      <v-toolbar-title>
        <v-icon left>mdi-plus</v-icon>
        {{ $t('common.buttons.createTicket') }}
      </v-toolbar-title>
    </v-toolbar>
    <v-form v-model="isFormValid" class="pa-4">
      <v-text-field
        v-model="form.name"
        required
        clearable
        autofocus
        type="text"
        maxlength="255"
        counter
        :label="$t('common.labels.title')"
        :rules="[formRules.required]"/>
      <h-rich-text-editor
        v-model="form.description"
        class="my-4"
        required
        editorBorders
        editorPadding
        opacifyDisabled
        formatParagraph
        formatBold
        formatItalic
        formatStrike
        formatMarker
        formatH1
        formatH2
        formatH3
        formatListB
        formatListN
        formatCode
        formatCodeBlock
        formatSeparator
        historyUndo
        historyRedo
        :editable="true"/>
      <v-file-input
        v-model="form.attachments"
        small-chips
        multiple
        counter
        show-size
        clearable
        prepend-icon=""
        prepend-inner-icon="mdi-paperclip"
        :placeholder="`${$t('common.buttons.selectFiles')}...`"/>
      <v-row class="my-0">
        <v-col cols=12 sm=6>
          <v-select
            v-model="form.priority"
            item-value="value"
            :label="$t('common.labels.priority')"
            :menu-props="{ top: false, offsetY: true }"
            :items="priorities">
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-icon>
                  <v-icon :color="item.iconColor">mdi-{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  {{ item.text }}
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:selection="data">
              <v-icon class="mr-2" :color="data.item.iconColor">mdi-{{ data.item.icon }}</v-icon>{{ data.item.text }}
            </template>
          </v-select>
        </v-col>
        <v-col cols=12 sm=6>
          <v-autocomplete
            v-model="form.contact"
            return-object
            item-text="full_name"
            :items="contacts"
            :label="$tc('common.labels.contacts', 1)"
            :disabled="userMe?.user_type === 'portal'"
            :loading="loadingContactsInput"
            @click="getContactsInput">
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-form>
    <v-divider/>
  </DialogCard>
</template>

<script>
import HRichTextEditor from '@/common/components/rich-text-editor/HRichTextEditor'
import ContactContactService from '@/services/contact/contact.contact.service'
import HelpdeskTicketPortalService from '@/services/helpdesk/helpdesk.ticket.portal.service'
export default {
  name: 'HelpdeskTicketCreateFormPortalDialog',
  components: {
    HRichTextEditor,
  },
  props: {
    userMe: {
      type: Object,
      required: true,
    },
  },
  async mounted () {
    this.loadingContactsInput = true
    this.contacts = await ContactContactService.getPortalContactsInput()
    const contactMe = this.contacts.filter(contact => contact.id === this.userMe?.contact_id)[0]
    this.loadingContactsInput = false
    this.form.contact = contactMe
  },
  data () {
    return {
      loadingContactsInput: false,
      contacts: [],
      priorities: [
        { text: this.$t('common.labels.priorityLow'), value: 'low', icon: 'chevron-up', iconColor: 'green' },
        { text: this.$t('common.labels.priorityNormal'), value: 'normal', icon: 'chevron-double-up', iconColor: 'orange' },
        { text: this.$t('common.labels.priorityHigh'), value: 'high', icon: 'chevron-triple-up', iconColor: 'red' },
      ],
      form: {
        name: '',
        description: '',
        contact: null,
        priority: 'normal',
        attachments: [],
      },
      isFormValid: false,
      formRules: {
        required: value => !!value || this.$t('common.errors.required'),
      },
    }
  },
  computed: {
    actions () {
      return {
        false: {
          text: this.$t('common.buttons.cancel'),
        },
        true: {
          text: this.$t('common.buttons.save'),
          color: 'green',
          disabled: !this.isFormValid || !this.form.description,
          handler: async () => {
            const formData = new FormData()
            formData.append('name', this.form.name)
            formData.append('description', this.form.description)
            formData.append('priority', this.form.priority)
            formData.append('contact_id', this.form.contact?.id)
            for (const attachment of this.form.attachments) {
              formData.append('attachments', attachment)
            }
            const createdTicket = await HelpdeskTicketPortalService.createTicket(formData)
            if (createdTicket['name']) {
              // Checking if returned item as 'name' attribute is an ugly way
              // to stay in the modal but still...
              // Otherwise if we encounter an error, the modal is closed and
              // user has to start over.
              return new Promise(resolve => {
                resolve(createdTicket)
              })
            }
          },
        },
      }
    },
  },
  methods: {
    async getContactsInput () {
      if (this.userMe?.user_type === 'portal_referent') {
        this.loadingContactsInput = true
        this.contacts = await ContactContactService.getPortalContactsInput()
        this.loadingContactsInput = false
      }
    },
  },
}
</script>

<style scoped>
  .v-card::v-deep
    .v-card__text {
      padding: 0;
    }
</style>
