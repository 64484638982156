var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',{staticClass:"reset-password-request-content"},[_c('v-card',{staticClass:"mx-auto my-auto",attrs:{"color":"#404040","elevation":"20","width":"380"}},[_c('v-card-title',{staticClass:"reset-password-request-card-title justify-center white--text"},[_c('h2',[_vm._v(_vm._s(_vm.$t('applications.base.resetPasswordRequestForm.forgottenPassword')))])]),_c('v-divider',{staticClass:"mt-2 mb-4"}),(!_vm.requestSuccessful)?_c('v-card-text',{on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.sendPasswordResetRequest()}}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-text-field',{staticClass:"reset-password-request-input",attrs:{"solo":"","clearable":"","prepend-inner-icon":"mdi-email","type":"email","maxlength":"254","autofocus":"","error":!!_vm.errorMessage,"error-messages":_vm.errorMessage,"label":_vm.$tc('common.labels.emails', 1),"rules":[
            _vm.formRules.email,
            _vm.formRules.required
          ]},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"info-icon",attrs:{"color":"grey"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,false,3387298820)},[_vm._v(" "+_vm._s(_vm.$t('applications.base.resetPasswordRequestForm.infoTooltip'))+" ")])]},proxy:true}],null,false,2093321585),model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('v-btn',{class:{
          'disable-events': !_vm.isFormValid || _vm.loadingRequest,
          'white--text': _vm.isFormValid,
        },attrs:{"block":"","rounded":"","depressed":_vm.isFormValid ? false : true,"color":_vm.isFormValid ? 'green' : 'grey'},on:{"click":function($event){return _vm.sendPasswordResetRequest()}}},[_vm._v(" "+_vm._s(_vm.$t('applications.base.resetPasswordRequestForm.sendButton'))+" ")])],1):_c('v-card-text',{staticClass:"white--text",staticStyle:{"opacity":"0.6"}},[_c('div',{staticClass:"d-flex justify-center mb-2"},[_c('video',{attrs:{"width":"150","height":"150","autoplay":"","muted":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":require('@/assets/videos/check_animation.webm'),"type":"video/webm"}})])]),_c('div',{staticClass:"d-flex justify-center white--text mb-3"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('applications.base.resetPasswordRequestForm.requestReceived'))+" ")])]),_c('h3',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.$t('applications.base.resetPasswordRequestForm.confirmationText'))+" ")])]),_c('v-divider',{staticClass:"my-2"}),_c('v-btn',{staticClass:"white--text overline",attrs:{"tile":"","block":"","text":"","color":"grey","to":{ name: 'LoginForm' }}},[_vm._v(" "+_vm._s(_vm.$t('applications.base.resetPasswordRequestForm.backToLoginButton'))+" ")]),_c('div',{staticClass:"h-poweredby-bar mt-4 justify-center align-center"},[_c('span',{staticClass:"overline white--text"},[_vm._v("Powered by")]),_c('a',{attrs:{"href":"https://www.hubbiz.be","target":"_blank"}},[_c('v-img',{attrs:{"src":require("@/assets/images/hubbiz_full_mix_white_h.png"),"contain":"","max-height":"20","max-width":"100"}})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }