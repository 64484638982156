import { render, staticRenderFns } from "./HelpdeskTicketSubtaskList.vue?vue&type=template&id=4481faac&scoped=true"
import script from "./HelpdeskTicketSubtaskList.vue?vue&type=script&lang=js"
export * from "./HelpdeskTicketSubtaskList.vue?vue&type=script&lang=js"
import style0 from "./HelpdeskTicketSubtaskList.vue?vue&type=style&index=0&id=4481faac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4481faac",
  null
  
)

export default component.exports