export default {
  all: 'Tous',
  for: 'pour',
  loading: 'Chargement',
  of: 'sur',
  selectionOnly: 'Sélection seulement',
  showing: 'Affiche',
  applicationNames: {
    base: 'Paramètres',
    contact: 'Contacts',
    helpdesk: 'Helpdesk',
    inventory: 'Inventaire',
    timesheets: 'Timesheets',
  },
  applications: {
    base: {
      applications: {
        messages: {
          applicationActivated: 'Application \'{name}\' activée',
          applicationDeactivated: 'Application \'{name}\' désactivée',
        },
      },
      changeUserPasswordDialog: {
        changePasswordOf: 'Changer le mot de passe de {fullName}',
      },
      groups: {
        common: {
          deleteGroupTitle: 'Supprimer le groupe \'{name}\' ?',
          deleteGroupWarningText: 'Voulez-vous vraiment supprimer le groupe \'{name}\' ?',
          deleteGroupsTitle: 'Supprimer le groupe sélectionné ? | Supprimer le groupe sélectionné ? | Supprimer les groupes sélectionnés ?',
          deleteGroupsWarningText: 'Voulez-vous vraiment supprimer ce groupe ? | Voulez-vous vraiment supprimer ce groupe ? | Voulez-vous vraiment supprimer ces groupes ?',
        },
        form: {
          usersTab: {
            addUserButton: 'Ajouter cet utilisateur | Ajouter cet utilisateur | Ajouter ces utilisateurs',
            searchUsers: 'Chercher un utilisateur...',
            selectUsersToAdd: 'Sélectionnez des utilisateurs à ajouter...',
          },
        },
        messages: {
          groupCreated: 'Groupe \'{name}\' créé',
          groupDeleted: 'Groupe \'{name}\' supprimé',
          groupUpdated: 'Groupe \'{name}\' modifié',
          groupsDeleted: 'Le groupe sélectionné a été supprimé | Le groupe sélectionné a été supprimé | Les {count} groupes sélectionnés ont été supprimés',
          permissionsUpdated: 'Permissions mises à jour',
          usersAddedToGroup: '{count} utilisateur ajouté au groupe \'{name}\' | {count} utilisateur ajouté au groupe \'{name}\' | {count} utilisateurs ajoutés au groupe \'{name}\'',
          userRemovedFromGroup: 'L\'utilisateur \'{fullName} ({username})\' a été enlevé du groupe \'{name}\'',
        },
      },
      loginForm: {
        forgottenPassword: 'Mot de passe oublié',
        login: 'Connexion',
        loginBtnText: 'Se connecter',
        switchBtnText: 'Changer d\'utilisateur',
        welcomeBack: 'Content de vous revoir,',
      },
      menus: {
        logout: 'Déconnexion',
        myProfile: 'Mon Profil',
      },
      resetPasswordForm: {
        backToLoginButton: 'Retour à la Connexion',
        requestSuccess: 'Mot de passe changé avec succès',
        resetPassword: 'Réinitialisation du Mot de Passe',
        sendButton: 'Envoyer',
      },
      resetPasswordRequestForm: {
        backToLoginButton: 'Retour à la Connexion',
        confirmationText: 'Si l\'adresse email que vous avez renseignée est valide, vous recevrez sous peu un email contenant les étapes à suivre pour réinitialiser votre mot de passe.',
        forgottenPassword: 'Mot de passe oublié',
        infoTooltip: 'Saisissez l\'adresse email correspondant à votre compte et nous vous enverrons un lien pour réinitialiser votre mot de passe.',
        requestReceived: 'Nous avons reçu votre demande',
        sendButton: 'Envoyer',
      },
      settings: {
        emails: {
          classic: {
            inbound: {
              createInboundTitle: 'Ajouter une boîte email entrante',
              deleteInboundMailboxTitle: 'Supprimer la boîte email \'{name}\' ?',
              deleteInboundMailboxWarningText: 'Voulez-vous vraiment supprimer cette boîte email ?',
              updateInboundTitle: 'Modifier une boîte email entrante',
            },
            messages: {
              inboundCreated: 'Boîte d\'emails entrants ajoutée',
              inboundDeleted: 'Boîte d\'emails entrants supprimée',
              inboundUpdated: 'Boîte d\'emails entrants modifiée',
              outboundUpdated: 'Paramètres d\'emails sortants modifiés',
            },
            outbound: {
              hints: {
                defaultEmailFrom: 'Sera utilisée comme adresse par défaut pour envoyer les emails.',
              },
            },
          },
          common: {
            hints: {
              testConnection: 'Veuillez tester si la connexion fonctionne avant de sauvegarder.',
              failedConnection: 'Les informations fournies n\'ont pas fonctionné.',
              successConnection: 'Les informations fournies ont permis d\'établir la connexion.',
            },
            updateEmailSettingsTitle: 'Modifier les paramètres email ?',
            updateEmailSettingsText: 'Voulez-vous vraiment sauvegarder ces paramètres ?',
          },
          office365: {
            configurationTitle: 'Configuration Office 365',
            hints: {
              defaultEmailFrom: 'Sera utilisée comme adresse par défaut pour envoyer les emails. Vous devez utiliser une adresse/boîte existante.',
            },
            messages: {
              office365Updated: 'Paramètres email Office 365 modifiés',
            },
          },
          outboundFailedEmails: {
            deleteOutboundFailedEmailAlertText: 'Cela supprimera également le message lié (par exemple: message de ticket).',
            deleteOutboundFailedEmailTitle: 'Supprimer cet email ayant échoué ?',
            deleteOutboundFailedEmailWarningText: 'Voulez-vous vraiment supprimer cet email ayant échoué ?',
            messages: {
              failedEmailDeleted: 'Email échoué supprimé',
            },
          },
        },
        integrations: {
          apikeys: {
            common: {
              activateApikeyTitle: 'Activer la clé API \'{name}\' ?',
              activateApikeyText: 'Voulez-vous vraiment activer la clé API \'{name}\' ?',
              deactivateApikeyTitle: 'Désactiver la clé API \'{name}\' ?',
              deactivateApikeyText: 'Voulez-vous vraiment désactiver la clé API \'{name}\' ?',
              deleteApikeyTitle: 'Supprimer la clé API \'{name}\' ?',
              deleteApikeyWarningText: 'Voulez-vous vraiment supprimer la clé API \'{name}\' ?',
              rotateApikeyTitle: 'Renouveler la clé API \'{name}\' ?',
              rotateApikeyText: 'Voulez-vous vraiment renouveler la clé API \'{name}\' ?',
            },
            form: {
              hints: {
                useMeaningfulName: 'Utilisez un nom significatif qui représente l\'utilisation qui en sera faite. Par exemple: \'Zabbix\'.',
              },
            },
            messages: {
              apikeyActivated: 'Clé API \'{name}\' activée',
              apikeyCreated: 'Clé API \'{name}\' créée',
              apikeyDeactivated: 'Clé API \'{name}\' désactivée',
              apikeyDeleted: 'Clé API \'{name}\' supprimée',
              apikeyRotated: 'Clé API \'{name}\' renouvelée',
              apikeyTokenCopiedToClipboard: 'Token copié dans le presse-papiers',
              apiUserCreated: 'Utilisateur API \'{fullName}\' créé',
            },
          },
          webhooks: {
            actions: {
              createOrUpdateHelpdeskTicket: 'Créer ou modifier un ticket Helpdesk',
            },
            common: {
              activateWebhookTitle: 'Activer le webhook \'{name}\' ?',
              activateWebhookText: 'Voulez-vous vraiment activer le webhook \'{name}\' ?',
              deactivateWebhookTitle: 'Désactiver le webhook \'{name}\' ?',
              deactivateWebhookText: 'Voulez-vous vraiment désactiver le webhook \'{name}\' ?',
              deleteWebhookTitle: 'Supprimer le webhook \'{name}\' ?',
              deleteWebhookWarningText: 'Voulez-vous vraiment supprimer le webhook \'{name}\' ?',
            },
            form: {
              hints: {
                useMeaningfulName: 'Utilisez un nom significatif qui représente l\'utilisation qui en sera faite.',
              },
              placeholders: {
                namePlaceholder: 'Par exemple: Créer un ticket depuis Zabbix',
                userNamePlaceholder: 'Par exemple: Zabbix',
              },
            },
            messages: {
              webhookActivated: 'Webhook \'{name}\' activé',
              webhookCreated: 'Webhook \'{name}\' créé',
              webhookDeactivated: 'Webhook \'{name}\' désactivé',
              webhookDeleted: 'Webhook \'{name}\' supprimé',
              webhookTokenCopiedToClipboard: 'Token copié dans le presse-papiers',
            },
          },
        },
        schedule: {
          form: {
            hints: {
              dayNotWorked: 'Un début et une fin à 0 indique que le jour n\'est pas travaillé.',
              useH24: 'Si activé, les jours fériés seront considérés comme travaillés.',
              useCountry: 'Choisissez un pays sur lequel baser votre horaire. Cela exclura les jours fériés des calculs.',
            },
          },
          messages: {
            scheduleUpdated: 'Horaire modifié',
          },
        },
      },
      users: {
        common: {
          deactivateUserTitle: 'Désactiver {fullName} ({username}) ?',
          deactivateUserText: 'Voulez-vous vraiment désactiver {fullName} ({username}) ?',
          deleteUserAlertText: 'En supprimant un utilisateur, vous pourriez perdre des liens et de l\'historique. Veuillez plutôt le désactiver. | En supprimant un utilisateur, vous pourriez perdre des liens et de l\'historique. Veuillez plutôt le désactiver. | En supprimant des utilisateurs, vous pourriez perdre des liens et de l\'historique. Veuillez plutôt les désactiver.',
          deleteUserCheckText: 'Je préfère supprimer cet utilisateur | Je préfère supprimer cet utilisateur | Je préfère supprimer ces utilisateurs',
          deleteUserTitle: 'Supprimer {fullName} ({username}) ?',
          deleteUserWarningText: 'Voulez-vous vraiment supprimer {fullName} ({username}) ?',
          deactivateUsersText: 'Voulez-vous vraiment désactiver cet utilisateur ? | Voulez-vous vraiment désactiver cet utilisateur ? | Voulez-vous vraiment désactiver ces utilisateurs ?',
          deactivateUsersTitle: 'Désactiver l\'utilisateur sélectionné ? | Désactiver l\'utilisateur sélectionné ? | Désactiver les utilisateurs sélectionnés ?',
          deleteUsersTitle: 'Supprimer l\'utilisateur sélectionné ? | Supprimer l\'utilisateur sélectionné ? | Supprimer les utilisateurs sélectionnés ?',
          deleteUsersWarningText: 'Voulez-vous vraiment supprimer cet utilisateur ? | Voulez-vous vraiment supprimer cet utilisateur ? | Voulez-vous vraiment supprimer ces utilisateurs ? ',
        },
        form: {
          formTab: {
            hints: {
              userContactSync: 'Les modifications apportées à certains champs seront synchronisées avec le contact.',
              userCreation: 'Un contact sera créé pour cet utilisateur. Si le contact désiré existe déjà, créez plutôt un utilisateur depuis ce dernier.',
              userCreationActive: 'L\'utilisateur sera créé comme actif.',
              userCreationInactive: 'L\'utilisateur sera créé comme inactif.',
              userTypePortal: 'Ce type d\'utilisateur n\'aura accès qu\'au portail, si ce dernier est activé.',
              userTypePortalRef: 'Ce type d\'utilisateur n\'aura accès qu\'au portail, si ce dernier est activé, mais avec une vue à l\'échelle de la société.',
              userTypeSystem: 'Ce type d\'utilisateur aura accès à la plateforme par défaut.',
            },
          },
          groupsTab: {
            addGroupButton: 'Ajouter ce groupe | Ajouter ce groupe | Ajouter ces groupes',
            searchGroups: 'Chercher un groupe...',
            selectGroupsToAdd: 'Sélectionnez des groupes à ajouter...',
          },
          historyTab: {
            neverActivated: 'Jamais activé(e).',
            neverDeactivated: 'Jamais désactivé(e).',
            neverLoggedIn: 'Jamais connecté(e).',
          },
          permissionsTab: {
            infoText: 'Ceci est un résumé des permissions de {fullName}, en lecture seule. Les ermissions sont gérées par groupe.',
          },
        },
        messages: {
          groupsAddedToUser: '{count} groupe ajouté à l\'utilisateur \'{username}\' | {count} groupe ajouté à l\'utilisateur \'{username}\' | {count} groupes ajoutés à l\'utilisateur \'{username}\'',
          groupRemovedFromUser: 'Le groupe \'{groupName}\' a été enlevé de l\'utilisateur \'{username}\'',
          userActivated: 'Utilisateur \'{username}\' activé',
          userAvatarUpdated: 'Avatar de {fullName} modifié',
          userCreated: 'Utilisateur \'{username}\' créé',
          userDeactivated: 'Utilisateur \'{username}\' désactivé',
          userDeleted: 'Utilisateur {fullName} ({username}) supprimé',
          userPasswordUpdated: 'Mot de passe de {fullName} modifié',
          userProfilePasswordUpdated: 'Mot de passe modifié',
          userProfileUpdated: 'Profil modifié',
          userUpdated: 'Utilisateur \'{username}\' modifié',
          usersActivated: '{effectiveCount} sur {count} utilisateur sélectionné activé | {effectiveCount} sur {count} utilisateur sélectionné activé | {effectiveCount} sur {count} utilisateurs sélectionnés activé(s)',
          usersDeactivated: '{effectiveCount} sur {count} utilisateur sélectionné désactivé | {effectiveCount} sur {count} utilisateur sélectionné désactivé | {effectiveCount} sur {count} utilisateurs sélectionnés désactivé(s)',
          usersDeleted: 'L\'utilisateur sélectionné a été supprimé | L\'utilisateur sélectionné a été supprimé | Les {count} utilisateurs sélectionnés ont été supprimés',
        },
      },
    },
    contact: {
      address: {
        common: {
          deleteAddressTitle: 'Supprimer l\'adresse \'{name}\' ?',
          deleteAddressWarningText: 'Voulez-vous vraiment supprimer l\'adresse \'{name}\' ?',
        },
        messages: {
          addressCreated: 'Adresse créée',
          addressDeleted: 'Adresse supprimée',
          addressUpdated: 'Adresse mise à jour',
        },
      },
      buttons: {
        archiveContact: 'Archiver le Contact',
        unarchiveContact: 'Désarchiver le Contact',
      },
      companies: {
        common: {
          deleteCompanyTitle: 'Supprimer la société \'{name}\' ?',
          deleteCompanyWarningText: 'Voulez-vous vraiment supprimer la société \'{name}\' ?',
          deleteCompaniesTitle: 'Supprimer la société sélectionnée ? | Supprimer la société sélectionnée ? | Supprimer les sociétés sélectionnées ?',
          deleteCompaniesWarningText: 'Voulez-vous vraiment supprimer cette société ? | Voulez-vous vraiment supprimer cette société ? | Voulez-vous vraiment supprimer ces sociétés ?',
        },
        messages: {
          companiesDeleted: 'La société sélectionnée a été supprimée | La société sélectionnée a été supprimée | Les {count} sociétés sélectionnées ont été supprimées',
          companyCreated: 'Société \'{name}\' créée',
          companyDeleted: 'Société \'{name}\' supprimée',
          companyUpdated: 'Société mise à jour',
        },
      },
      contacts: {
        common: {
          deleteContactAlertText: 'En supprimant un contact, vous pourriez perdre des liens et de l\'historique. Veuillez plutôt l\'archiver.',
          deleteContactCheckText: 'Je préfère supprimer ce contact',
          deleteContactTitle: 'Supprimer {fullName} ?',
          deleteContactWarningText: 'Voulez-vous vraiment supprimer {fullName} ?',
          setContactCompanyAdviceText: 'Les objets liés à ce contact seront aussi liés à la société.',
          setContactCompanyAlextText: 'Gardez à l\'esprit que ce changement est irréversible, pour la consistence des données.',
          setContactCompanyCheckText: 'J\'ai compris',
          setContactCompanyTitle: 'Attention: changement de société',
          setContactCompanyWarningText: 'Vous essayez de changer la société ({companyName}) pour ce contact.',
        },
        form: {
          hints: {
            contactUserSync: 'Les modifications apportées à certains champs seront synchronisées avec l\'utilisateur.'
          },
        },
        messages: {
          contactArchived: 'Contact \'{fullName}\' archivé',
          contactCreated: 'Contact \'{fullName}\' créé',
          contactDeleted: 'Contact \'{fullName}\' supprimé',
          contactUnarchived: 'Contact \'{fullName}\' désarchivé',
          contactUpdated: 'Contact modifié',
          contactUserCreated: 'Utilisateur créé pour le contact \'{fullName}\'',
        },
      },
      tags: {
        common: {
          deleteTagTitle: 'Supprimer le tag \'{name}\' ?',
          deleteTagWarningText: 'Voulez-vous vraiment supprimer le tag \'{name}\' ?',
          deleteTagsTitle: 'Supprimer le tag sélectionné ? | Supprimer le tag sélectionné ? | Supprimer les tags sélectionnés ?',
          deleteTagsWarningText: 'Voulez-vous vraiment supprimer ce tag ? | Voulez-vous vraiment supprimer ce tag ? | Voulez-vous vraiment supprimer ces tags ?',
        },
        messages: {
          tagCreated: 'Tag \'{tagName}\' créé',
          tagDeleted: 'Tag \'{tagName}\' supprimé',
          tagUpdated: 'Tag modifié',
          tagsDeleted: 'Le tag sélectionné a été supprimé | Le tag sélectionné a été supprimé | Les {count} tags sélectionnés ont été supprimés'
        },
      },
      userFromContactFormDialog: {
        userTypeFormHint: {
          system: 'Ce type d\'utilisateur aura accès à la plateforme par défault.',
          portal: 'Ce type d\'utilisateur aura accès uniquement au portail, si activé.',
          portalRef: 'Ce type d\'utilisateur aura accès uniquement au portail, si activé, mais avec une vision sur la société.',
        },
      },
    },
    helpdesk: {
      tickets: {
        messages: {
          ticketCreated: 'Ticket créé',
          ticketUpdated: 'Ticket modifié',
        },
      },
    },
    inventory: {

    },
    timesheets: {
      items: {
        common: {
          deleteTimesheetTitle: 'Supprimer la timesheet ?',
          deleteTimesheetWarningText: 'Voulez-vous vraiment supprimer la timesheet ?',
          deleteTimesheetsTitle: 'Supprimer la timesheet sélectionnée ? | Supprimer la timesheet sélectionnée ? | Supprimer les timesheets sélectionnéss ?',
          deleteTimesheetsWarningText: 'Voulez-vous vraiment supprimer cette timesheet ? | Voulez-vous vraiment supprimer cette timesheet ? | Voulez-vous vraiment supprimer ces timesheets ?',
        },
        messages: {
          timesheetDeleted: 'Timesheet \'{description}\' supprimée',
          timesheetsDeleted: 'La timesheet sélectionnée a été supprimée | La timesheet sélectionnée a été supprimée | Les {count} timesheets sélectionnées ont été supprimées'
        },
      },
    },
  },
  common: {
    buttons: {
      activate: 'Activer',
      add: 'Ajouter',
      addAddress: 'Ajouter une adresse',
      archive: 'Archiver',
      back: 'Retour',
      cancel: 'Annuler',
      changePassword: 'Changer le mot de passe',
      copyToClipboard: 'Copier',
      create: 'Créer',
      createAPIKey: 'Créer une clé API',
      createTicket: 'Créer un ticket',
      createUser: 'Créer un utilisateur',
      createUserAPI: 'Créer un utilisateur API',
      createWebhook: 'Créer un webhook',
      deactivate: 'Désactiver',
      delete: 'Supprimer',
      edit: 'Modifier',
      export: 'Exporter',
      home: 'Accueil',
      iAgree: 'Je suis d\'accord',
      learnMore: 'En savoir plus',
      loadMore: 'Afficher plus',
      open: 'Voir',
      remove: 'Enlever',
      reset: 'Réinitialiser',
      rotate: 'Renouveler',
      save: 'Sauvegarder',
      selectAll: 'Tout sélectionner',
      selectFiles: 'Sélectionner des fichiers',
      selectFilters: 'Sélectionner des filtres',
      testConnection: 'Tester la Connexion',
      unarchive: 'Désarchiver',
      unselectAll: 'Tout désélectionner',
      updateTicket: 'Modifier le ticket',
      yes: 'Oui',
    },
    errors: {
      dateFromBeforeTo: 'La date de début doit être avant la date de fin',
      dateToAfterFrom: 'La date de fin doit être après la date de début',
      invalidDomainOneOf: 'Un des domaines est invalide',
      invalidEmail: 'Adresse email invalide',
      invalidHostname: 'Nom d\'hôte invalide',
      invalidPortAbove: 'Le port doit être >= 0',
      invalidPortBelow: 'Le port doit être <= 65535',
      invalidPostalCode: 'Code postal invalide',
      invalidSSLAndTLSExclusive: 'SSL et TLS sont mutuellement exclusifs',
      passwordsDoNotMatch: 'Les mots de passe ne correspondent pas',
      required: 'Requis(e)',
    },
    hints: {
      pressToValidate: 'Appuyez sur <enter>, <tab>, <espace> ou <,> pour valider une nouvelle entrée',
    },
    labels: {
      actions: 'Action | Action | Actions',
      activated: 'Activé',
      address: 'Adresse',
      addresses: 'Adresses',
      apikeys: 'Clé API | Clé API | Clés API',
      applications: 'Applications',
      archived: 'Archivé',
      assignee: 'Assigné(e) à',
      attempts: 'Tentative | Tentative | Tentatives',
      author: 'Auteur',
      categories: 'Catégorie | Catégorie | Catégories',
      city: 'Ville',
      closed: 'Fermé',
      closingDate: 'Date de Clôture',
      colors: 'Couleur | Couleur | Couleurs',
      companies: 'Société | Société | Sociétés',
      companyAddresses: 'Adresse de Société | Adresse de Société | Adresses de Société',
      configTemplate: 'Modèle de Configuration',
      configuration: 'Configuration',
      contacts: 'Contact | Contact | Contacts',
      contentHTML: 'Contenu HTML',
      contentText: 'Contenu Texte',
      country: 'Pays',
      createdBy: 'Créé(e) par',
      creationDate: 'Date de Création',
      customer: 'Client',
      day0End: 'Lundi (Fin)',
      day0Start: 'Lundi (Début)',
      day1End: 'Mardi (Fin)',
      day1Start: 'Mardi (Début)',
      day2End: 'Mercredi (Fin)',
      day2Start: 'Mercredi (Début)',
      day3End: 'Jeudi (Fin)',
      day3Start: 'Jeudi (Début)',
      day4End: 'Vendredi (Fin)',
      day4Start: 'Vendredi (Début)',
      day5End: 'Samedi (Fin)',
      day5Start: 'Samedi (Début)',
      day6End: 'Dimanche (Fin)',
      day6Start: 'Dimanche (Début)',
      deactivated: 'Désactivé',
      defaultEmailFrom: 'Adresse Email par Défaut (De)',
      description: 'Description',
      domain: 'Domaine',
      domains: 'Domaines',
      duration: 'Durée',
      emailConfigClassicInbound: 'Entrants (IMAP/POP)',
      emailConfigClassicOutbound: 'Sortants (SMTP)',
      emailConfigTemplateClassic: 'Classique (SMTP-IMAP/POP)',
      emailConfigTemplateOffice: 'Office 365',
      emailPro: 'Email Professionnel',
      emails: 'Email | Email | Emails',
      firstName: 'Prénom',
      from: 'De',
      fullName: 'Nom',
      globalSchedule: 'Horaire',
      groups: 'Groupe | Groupe | Groupes',
      helpdeskTeams: 'Équipe Helpdesk | Équipe Helpdesk | Équipes Helpdesk',
      hosts: 'Hôte | Hôte | Hôtes',
      integrations: 'Intégration | Intégration | Intégrations',
      language: 'Langue',
      lastActivation: 'Dernière Activation',
      lastAttempt: 'Dernière Tentative',
      lastDeactivation: 'Dernière Désactivation',
      lastLogin: 'Dernière Connexion',
      lastName: 'Nom',
      lastUpdate: 'Dernière Modification',
      lastUsed: 'Dernière Utilisation',
      logs: 'Historique',
      mailboxes: 'Boîte Email | Boîte Email | Boîtes Email',
      name: 'Nom',
      newPassword: 'Nouveau Mot de Passe',
      newPasswordConfirm: 'Confirmer Mot de Passe',
      notes: 'Note | Note | Notes',
      opened: 'Ouvert',
      outboundFailedEmails: 'Email Sortant Bloqué | Email Sortant Bloqué | Emails Sortants Bloqués',
      parameters: 'Paramètre | Paramètre | Paramètres',
      partNumbers: 'Numéro de Produit | Numéro de Produit | Numéros de Produit',
      password: 'Mot de passe',
      permissions: 'Permission | Permission | Permissions',
      personal: 'Personnel',
      phone: 'Téléphone',
      phonePerso: 'Téléphone Personnel',
      phonePersoMobile: 'Téléphone Personnel (mobile)',
      phonePro: 'Téléphone Professionnel',
      phoneProMobile: 'Téléphone Professionnel (mobile)',
      ports: 'Port | Port | Ports',
      priority: 'Priorité',
      priorityHigh: 'Haute',
      priorityLow: 'Basse',
      priorityNormal: 'Normale',
      professional: 'Professionnel',
      reason: 'Raison',
      replyTo: 'Répondre À',
      selection: 'Sélection',
      serialNumbers: 'Numéro de Série | Numéro de Série | Numéros de Série',
      serverType: 'Type de Serveur',
      slaLevel: "Niveau SLA",
      source: 'Source',
      status: 'État',
      streetPerso: 'Adresse',
      subject: 'Sujet',
      tags: 'Tag | Tag | Tags',
      title: 'Titre',
      to: 'À',
      token: 'Token',
      unarchived: 'Non Archivé',
      unassigned: 'Non assigné',
      url: 'URL',
      username: 'Nom d\'utilisateur',
      users: 'Utilisateur | Utilisateur | Utilisateurs',
      usersAPI: 'Utilisateur API | Utilisateur API | Utilisateurs API',
      usersPortal: 'Utilisateur Portail | Utilisateur Portal | Utilisateurs Portail',
      usersPortalRef: 'Utilisateur Portail Réf. | Utilisateur Portal Réf. | Utilisateurs Portail Réf.',
      usersSystem: 'Utilisateur Système | Utilisateur Système | Utilisateurs Système',
      userType: 'Type d\'Utilisateur',
      useSSL: 'Utilise SSL',
      useTLS: 'Utilise TLS',
      webhooks: 'Webhook | Webhook | Webhooks',
      workH24: 'Travail 24/7',
      zipCodePerso: 'Code Postal',
    },
  },
  globalPages: {
    home: {
      askAdminText: 'Demandez à votre administrateur pour obtenir des accès.',
      noAccessText: 'Vous n\'avez pas encore accès aux applications.',
    },
    notFound: {
      nothingHere: 'On dirait qu\'il n\'y a rien par ici',
      pageNotFound: 'PAGE NON TROUVÉE',
    },
  },
  timing: {
    days: 'jour | jour | jours',
    hours: 'heure | heure | heures',
    minutes: 'minute | minute | minutes',
  },
}
