<template>
  <DialogCard :actions="actions">
    <v-toolbar
      flat
      dark
      color="blue darken-1">
      <v-toolbar-title>
        <v-icon v-if="subtask" left>mdi-pencil</v-icon>
        <v-icon v-else left>mdi-sticker-plus</v-icon>
        {{ subtask ? 'Edit' : 'Create' }} Subtask
      </v-toolbar-title>
    </v-toolbar>
    <v-form
      v-model="isFormValid"
      class="pa-4">
      <v-text-field
        v-model="form.description"
        label="Description"
        required
        clearable
        autofocus
        type="text"
        maxlength="140"
        counter
        :rules="[formRules.required]"/>
      <h-datetime-picker v-model="form.due_date" label="Due Date" :clearable="true"/>
      <v-autocomplete
        label="Assigned To"
        v-model="form.assigned_user"
        :loading="loadingMembers"
        :items="members"
        item-text="full_name"
        return-object
        clearable
        @click="getMembersInput()">
        <template v-slot:item="data">
          <v-list-item-avatar>
            <v-img :src="data.item.picture ? getPictureURL(data.item.picture) : require('@/assets/images/no-user-picture.jpeg')"/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.full_name"/>
            <v-list-item-subtitle v-html="data.item.username"/>
          </v-list-item-content>
        </template>
        <template v-slot:selection="data">
          <v-chip
            small
            pill
            class="font-weight-bold">
            <v-avatar left>
              <v-img :src="data.item.picture ? getPictureURL(data.item.picture) : require('@/assets/images/no-user-picture.jpeg')"/>
            </v-avatar>
            {{ data.item.full_name }}
          </v-chip>
        </template>
      </v-autocomplete>
      <v-select
        v-model="form.status"
        label="Status"
        item-value="value"
        :items="ticketSubtasksStatuses"
        :menu-props="{ top: false, offsetY: true }"
        :rules="[formRules.required]"
        @change="changeStatusSelection">
        <template v-slot:item="{ item, attrs, on }">
          <v-list-item v-on="on" v-bind="attrs">
            <v-list-item-icon>
              <v-icon :color="ticketSubtaskStatusAttributes(item.value).color">
                {{ ticketSubtaskStatusAttributes(item.value).icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              {{ ticketSubtaskStatusAttributes(item.value).text }}
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:selection="data">
          <v-icon class="mr-2" :color="ticketSubtaskStatusAttributes(data.item.value).color">
            {{ ticketSubtaskStatusAttributes(data.item.value).icon }}
          </v-icon>
          {{ ticketSubtaskStatusAttributes(data.item.value).text }}
        </template>
      </v-select>
      <h-datetime-picker
        v-if="form.status === 'done'"
        v-model="form.done_date"
        label="Done Date"
        :clearable="true"
        :rules="[formRules.required]"/>
    </v-form>
    <v-divider/>
  </DialogCard>
</template>

<script>
import BaseUserService from '@/services/base/base.user.service'
import HDatetimePicker from '@/common/components/forms/HDatetimePicker'
export default {
  name: 'HelpdeskTicketSubtaskFormDialog',
  components: {
    HDatetimePicker,
  },
  props: {
    subtask: { type: Object, default: () => { return null } },
  },
  data () {
    return {
      loadingMembers: false,
      members: [],
      ticketSubtasksStatuses: [
        { text: 'To Do', value: 'to_do', },
        { text: 'In Progress', value: 'in_progress' },
        { text: 'Done', value: 'done' },
      ],
      form: {
        description: this.subtask ? this.subtask.description : '',
        assigned_user: this.subtask ? this.subtask.assigned_user : null,
        status: this.subtask ? this.subtask.status : '',
        due_date: this.subtask ? this.subtask.due_date : null,
        done_date: this.subtask ? this.subtask.done_date : null,
      },
      isFormValid: false,
      formRules: {
        required: value => !!value || this.$t('common.errors.required'),
      },
    }
  },
  mounted () {
    if (this.subtask?.assigned_user) {
      this.members = [this.subtask.assigned_user]
    }
  },
  computed: {
    actions () {
      return {
        false: {
          text: 'Cancel',
        },
        true: {
          text: 'Save',
          color: 'green',
          disabled: !this.isFormValid,
          handler: () => {
            return new Promise(resolve => {
              resolve(this.form)
            })
          },
        },
      }
    },
  },
  methods: {
    async getMembersInput () {
      this.loadingMembers = true
      this.members = await BaseUserService.getUsersInput()
      this.loadingMembers = false
    },
    ticketSubtaskStatusAttributes (status) {
      if (status === 'to_do') return { icon: 'mdi-circle-outline', color: 'red', text: "To Do" }
      if (status === 'in_progress') return { icon: 'mdi-progress-wrench', color: 'orange', text: "In Progress" }
      if (status === 'done') return { icon: 'mdi-check-circle', color: 'green', text: "Done" }
    },
    changeStatusSelection () {
      if (this.form.status !== 'done') {
        this.form.done_date = null
      }
    }
  },
}
</script>

<style scoped>
  .v-card::v-deep
    .v-card__text {
      padding: 0;
    }
</style>
