<template>
  <v-row class="my-0">
    <template v-if="applications.length && !loading">
      <v-col
        cols="12" xs="12" sm="6" md="4" lg="3" xl="2"
        v-for="application in applications"
        :key="application.id">
        <v-card tile elevation="3">
          <v-card-title>
            <v-icon left>mdi-{{ application.icon }}</v-icon>
            {{ $t(`applicationNames.${application.name}`) }}
            <template v-if="application.is_activated">
              <v-spacer/>
              <v-icon small color="green">
                mdi-check-decagram
              </v-icon>
            </template>
          </v-card-title>
          <v-card-subtitle>{{ application.summary }}</v-card-subtitle>
          <v-card-actions>
            <v-btn
              v-if="application.name !== 'base'"
              disabled
              text
              small
              color="primary">
              {{ $t('common.buttons.learnMore') }}
            </v-btn>
            <v-spacer/>
            <v-btn
              v-if="!application.is_activated && hasPermission('activate_application')"
              @click="$emit('activateApplication', application)"
              text
              small
              color="green">
              {{ $t('common.buttons.activate') }}
            </v-btn>
            <v-btn
              v-else-if="
                application.is_activated
                && application.is_deactivable
                && hasPermission('deactivate_application')
              "
              @click="$emit('deactivateApplication', application)"
              text
              small
              color="red">
              {{ $t('common.buttons.deactivate') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
    <template v-else-if="!applications.length && !loading">
      <v-col cols=12 align="center" justify="center">
        <h-no-records-found/>
      </v-col>
    </template>
    <template v-else>
      <v-col cols=12 align="center" justify="center">
        <h-request-loading/>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import HNoRecordsFound from '@/common/components/HNoRecordsFound'
import HRequestLoading from '@/common/components/HRequestLoading'
export default {
  name: 'ApplicationKanban',
  components: {
    HNoRecordsFound,
    HRequestLoading,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    applications:{
      type: Array,
      default: () => { return [] },
    },
  },
}
</script>

<style scoped>
  .v-card {
    height: 100%;
  }
</style>
