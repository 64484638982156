<template>
  <v-main class="login-content">
    <v-card
      color="#404040"
      elevation="20"
      width="380"
      class="mx-auto my-auto">
      <v-card-title class="login-card-title justify-center white--text">
        <template v-if="!lastLoggedInUser">
          <h2>{{ $t('applications.base.loginForm.login') }}</h2>
        </template>
        <template v-else>
          <v-row>
            <v-col cols="12" class="d-flex justify-center pb-0">
              <!-- <v-avatar size="120">
                <v-img :src="lastLoggedInUser.picture ? lastLoggedInUser.picture : require('@/assets/images/no-user-picture.jpeg')"/>
              </v-avatar> -->
              <h4>{{ $t('applications.base.loginForm.welcomeBack') }}</h4>
            </v-col>
            <v-col cols="12" class="d-flex justify-center pt-0">
              <h2>{{ lastLoggedInUser.full_name }}</h2>
            </v-col>
          </v-row>
        </template>
      </v-card-title>
      <v-divider class="mt-2 mb-4"/>
      <v-card-text v-on:keyup.enter="login()">
        <v-form @submit.prevent>
          <v-text-field
            v-if="!lastLoggedInUser"
            v-model="form.username"
            solo
            clearable
            prepend-inner-icon="mdi-account"
            class="login-input"
            type="text"
            :autofocus="!lastLoggedInUser"
            :error="!!errorMessage"
            :error-messages="errorMessage"
            :label="$t('common.labels.username')"
            @input="inputHandler()"/>
          <v-text-field
            v-model="form.password"
            solo
            clearable
            prepend-inner-icon="mdi-lock"
            class="login-input"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :autofocus="!!lastLoggedInUser"
            :error="!!errorMessage && !!lastLoggedInUser"
            :error-messages="!!lastLoggedInUser ? errorMessage : ''"
            :label="$t('common.labels.password')"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            @input="inputHandler()"/>
        </v-form>
        <v-btn
          block
          rounded
          color="green"
          class="white--text"
          :loading="loadingLogin"
          @click="login()">
          {{ $t('applications.base.loginForm.loginBtnText') }}
        </v-btn>
      </v-card-text>
      <v-divider class="my-2"/>
      <v-btn
        v-if="lastLoggedInUser"
        tile
        block
        text
        color="grey"
        class="white--text overline"
        :disabled="loadingLogin"
        @click="switchUser()">
        {{ $t('applications.base.loginForm.switchBtnText') }}
      </v-btn>
      <v-btn
        tile
        block
        text
        color="grey"
        class="white--text overline"
        :disabled="loadingLogin"
        @click="requestResetPassword()">
        {{ $t('applications.base.loginForm.forgottenPassword') }}
      </v-btn>
      <div class="h-poweredby-bar mt-2 justify-center align-center">
          <span class="overline white--text">Powered by</span>
          <a href="https://www.hubbiz.be" target="_blank">
            <v-img
              src="@/assets/images/hubbiz_full_mix_white_h.png"
              contain
              max-height="20"
              max-width="100"/>
          </a>
      </div>
    </v-card>
  </v-main>
</template>

<script>
import { addPortalRoutes } from "@/router/tools"
export default {
  name: 'LoginForm',
  data () {
    return {
      loadingLogin: false,
      lastLoggedInUser: JSON.parse(localStorage.getItem('hubbiz-last-logged')),
      showPassword: false,
      form: {
        username: '',
        password: '',
      },
      errorMessage: '',
    }
  },
  methods: {
    login () {
      this.loadingLogin = true
      this.axios
        .post('login', {
          username: this.form.username || this.lastLoggedInUser.username,
          password: this.form.password,
        })
        .then(async response => {
          localStorage.setItem('auth-token-exp', response.data.token_expiration)
          await this.$store.dispatch('FETCH_ME')
          const me = this.$store.state.login.me
          this.$root.$i18n.locale = me.language
          if (me.user_type === 'system') {
            await this.$store.dispatch('FETCH_ACTIVATED_APPLICATIONS')
            await this.$store.dispatch('FETCH_ALL_SETTINGS_BY_APPLICATION')
            if (this.$route.query.redirect) {
              const redirectTo = JSON.parse(localStorage.getItem('redirectTo'))
              if (redirectTo?.perm && !me.permissions?.includes(redirectTo.perm)) {
                await this.$router.push({ name: 'HomePage' })
              }
              await this.$router.push(this.$route.query.redirect)
            } else {
              await this.$router.push({ name: 'HomePage' })
            }
          } else {
            // If we arrived here, it means that :
            //  - Heldpesk Application is activated in backend
            //  - Helpdesk Portal is enabled in backend
            // Then we can just add HelpdeskPortal views to router
            // and route to it.
            addPortalRoutes()
            if (this.$route.query.redirect) {
              const redirectTo = JSON.parse(localStorage.getItem('redirectTo'))
              if (redirectTo?.perm) {
                // When user is a portal one, it has no permission.
                // So if redirect has a permission, it means we cannot go there.
                // Go to 'default' portal view instead.
                await this.$router.push({ name: 'HelpdeskPortalTicketList' })
              }
              await this.$router.push(this.$route.query.redirect)
            } else {
              await this.$router.push({ name: 'HelpdeskPortalTicketList' })
            }
          }
        })
        .catch(error => {
          this.errorMessage = error.response?.data.detail
        })
        .finally(() => {
          localStorage.removeItem('redirectTo')
          this.loadingLogin = false
        })
    },
    inputHandler () {
      this.errorMessage = ''
    },
    switchUser () {
      this.lastLoggedInUser = null
      localStorage.removeItem('hubbiz-last-logged')
    },
    requestResetPassword () {
      this.$router.push({ name: 'ResetPasswordRequestForm' })
    },
  },
}
</script>

<style>
  .v-main.login-content >
    .v-main__wrap {
      display: flex;
      background-image: url('@/assets/images/hubbiz_login_wallpaper.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-blend-mode: lighten;
      background-color: rgba(255, 255, 255, 0.1);
    }
  .v-card__title.login-card-title {
    opacity: 0.6;
  }
  .v-input.login-input >
    .v-input__control >
      .v-input__slot {
        opacity: 0.5;
      }
  .v-input.login-input >
    .v-input__control >
      .v-input__slot >
        .v-text-field__slot {
          margin-left: 15px;
          margin-right: 15px;
        }
  div.h-poweredby-bar {
    display: flex;
    background-color: rgba(0, 0, 0, 0.2)!important;
  }
  div.h-poweredby-bar span {
    opacity: 0.6;
  }
</style>
