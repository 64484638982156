<template>
  <h-app-main-menu :menuItems="menuItems"/>
</template>

<script>
import HAppMainMenu from '@/common/components/HAppMainMenu'
export default {
  name: 'TimesheetsMainMenu',
  components: { HAppMainMenu },
  computed: {
    menuItems () {
      return [
        {
          menuLabel: this.$t('applicationNames.timesheets'),
          menuPermissions: ['view_timesheetitem'],
          routeName: 'TimesheetItemListing',
          matchingPaths: ['/timesheets/items'],
        },
      ]
    },
  },
}
</script>
