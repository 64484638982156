<template>
  <div>
    <user-listing-toolbar
      :viewType="viewType"
      :selectedUsers="selectedUsers"
      @setListView="setListView"
      @setKanbanView="setKanbanView"
      @activateUsers="activateUsers"
      @deactivateUsers="deactivateUsers"
      @deleteUsers="deleteUsers"/>
    <transition name="router-anim" mode="out-in">
      <user-kanban
        v-if="viewType === 'kanban'"
        :users="users"
        :pagination="pagination"
        :loading="loading"
        :loadingMore="loadingMore"
        @loadNextUserPage="loadNextUserPage"
        @activateUser="activateUser"
        @deactivateUser="deactivateUser"
        @deleteUser="deleteUser"/>
      <user-list
        v-else
        v-model="selectedUsers"
        :users="users"
        :pagination="pagination"
        :loading="loading"
        :loadingMore="loadingMore"
        @loadNextUserPage="loadNextUserPage"
        @activateUser="activateUser"
        @deactivateUser="deactivateUser"
        @deleteUser="deleteUser"/>
    </transition>
  </div>
</template>

<script>
import UserListingToolbar from '@/views/base/user/shared/UserListingToolbar'
import UserKanban from '@/views/base/user/UserKanban'
import UserList from '@/views/base/user/UserList'
import WarningModal from '@/common/components/WarningModal'
import DeleteModal from '@/common/components/DeleteModal'
import BaseUserService from '@/services/base/base.user.service'
export default {
  name: 'UserListing',
  components: {
    UserListingToolbar,
    UserKanban,
    UserList,
  },
  data () {
    return {
      loading: false,
      loadingMore: false,
      viewType: 'kanban',
      users: [],
      pagination: null,
      selectedUsers: [],
    }
  },
  mounted () {
    this.getUsers(this.$store.state.url.urlSearchParams)
  },
  watch: {
    '$store.state.url.urlSearchParams' (newParams, oldParams) {
      this.getUsers(this.$store.state.url.urlSearchParams)
    },
  },
  computed: {
    userMe () {
      return this.$store.state.login.me
    },
  },
  methods: {
    setListView () {
      this.viewType = 'list'
    },
    setKanbanView () {
      this.viewType = 'kanban'
    },
    async getUsers (urlSearchParams) {
      this.loading = true
      const responseData = await BaseUserService.getUsers(urlSearchParams)
      this.users = responseData.users
      this.pagination = responseData.pagination
      for (let user of this.users) {
        this.addIsSelectableOnUser(user)
      }
      this.loading = false
    },
    addIsSelectableOnUser (user) {
      this.$set(user, 'isSelectable', this.isSelectable(user))
    },
    isSelectable (user) {
      return ![1, 2, this.userMe.id].includes(user.id)
    },
    async loadNextUserPage () {
      if (this.pagination.has_next_page) {
        this.loadingMore = true
        const nextPageNumber = this.pagination.page_number + 1
        const urlSearchParamsString = this.$store.state.url.urlSearchParams.toString()
        // We do not use the one from the store otherwise the page is stored there..
        const urlSearchParams = new URLSearchParams(urlSearchParamsString)
        urlSearchParams.set('page', nextPageNumber)
        const responseData = await BaseUserService.getUsers(urlSearchParams)
        this.pagination = responseData.pagination
        const newUserPage = responseData.users
        for (let user of newUserPage) {
          this.addIsSelectableOnUser(user)
        }
        const currentUsers = this.users
        const updatedUserList = currentUsers.concat(newUserPage)
        this.users = updatedUserList
        this.loadingMore = false
      }
    },
    async activateUser (user) {
      const activatedUsers = await BaseUserService.activateUsers({ ids: [user.id] })
      if (activatedUsers) {
        let activatedUser = activatedUsers[0]
        this.addIsSelectableOnUser(activatedUser)
        this.users.splice(this.users.indexOf(user), 1, activatedUser)
        if (
          this.viewType === 'list'
          && this.selectedUsers.some(selectedUser => selectedUser.id === user.id)
        ) {
          this.selectedUsers.splice(this.selectedUsers.indexOf(user), 1, activatedUser)
        }
        this.$dialog.notify.success(
          this.$t('applications.base.users.messages.userActivated', { username: user.username }), {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    async activateUsers (users) {
      const deactivatedUsers = users.filter(user => !user.is_active)
      const userIds = deactivatedUsers.map(user => user.id)
      const activatedUsers = await BaseUserService.activateUsers({ ids: userIds })
      if (activatedUsers) {
        for (let activatedUser of activatedUsers) {
          this.addIsSelectableOnUser(activatedUser)
          let userToUpdate = this.users.find(record => record.id === activatedUser.id)
          this.users.splice(this.users.indexOf(userToUpdate), 1, activatedUser)
          if (
            this.viewType === 'list'
            && this.selectedUsers.some(selectedUser => selectedUser.id === activatedUser.id)
          ) {
            this.selectedUsers.splice(this.selectedUsers.indexOf(userToUpdate), 1, activatedUser)
          }
        }
        this.$dialog.notify.success(
          this.$tc('applications.base.users.messages.usersActivated', users.length, { effectiveCount: deactivatedUsers.length, count: users.length }), {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    deactivateUser (user) {
      this.$dialog.show(WarningModal, {
        icon: 'archive-arrow-down',
        title: this.$t('applications.base.users.common.deactivateUserTitle', { fullName: user.full_name, username: user.username }),
        message: this.$t('applications.base.users.common.deactivateUserText', { fullName: user.full_name, username: user.username }),
        okText: this.$t('common.buttons.yes'),
        okColor: 'green',
        noButton: true,
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const deactivatedUsers = await BaseUserService.deactivateUsers({ ids: [user.id] })
          if (deactivatedUsers) {
            let deactivatedUser = deactivatedUsers[0]
            this.addIsSelectableOnUser(deactivatedUser)
            this.users.splice(this.users.indexOf(user), 1, deactivatedUser)
            if (
              this.viewType === 'list'
              && this.selectedUsers.some(selectedUser => selectedUser.id === deactivatedUser.id)
            ) {
              this.selectedUsers.splice(this.selectedUsers.indexOf(user), 1, deactivatedUser)
            }
            this.$dialog.notify.success(
              this.$t('applications.base.users.messages.userDeactivated', { username: deactivatedUser.username }), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    deactivateUsers (users) {
      this.$dialog.show(WarningModal, {
        icon: 'archive-arrow-down',
        title: this.$tc('applications.base.users.common.deactivateUsersTitle', users.length),
        message: this.$tc('applications.base.users.common.deactivateUsersText', users.length),
        okText: this.$t('common.buttons.yes'),
        okColor: 'green',
        noButton: true,
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const activatedUsers = users.filter(user => user.is_active)
          const userIds = activatedUsers.map(user => user.id)
          const deactivatedUsers = await BaseUserService.deactivateUsers({ ids: userIds })
          if (deactivatedUsers) {
            for (let deactivatedUser of deactivatedUsers) {
              this.addIsSelectableOnUser(deactivatedUser)
              let userToUpdate = this.users.find(record => record.id === deactivatedUser.id)
              this.users.splice(this.users.indexOf(userToUpdate), 1, deactivatedUser)
              if (
                this.viewType === 'list'
                && this.selectedUsers.some(selectedUser => selectedUser.id === deactivatedUser.id)
              ) {
                this.selectedUsers.splice(this.selectedUsers.indexOf(userToUpdate), 1, deactivatedUser)
              }
            }
            this.$dialog.notify.success(
              this.$tc('applications.base.users.messages.usersDeactivated', users.length, { effectiveCount: activatedUsers.length, count: users.length }), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    deleteUser (user) {
      this.$dialog.show(DeleteModal, {
        titleText: this.$t('applications.base.users.common.deleteUserTitle', { fullName: user.full_name, username: user.username }),
        warningText: this.$t('applications.base.users.common.deleteUserWarningText', { fullName: user.full_name, username: user.username }),
        alertMessage: this.$tc('applications.base.users.common.deleteUserAlertText', 1),
        checkRequired: true,
        checkMessage: this.$tc('applications.base.users.common.deleteUserCheckText', 1),
        recordName: this.$tc('common.labels.users', 1),
        recordNamePlural: this.$tc('common.labels.users', 2),
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await BaseUserService.deleteUsers({ ids: [user.id] })
          if (response) {
            this.users.splice(this.users.indexOf(user), 1)
            this.pagination.total_records -= 1
            this.selectedUsers = []
            this.$dialog.notify.success(
              this.$t('applications.base.users.messages.userDeleted', { username: user.username, fullName: user.full_name }), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    deleteUsers (usersToDelete) {
      this.$dialog.show(DeleteModal, {
        titleText: this.$tc('applications.base.users.common.deleteUsersTitle', usersToDelete.length),
        warningText: this.$tc('applications.base.users.common.deleteUsersWarningText', usersToDelete.length),
        alertMessage: this.$tc('applications.base.users.common.deleteUserAlertText', usersToDelete.length),
        checkRequired: true,
        checkMessage: this.$tc('applications.base.users.common.deleteUserCheckText', usersToDelete.length),
        recordName: this.$tc('common.labels.users', 1),
        recordNamePlural: this.$tc('common.labels.users', 2),
        summaryHeaders: [
          { value: 'username', text: this.$t('common.labels.username') },
          { value: 'email', text: this.$tc('common.labels.emails', 1) },
          { value: 'is_active', text: this.$t('common.labels.activated') },
          { value: 'user_type', text: this.$t('common.labels.userType') },
        ],
        summaryRecords: usersToDelete,
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const userIdsToDelete = usersToDelete.map(user => user.id)
          const response = await BaseUserService.deleteUsers({ ids: userIdsToDelete })
          if (response) {
            this.users = this.users.filter(undeletedUser => !userIdsToDelete.includes(undeletedUser.id))
            this.pagination.total_records -= usersToDelete.length
            this.selectedUsers = []
            this.$dialog.notify.success(
              this.$tc('applications.base.users.messages.usersDeleted', usersToDelete.length, { count: usersToDelete.length }), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>
  .router-anim-enter,
  .router-anim-leave-to {
    opacity: 0;
    transform: translateY(2em);
  }
  .router-anim-enter-active,
  .router-anim-leave-active {
    transition: all .20s ease;
  }
</style>
